import { FC, useEffect } from "react"
import { AppAvatar } from "../../../../../_components/AppsAvatar"
import { CreateTeamViewModel } from "../CreateTeamViewModel"
import { observer } from "mobx-react-lite"

type Props = {
	viewModel: CreateTeamViewModel
}

const SelectApps: FC<Props> = observer(({ viewModel }) => {

	useEffect(() => {
	}, [viewModel])

	return (
		<div className="w-full">
			<p className="mb-5">Select apps <strong>{viewModel.team?.userGroup.name}</strong> will have access for</p>
			{
				<div className="table-container max-h-52 flex-grow overflow-scroll">
					<table>
						<thead className="sticky top-0 bg-white rounded-md">
							<th></th>
							<th>Name</th>
						</thead>
						<tbody className="">
							{
								viewModel.apps?.map(item => (
									<tr key={item.app.id}>
										<td className="w-20"><input type="checkbox" checked={item.selected} onChange={(e) => viewModel.handleAppSelection(item.app.id!, e.target.checked)}></input></td>
										<td>
											<div className="flex flex-row gap-2 items-center font-semibold">
												<AppAvatar app={item.app} />
												{item.app.name}
											</div>
										</td>

									</tr>
								))
							}
						</tbody>
					</table>
				</div>
			}

		</div>
	)
})
export { SelectApps }