import { FC, useCallback, useEffect, useState } from "react";
import { Issue } from "../../../data/model/Issue";
import { Filter } from "./Filter";
import Lottie from 'react-lottie'
import emptyIssuesAnimation from '../../../assets/animations/empty-issues.json'
import issuesNotFoundAnimation from '../../../assets/animations/issues-not-found.json'
import { IssuesHeader } from "./IssuesHeader";
import { CompanyAPI } from "../../../data/api/CompanyAPI";
import { UserAPI } from "../../../data/api/UserAPI";
import { AppAPI } from "../../../data/api/AppAPI";
import { BuildAPI } from "../../../data/api/BuildAPI";
import { IssueStatus } from "../../../data/api/IssuesAPI";
import { Badge, BadgeType } from "../../_components/Badge";
import { IssuesList } from "../../_partials/issues/IssuesList";
import { CubeTransparentIcon, InformationCircleIcon } from "@heroicons/react/24/outline";

const IssuesPage: FC = () => {

	// const [issues, setIssues] = useState<Issue[]>([])
	// const [filteredIssues, setFilteredIssues] = useState<Issue[]>([])

	// const emptyIssuesAnimationOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: emptyIssuesAnimation,
	// 	rendererSettings: {
	// 		preserveAspectRatio: "xMidYMid slice"
	// 	}
	// }
	// const issuesNotFoundAnimationOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: issuesNotFoundAnimation,
	// 	rendererSettings: {
	// 		preserveAspectRatio: "xMidYMid slice"
	// 	}
	// }
	// const fetchIssues = useCallback((async () => {
	// 	const userAPI = new UserAPI()
	// 	const companyAPI = new CompanyAPI()
	// 	const appAPI = new AppAPI()
	// 	const buildAPI = new BuildAPI()

	// 	const companies = await userAPI.getCompaniesForUser("me")

	// 	console.debug(`[IssueList] fetch companies for user : ${companies}`)

	// 	var allIssues: Issue[] = []

	// 	await Promise.all(
	// 		companies.map(async company => {
	// 			const apps = await companyAPI.getAppsForCompany(company.id)

	// 			console.debug(`[IssueList] fetch apps for company ${company.name} : ${apps.length} apps`)

	// 			await Promise.all(
	// 				apps.map(async app => {
	// 					const builds = await appAPI.builds(app.id)
	// 					console.debug(`[IssueList] fetch builds for app ${app.name} : ${builds.length} builds`)

	// 					await Promise.all(
	// 						builds.map(async build => {
	// 							const issues = await buildAPI.getIssuesForBuild(build.id)
	// 							const testers = await buildAPI.getTestersForBuild(build.id)
	// 							const builder = await new UserAPI().getUserByID(build.committerId)
	// 							build.committer = builder
	// 							debugger
	// 							console.debug(`[IssueList] fetch issues for build ${build.id} : ${issues.length} issues`)
	// 							console.debug(`[IssueList] fetch issues for build ${build.id} : ${testers.length} testers`)
	// 							build.testers = testers

	// 							issues.forEach(issue => {

	// 								console.debug(`[IssueList] issueID : ${issue.id}`)

	// 								issue.company = company
	// 								issue.app = app
	// 								issue.build = build
	// 							})

	// 							build.issues = issues

	// 							allIssues = allIssues.concat(issues)
	// 						})
	// 					)
	// 					app.builds = builds
	// 				}))

	// 			company.apps = apps
	// 		})
	// 	)

	// 	console.debug(`[IssueList] all issues : ${(await allIssues).length} issues`)

	// 	const sortedIssues = allIssues.sort((a, b) => {
	// 		if (a.issueId! > b.issueId!) {
	// 			return 1
	// 		} else {
	// 			return -1
	// 		}
	// 	})
	// 	setIssues(sortedIssues)
	// 	setFilteredIssues(sortedIssues)
	// }), [])

	// useEffect(() => {
	// 	fetchIssues()
	// }, [fetchIssues])

	// const statusBadge = (issue: Issue) => {
	// 	if (issue.status === IssueStatus.NEW) {
	// 		return <Badge type={BadgeType.INFO} text={"New"} />
	// 	} else if (issue.status === IssueStatus.CLOSED) {
	// 		return <Badge type={BadgeType.ERROR} text={" Closed"} />
	// 	} else if (issue.status === IssueStatus.REFUSED) {
	// 		return <Badge type={BadgeType.ERROR} text={" KO"} />
	// 	} else { // VALIDATED
	// 		return <Badge type={BadgeType.ERROR} text={" OK"} />
	// 	}
	// }

	return (

		<div className="p-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Issues is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming soon 🚀" />
				</div>
				<p className="text-gray-600">
					
				</p>
				<div className="flex flex-row items-center gap-1 pt-6">
					<InformationCircleIcon className="size-5 " />
					<p className="font-bold">
						Please use issues in a build detail from the store menu item
					</p>
				</div>
			</div>
		</div>
		// <>
		// 	{
		// 		issues.length > 0 ? (
		// 			<div className="h-full overflow-y-scroll">
		// 				<div className={`px-6`}>
		// 					<div className="px-6 pt-12">

		// 						<IssuesHeader issues={issues} />
		// 						<Filter setFilteredIssues={setFilteredIssues} issues={issues} />

		// 						{
		// 							filteredIssues.length > 0 ? (
		// 								<IssuesList issues={filteredIssues} />
		// 							) : (
		// 								<div className="items-center text-center">

		// 									<Lottie options={issuesNotFoundAnimationOptions} height={150} width={200} />
		// 									<div className="text-2xl font-bold mt-5">No issues match your filters</div>
		// 								</div>

		// 							)
		// 						}

		// 					</div>
		// 				</div>
		// 			</div >
		// 		) : (
		// 			<div className="mt-16 items-center text-center">
		// 				<Lottie options={emptyIssuesAnimationOptions} height={150} width={200} />
		// 				<div className="text-2xl font-bold mt-5">No issues found</div>
		// 			</div>
		// 		)
		// 	}
		// </>
	);
}

export { IssuesPage }