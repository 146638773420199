import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { FC, useState, useRef, useEffect } from "react";
import { Tooltip } from "./Tooltip";
import Tippy from "@tippyjs/react";
import { TextInput } from "./TextInput";

const CopyToClipboardInput: FC<{
	text: string;
	action?: string;
	className?: string;
}> = ({ text, action = "Copy to Clipboard", className }) => {
	const [supported, setSupported] = useState(false);
	const [success, setSuccess] = useState(false);
	const [visible, setVisible] = useState(false);
	const ref = useRef<HTMLInputElement>(null);

	const [tooltipText, setTooltipText] = useState("Copy to clipboard")

	useEffect(() => {
		if (navigator.clipboard) {
			setSupported(true);
		}
	}, []);

	useEffect(() => {
		if (success) {

			const hide = () => {
				setTooltipText("Copy to clipboard")
				setSuccess(false);
			};
			const timer = window.setTimeout(hide, 1000);
			return () => {
				window.clearTimeout(timer);
			};
		}
	}, [success]);

	const copy = async () => {
		try {
			
			await navigator.clipboard.writeText(text);
			setTooltipText("Copied !")
			setSuccess(true);
			
		} catch (error) {
			console.error("Failed to copy: ", error);
			setSuccess(false);
		}
	};

	return (
		<div className={`flex items-center space-x-2 ${className}`}>
		<p
		className="rounded-md border-0 p-1 w-fit ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6"		
		>{text}</p>
		
		{supported && (
			<div>
			<Tippy content={ tooltipText } visible={visible}>
			<p onClick={copy} onMouseEnter={ () => { setVisible(true) } } onMouseLeave={ () => { setVisible(false) } }>
			<ClipboardDocumentCheckIcon className='size-6 text-gray-500' />
			</p>
			</Tippy>
			</div>
		)}
		</div>
	);
};

CopyToClipboardInput.defaultProps = {
	className: ""
}

export { CopyToClipboardInput }