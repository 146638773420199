import { App } from "../../../../data/model/App";
import { Company } from "../../../../data/model/Company";
import { User } from "../../../../data/model/User";
import { UserGroup } from "../../../../data/model/UserGroup";
import { makeAutoObservable, runInAction } from "mobx"
import { CompanyAPI } from "../../../../data/api/CompanyAPI";
import { UserGroupsAPI } from "../../../../data/api/UserGroupsAPI";

class TeamsViewModel {

	teams: TeamExtended[] = []

	private workspace?: Company

	constructor(workspace?: Company) {
		makeAutoObservable(this);
		this.workspace = workspace
	}

	public load() {
		(async () => { await this.getTeams() })()
	}

	public deleteTeam = async (userGroupId: string) => {
		const userGroupsAPI = new UserGroupsAPI()
		await userGroupsAPI.deleteUserGroup(userGroupId)
	}

	getTeams = async () => {
		if (this.workspace != undefined) {
			const companyAPI = new CompanyAPI()
			const userGroupsAPI = new UserGroupsAPI()
			const userGroups = await companyAPI.getUsergroupsForCompany(this.workspace?.id)
			const teams = userGroups.map((ug) => new TeamExtended(ug));
			await Promise.all(
				teams.map(async team => {
					const users = await userGroupsAPI.getUsersForUserGroup(team.userGroup.id!)
					team.users = users
					const apps = await userGroupsAPI.appsForUserGroup(team.userGroup.id!)
					team.apps = apps
				})
			)
			runInAction(() => {
				this.teams = teams
			})
		}
	}
}

class TeamExtended {
	userGroup: UserGroup
	apps?: App[]
	users?: User[]

	constructor(userGroup: UserGroup) {
		this.userGroup = userGroup
	}
}

export { TeamsViewModel, TeamExtended }