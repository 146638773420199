import { ChevronLeftIcon } from "@heroicons/react/24/solid"
import { FC } from "react"
import { Link } from "react-router-dom"

type Props = {
	text: string,
	redirectTo?: string
	customClass?: string
}

const BackLink: FC<Props> = ({ text, redirectTo, customClass }) => {
	return (
		<Link to={redirectTo || ".."} className={`inline-flex ${customClass}`}>
			<ChevronLeftIcon className="w-6 h-6" />
			{text}
		</Link>
	)
}

export { BackLink }