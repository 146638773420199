import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { QRCode } from 'react-qrcode-logo';
import { TextInput } from "../../_components/TextInput";
import { Button } from "../../_components/buttons/Button";
import { Label } from "../../_components/Label";
import { ImageInput } from "../../_components/ImageInput";
import { observer } from "mobx-react-lite";
import { ProfilePageViewModel } from "./ProfilePageViewModel";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { ButtonGroup } from "../../_components/ButtonGroup";
import { Tooltip } from "../../_components/Tooltip";
import React from "react";

const ProfilePage: FC = observer(() => {

	const { user, setUser } = useAuth()
	const viewModel = useMemo(() => new ProfilePageViewModel(user), [user]);

	const updateProfile = () => {
		(async () => {
			const user = await viewModel.updateProfile()
			setUser(user)
		})()
	}

	const formRef = useRef<HTMLFormElement>(null);

	return (
		<>
			{
				viewModel.user ? (
					<div className="h-full overflow-y-scroll px-12">
						<div className="w-full flex flex-col gap-5">
							<div className="flex justify-between border-b-1 ">
								<div>
									<p className='mt-4 mb-2 font-extrabold text-lg'>Avatar</p>
									<ImageInput initialeImage={`/api/img/${viewModel.user?.avatar}`} name={""} label={""} onImageUpload={(file) => { viewModel.iconFile = file }} />
								</div>
								{/* TODO, keep it or change it ? If kept, change logo for deliver logo ? Change color ! */}
								<div className="pt-4">
									<QRCode value={viewModel.user?.email}
										removeQrCodeBehindLogo={true}
										logoPadding={5}
										fgColor="rgb(100 100 100)"
										size={100}
										qrStyle='dots'
										eyeColor="rgb(255 150 250)"
										eyeRadius={5}
										logoImage={viewModel.user?.avatar ? `/api/img/${viewModel.user?.avatar}` : "/api/img/default_avatar.png"} />
								</div>
							</div>
							<div className="border-b-1">
								<p className='mt-4 mb-2 font-extrabold text-lg'>Main settings</p>
								<div className="pb-5 grid grid-cols-2 max-w-96 gap-8">
									<div>
										<Label label="Firstname" htmlFor="" />
										<TextInput onChange={(event) => { viewModel.setFirstname(event.target.value) }} name="firstname" value={viewModel.user?.firstname} placeholder="John" customClass="mt-1 me-1" />
									</div>
									<div>
										<Label label="Lastname" htmlFor="" />
										<TextInput onChange={(event) => { viewModel.setLastname(event.target.value) }} name="lastname" value={viewModel.user?.lastname} placeholder="Doe" customClass="mt-1 me-1" />
									</div>
									<div>
										<Label label="Your username" htmlFor="" />
										<TextInput onChange={(event) => { viewModel.setUsername(event.target.value) }} name="username" value={viewModel.user?.username} placeholder="jdoe" customClass="mt-1 me-1" />
									</div>
								</div>
							</div>
							<div className="border-b-1">
								<div className="max-w-96 pb-5">
									<p className='mt-4 mb-2 font-extrabold text-lg'>Authentication</p>
									<Label style="md" label="Security code" htmlFor="" />
									<p className="text-sm text-gray-500 pb-2">Your security code helps you to easily authenticate on Deliver Store and Deliver SDK</p>
									<form ref={formRef} className="max-w-full">
										<div className="grid grid-cols-2 gap-2">
											<div className="grid grid-cols-6 gap-2">
												<TextInput type="password" maxChar={1} onChange={(event) => { viewModel.securityCode[0] = event.target.value; }} name="first" value={viewModel.securityCode[0]} placeholder="" />
												<TextInput type="password" maxChar={1} onChange={(event) => { viewModel.securityCode[1] = event.target.value; }} name="second" value={viewModel.securityCode[1]} placeholder="" />
												<TextInput type="password" maxChar={1} onChange={(event) => { viewModel.securityCode[2] = event.target.value; }} name="third" value={viewModel.securityCode[2]} placeholder="" />
												<TextInput type="password" maxChar={1} onChange={(event) => { viewModel.securityCode[3] = event.target.value; }} name="fourth" value={viewModel.securityCode[3]} placeholder="" />
												<TextInput type="password" maxChar={1} onChange={(event) => { viewModel.securityCode[4] = event.target.value; }} name="fifth" value={viewModel.securityCode[4]} placeholder="" />
												<TextInput type="password" maxChar={1} onChange={(event) => { viewModel.securityCode[5] = event.target.value; }} name="sixth" value={viewModel.securityCode[5]} placeholder="" />
											</div>
											<div className="h-full content-center" onClick={() => {
												const emptyArray = Array(6).fill("");
												viewModel.securityCode = emptyArray
											}
											}>
												<ButtonGroup mode="click">
													<Tooltip content="Refresh security code">
														<ArrowPathIcon className="size-4" />
													</Tooltip>
												</ButtonGroup>

											</div>

										</div>
									</form>
								</div>
							</div>
							<div>
								<Button loading={viewModel.isLoading} text='Update profile' customClass="w-fit" onClick={() => {
									if (viewModel.securityCode.join("").length > 0) {
										if (formRef.current && formRef.current.reportValidity()) {
											updateProfile()
										}
									} else {
										updateProfile()
									}									
								}} />
							</div>
						</div>
					</div>
				) : (null)
			}
		</>
	);
})


// <Button text='Update profile' onClick={async () => {
// 					var updateUser: Partial<User> = {}

// 					updateUser.firstname = firstName
// 					updateUser.lastname = lastName

// 					if (userName) {
// 						updateUser.username = userName
// 					}

// 					if (securityCode.length == 6) {
// 						updateUser.securityCode = securityCode.join('')
// 					}

// 					await userApi.updateProfile("me", updateUser)
// 				}} />

export { ProfilePage }


{/* <div className="flex flex-row">
					<Avatar customClass="size-40 me-5" user={user!} />
					<div className="flex flex-col">
						<span className="font-bold">{user?.firstname} {user?.lastname}</span>
						<span className="">{user?.email}</span>
						<div className="mt-2 w-full">
							{
								editMode ? <input
									type='file'
									onClick={onInputClick}
									onChange={onInputChange}
									accept='.png, .jpg, .jpeg'
								/> : <OutlineButton text={"Change avatar"} style={"neutral"} enabled={true} onClick={() => setEditMode(true)} />

							}
						</div>

					</div>
				</div> */}