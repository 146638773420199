import { MobSFProps } from "../MobSFProps"
import { URL } from '../../../../../../data/model/MobSF'
import { SecuritySection } from "../_components/SecuritySection";

const URLs: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="URLS" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum." expanded={expanded}> 
				<div className="table-container">
					<table>
					<thead>
						<tr>
							<th>URL</th>
							<th>File</th>
							</tr>
						</thead>
					<tbody>
					{
						report.urls ? (
							(() => {
								const rows: React.ReactElement[] = [];
								for (const url of report.urls) {
									rows.push(<URLRow url={url} />)
								}
								return rows
							})()
						): <></>
					}
					</tbody>
					</table>
				</div>
		</SecuritySection>
	)
}

type URLRowProps = {
   url: URL
}

const URLRow: React.FC<URLRowProps> = ({ url }) => {
	
	const a = url.urls
	return (   
		<tr>
			<td>
			{url.urls.map((value, index) => (
				<p key={index}><span>{value}</span><br/></p>
			))}
			</td>           
			<td>{ url.path }</td>
		</tr>
	)
}

export { URLs }