import { FC, useCallback, useEffect, useState } from "react"
import { Filter } from "./_components/Filter"
import { BuildList } from "./_components/buildlist/BuildList"
import { useParams } from "react-router-dom"
import { AppAPI } from "../../../../data/api/AppAPI"
import { Build } from "../../../../data/model/Build"
import Lottie from 'react-lottie'
import emptyBuildAnimation from '../../../../assets/animations/empty-build.json'
import issuesNotFoundAnimation from '../../../../assets/animations/issues-not-found.json'
import { BuildHeader } from "./_components/buildheader/BuildHeader"
import { BackLink } from "../../../_components/BackLink"
import { BuildUseCases } from "../../../../domain/usecases/BuildUseCases"
import { App } from "../../../../data/model/App"

/**
 * This page display basic application information and all available buillds
 */
const AppPage: FC = () => {

	const { appId } = useParams()

	const [app, setApp] = useState<App>()
	const [builds, setBuilds] = useState<Build[]>([])
	const [filteredBuilds, setFilteredBuilds] = useState<Build[]>([])

	const emptyBuildAnimationOptions = {
		loop: true,
		autoplay: true,
		animationData: emptyBuildAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}
	const buildsNotFoundAnimationOptions = {
		loop: true,
		autoplay: true,
		animationData: issuesNotFoundAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}

	const fetchBuilds = useCallback((async () => {
		const appAPI = new AppAPI()
		const buildUseCases = new BuildUseCases()
		const builds = await appAPI.builds(appId!)
		const app = await appAPI.getAppById(appId!)
		console.debug(`[BuildList] build for ${appId} : ${(await builds).length} builds`)
		await Promise.all(
			builds.map(async (build) => {
				return await buildUseCases.assembleBuild(build)
			})
		)
		setApp(app)
		setBuilds(builds)
		setFilteredBuilds(builds)
	}), [appId])

	useEffect(() => {
		fetchBuilds()
	}, [fetchBuilds])

	return (
		<div className="px-6 pt-8 h-full overflow-y-scroll">
			<BackLink text="Back to store" customClass="mb-10" />
			{
				builds.length > 0 ? (
					<>

						<BuildHeader />
						<Filter setFilteredBuilds={setFilteredBuilds} builds={builds} />

						{
							(app && filteredBuilds.length > 0) ? (
								<BuildList app={app} builds={filteredBuilds} />
							) : (
								<div className="items-center text-center">
									<Lottie options={buildsNotFoundAnimationOptions} height={150} width={200} />
									<div className="text-2xl font-bold mt-5">No builds match your filters</div>
								</div>

							)
						}
					</>
				) : (
					<div className="items-center text-center">
						<Lottie options={emptyBuildAnimationOptions} height={350} width={400} />
						<div className="text-2xl font-bold mt-10">No build found</div>
					</div>
				)
			}
		</div>
	)
}

export { AppPage }