import { FC } from "react";
import { User } from "../../../data/model/User";
import { UserIcon } from "@heroicons/react/24/solid";


type Props = {
	size?: "sm" | "default"
	user: User
	customClass?: string
}

const Avatar: FC<Props> = ({ user, size, customClass }) => {
	const classes = `${customClass || size === "sm" ? "size-5 text-xs " : "size-8"} object-cover rounded border-1 border-gray-200`;

	const avatarContent = user.avatar ? (
		<img className={classes} src={`/api/img/${user.avatar}`} alt={`${user.firstname || 'User'}'s avatar`} />
	) : user.firstname || user.lastname ? (
		// Display initial if avatar is null and at least one name field is present
		<div className={`${classes} flex items-center justify-center bg-slate-100 text-black font-bold`}>
			{`${user.firstname?.[0] || ""}${user.lastname?.[0] || ""}`.toUpperCase() || "?"}
		</div>
	) : (
		// Display default avatar if no avatar, firstname, or lastname
		// <img className={classes} src="/api/img/default_avatar.png" alt="default avatar" />
		<UserIcon className={`${classes} p-1`} />
	);

	return <>{avatarContent}</>;
};

Avatar.defaultProps = {
	size: "default",
	customClass: ""
}


export { Avatar }