import { FC } from "react";
import { NavigationTabs } from "../../_components/NavigationTabs/Tabs";
import { NavigationTab } from "../../_components/NavigationTabs/Tab";
import { Outlet } from "react-router-dom";

const SettingsLayout: FC = () => {
	return (
		<div className={`h-full overflow-scroll w-full`}>
			<div className={`pt-8 flex flex-col px-6 w-full`}>
				<NavigationTabs customClass="h-1/12">
					<NavigationTab to={"company"} title={"Workspace"} />
					<NavigationTab to={"apps"} title={"Apps"} />
					<NavigationTab to={"members"} title={"Members"} />
					<NavigationTab to={"teams"} title={"Teams"} />
				</NavigationTabs>
			</div>
			{ /** pb-40 is a very dirty trick to show entire scrollview, fuck CSS */}
			<div className="pl-2 h-10/12">
				<Outlet />
			</div>
		</div>
	);
}

export { SettingsLayout }