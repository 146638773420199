import { FC, useCallback, useEffect, useState } from "react"
import { BuildAPI } from "../../../../../data/api/BuildAPI";
import { useParams } from "react-router-dom";
import { MobSF, AndroidMobSF } from "../../../../../data/model/MobSF";
import { ShieldExclamationIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/solid'
import { BuildSecurityiOS } from "./BuildSecurityiOS";
import { BuildSecurityAndroid } from "./BuildSecurityAndroid";
import { TextInput } from "../../../../_components/TextInput";
import { Tooltip } from "../../../../_components/Tooltip";
import { SecurityScanStatus } from "../../../../../data/model/Build";
import { Loader } from "../../../../_components/Loader";

const BuildSecurity: FC = () => {

	const { buildId } = useParams()
	const [report, setReport] = useState<{ status: SecurityScanStatus, mobsf: MobSF | null }>({ status: SecurityScanStatus.LOADING, mobsf: null })
	const [expanded, setexpanded] = useState(true)

	const fetchSecurityReport = useCallback((async () => {
		const api = new BuildAPI()
		const build = await api.getBuildById(buildId!)
		if (build.securityScanStatus == SecurityScanStatus.ENDED) {
			const report = await api.securityReport(buildId!)
			console.log(`Security report : ${JSON.stringify(report)}`)
			if (typeof report == "string") {
				// An error occured on API
				setReport({ status: SecurityScanStatus.ENDED, mobsf: null })
			} else {
				setReport({ status: SecurityScanStatus.ENDED, mobsf: report })
			}
		} else {
			setReport({ status: SecurityScanStatus.PENDING, mobsf: null })
		}

	}), [buildId, setReport]);

	useEffect(() => {
		fetchSecurityReport()
	}, [fetchSecurityReport])

	return (
		<div className="">
			{
				report?.status == SecurityScanStatus.LOADING && (
					<Loader size="default" />
				)
			}
			{
				report?.status == SecurityScanStatus.PENDING && (
					<div className="py-6 border-1 rounded-md border-gray-100 bg-gray-50 pl-4">
						<ShieldExclamationIcon className="inline w-6 h-6" />
						<p className="ml-2 inline align-middle font-medium text-lg">Scan in progress</p>
						<p className="align-middle font-medium pt-3">Please wait a moment and refresh this page to see your security scan.</p>
					</div>
				)
			}
			{
				report?.status == SecurityScanStatus.ENDED && (
					report?.mobsf ? (
						<>
							<div className="flex flex-row justify-end space-x-2 mb-4">

								<TextInput name={""} placeholder="Search" />
								<Tooltip content={expanded ? "Collapse all" : "Expand all"}>
									<button className="p-2 border-1 rounded-md" onClick={() => { setexpanded(!expanded) }}>
										{expanded ? (<ArrowsPointingInIcon className="w-4 h-4" />) : (<ArrowsPointingOutIcon className="w-4 h-4" />)}
									</button>
								</Tooltip>

							</div>
							{report.mobsf.app_type === "Swift" ? <BuildSecurityiOS report={report.mobsf} expanded={expanded} /> : <BuildSecurityAndroid report={report.mobsf as AndroidMobSF} expanded={expanded} />}
						</>
					) : (
						<div className="py-6 border-1 rounded-md border-gray-100 bg-gray-50 pl-4">
							<ShieldExclamationIcon className="inline w-6 h-6" />
							<p className="ml-2 inline align-middle font-medium text-lg">No report found</p>
							<p className="align-middle font-medium pt-3">Security reports are automatically generated when a build is uploaded.</p>
						</div>
					)
				)
			}
		</div>
	)
}

export { BuildSecurity }