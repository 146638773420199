import { FC, useEffect } from "react"
import { Tab } from "../../../../../_components/tabs/Tab"
import { CreateTeamViewModel } from "../CreateTeamViewModel"
import { Avatar } from "../../../../../_components/avatar/Avatar"
import { observer } from "mobx-react-lite"

type Props = {
	viewModel: CreateTeamViewModel
}

const SelectUsersAndTeams: FC<Props> = observer(({ viewModel }) => {

	useEffect(() => {
	}, [viewModel])

	return (
		<div className="w-full">
			<p className="mb-5">Select user(s) to add in <strong>{viewModel.team?.userGroup.name}</strong></p>
			{
				// <Tabs>
				// 	<Tab title="Teams" customClass=" flex flex-col">
				// 		<div className="table-container max-h-52 flex-grow overflow-scroll">
				// 			<table>
				// 				<thead className="sticky top-0 bg-white rounded-md">
				// 					<th></th>
				// 					<th>Name</th>
				// 					<th>Access to</th>
				// 				</thead>
				// 				<tbody className="">
				// 					{
				// 						viewModel.selectableTeams()?.map(team => (
				// 							<tr>
				// 								<td className="w-20"><input type="checkbox" onChange={() => handleValidate()}></input></td>
				// 								<td>
				// 									<div className="flex flex-row gap-2 items-center font-semibold">
				// 										{team.userGroup.name}
				// 									</div>
				// 								</td>
				// 								<td>
				// 									<div className="flex flex-row items-center gap-2">
				// 										<AppsAvatar apps={team.apps} />
				// 									</div>
				// 								</td>
				// 							</tr>
				// 						))
				// 					}
				// 				</tbody>
				// 			</table>
				// 		</div>
				// 	</Tab>
				<Tab title="Users">
					<div className="table-container max-h-52 flex-grow overflow-scroll">
						<table>
							<thead className="sticky top-0 bg-white rounded-md">
								<th></th>
								<th>email</th>
							</thead>
							<tbody className="overflow-scroll">
								{
									viewModel.users?.map(item => (
										<tr key={item.user.id}>
											<td className="w-20"><input type="checkbox" checked={item.selected} onChange={(e) => viewModel.handleUsersSelection(item.user.id!, e.target.checked)}></input></td>
											<td>
												<div className="flex flex-row gap-2 items-center font-semibold">
													<Avatar user={item.user} />{item.user.email}
												</div>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>
				</Tab>
				// 	</Tabs>
			}

		</div>
	)
})
export { SelectUsersAndTeams }