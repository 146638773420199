import { FC } from "react"
import { ChartBarSquareIcon, CubeTransparentIcon } from "@heroicons/react/24/outline"
import { Badge, BadgeType } from "../../../_components/Badge"

const BuildPerformance: FC = () => {

	return (
		<div className="py-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Performance is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming in 2025 🚀" />
				</div>
				<p className="text-gray-600">
					Performance is designed to enhance the quality of your mobile application by analyzing its behavior 
					during tester interactions.<br/>
					Performance monitors network activity, tracking the amount of data exchanged and the duration of requests. 
					It provides actionable insights and suggestions for optimization to ensure efficient data usage and faster response times.<br/>
					Additionally, Performance identifies screens that are resource-intensive, helping you address potential performance bottlenecks and deliver a smoother, 
					high-quality user experience.
				</p>
			</div>

		</div>
	)
}

export { BuildPerformance }