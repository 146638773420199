import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection"

const Files: React.FC<MobSFProps> = ({ report, expanded }) => {
    
    return (
        <SecuritySection title="Files" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum." expanded={expanded}>
			<div>
                {
                    report.files ? (
                        report.files.map((value, index) => (
                            <p key={value}><span>{value}</span><br/></p>
                        ))
                    ): <></>
                }
            </div>
		</SecuritySection>
    )
}

export { Files }