import { FC, PropsWithChildren, useState, useCallback, useEffect } from "react"
import { useAuth } from "../../../hooks/useAuth"
import { AuthAPI } from "../../../data/api/AuthAPI"
import { Company } from "../../../data/model/Company"
import Cookies from "js-cookie"
import { CompanyAPI } from "../../../data/api/CompanyAPI"
import { UserAPI } from "../../../data/api/UserAPI"
import { User } from "../../../data/model/User"


/**
 * This class is responsible to retreive connected user and affect it to the auth session provider
 * If user can't be retreive (invalid or no cookie) user will be redirect to the login page
 * cf: RequestUtils.ts
 * 
 * During loading state, we display an empty view cf logging state variable.
 * @returns 
 */
const AuthInitializer: FC<PropsWithChildren> = ({ children }) => {

	const { setUser, workspace, setWorkspace } = useAuth()
	const [logging, setLogging] = useState(true)

	/**
	 *  Get connected user and display an empty page will loading
	 */
	const login = useCallback(async () => {

		try {
			const userAPI = new AuthAPI()
			const user = await userAPI.me()
			console.debug(`[AuthProvider] login user: ${JSON.stringify(user)}`)
			return user
		}
		catch (error) { }
	}, [])

	const fetchWorkspace = useCallback(async (user: User) => {
		const workspaceId = Cookies.get('workspace');

		if (workspaceId) {
			const api = new CompanyAPI()
			const company = await api.getCompanyById(workspaceId)
			return company
		}
		else {
			const userAPI = new UserAPI()
			const companies = await userAPI.getCompaniesForUser("me")
			if (companies.length > 0) {
				const company = companies.at(0)
				return company
			}
		}

	}, [])

	useEffect(() => {
		const init = async () => {
			const user = await login()
			if (user) {
				setUser(user)
				const workspace = await fetchWorkspace(user)
				setWorkspace(workspace)
			}
			setLogging(false)
		};

		init()

	}, [login, setUser, fetchWorkspace, setWorkspace])

	return logging ? <></> : <>{children}</>
}

export { AuthInitializer }