import { FC } from "react"
import { UserGroup, UserGroupType } from "../../../../data/model/UserGroup"
import { Tooltip } from "../../../_components/Tooltip"
import { StarIcon } from "@heroicons/react/24/solid"
import { Badge, BadgeType } from "../../../_components/Badge"
import { CubeIcon, DevicePhoneMobileIcon, PhoneIcon } from "@heroicons/react/24/outline"

type Props = {
	team: UserGroup
	mode?: "default" | "visible"
}

const TeamBadge: FC<Props> = (props) => {
	return (
		<>
			{(() => {
				switch (props.team.type) {
					
				case UserGroupType.WORKSPACE:
					return (
					<Tooltip content="Users in workspace have access to all applications">
						<div className="w-fit">
						<Badge
							text={
							<div className="flex flex-row items-center gap-1">
								<p>Workspace</p>
								<StarIcon className="size-3 text-yellow-500" />
							</div>
							}
							type={BadgeType.NEUTRAL}
						/>
						</div>
					</Tooltip>
					);

				case UserGroupType.APP:
					return (
					<Tooltip content={<p>App team: Users in team have access to the underlying app</p>}>
						<div className="w-fit">
						<Badge
							text={
							<div className="flex flex-row items-center gap-1">
								<CubeIcon className="size-4" />
								<p>{props.team.name?.replace("-#default", "")}</p>
							</div>
							}
							type={BadgeType.NEUTRAL}
						/>
						</div>
					</Tooltip>
					);

				case UserGroupType.CUSTOM:
					return props.mode === "default" 
					? (<>{props.team.name}</>) 
					: (<Badge text={props.team.name} type={BadgeType.NEUTRAL} />);
				}
			})()}
		</>
	)
}

TeamBadge.defaultProps = {
	mode:"default"
}


export { TeamBadge }