import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { User } from "../../../data/model/User";
import { AuthContext } from "../../../hooks/useAuth";
import { Company } from "../../../data/model/Company";
import { CompanyAPI } from "../../../data/api/CompanyAPI";
/**
 * AuthProvider store the connected user at the top level of the application.
 * Cf `AuthInitializer` for more information 
 */
const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const [user, setUser] = useState<User | undefined>(undefined)
	const [workspace, setWorkspaceState] = useState<Company | undefined>(undefined)

	const setWorkspace = useCallback(async (newWorkspace: Company | undefined) => {
		if (newWorkspace) {
			const companyAPI = new CompanyAPI()
			await companyAPI.setWorkspace(newWorkspace?.id)
		}

		console.warn("Update workspace cookie ", newWorkspace?.id)
		setWorkspaceState(newWorkspace)
	}, [setWorkspaceState]);

	return (
		<AuthContext.Provider value={{ user, workspace, setUser, setWorkspace }}>
			{children}
		</AuthContext.Provider>
	)
}

export { AuthProvider }