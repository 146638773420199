import { makeAutoObservable, observable } from "mobx";
import { CompanyAPI } from "../../../../../../data/api/CompanyAPI";
import { UserGroupsAPI } from "../../../../../../data/api/UserGroupsAPI";
import { Company } from "../../../../../../data/model/Company";
import { TeamExtended } from "../../TeamsViewModel";
import { UserSelection } from "../UserSelection";

class RemoveUserViewModel {

	users: UserSelection[] = []
	isDeleting: boolean = false

	private workspace: Company | undefined
	team: TeamExtended | undefined


	constructor(workspace: Company | undefined, team: TeamExtended | undefined) {
		makeAutoObservable(this, {
			users: observable,
			isDeleting: observable,
		});
		this.workspace = workspace
		this.team = team
	}


	/// View life cycle
	// ---------------------------------------------------------------------------------------------------------------------------------------
	load() {
		const userSelection = this.team?.users?.flatMap((u) => new UserSelection(u, false))
		if (userSelection) {
			this.users = userSelection
		}
	}

	/// Get all apps in the current team
	async fetchUsersWorkspace() {
		if (this.team != undefined) {
			const api = new CompanyAPI()
			const users = await api.getUsersForCompany(this.workspace!.id)
			return users
		}
	}


	/// Actions
	// ---------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Add the given user to the guests 
	 * @param userId User affected by the selection/unselection
	 * @param checked Is user selected
	 */
	handleMemberSelection(userId: string, checked: boolean) {
		this.users = this.users.map((userSelection) =>
			userSelection.user.id === userId
				? { ...userSelection, selected: checked }
				: userSelection
		)
	}

	removeUsers = async () => {
		if (this.team != undefined) {
			this.isDeleting = true
			const api = new UserGroupsAPI()
			const removedUsers = this.users.filter((u) => u.selected == true)
			await api.removeUsers(this.team.userGroup.id, removedUsers.flatMap((u) => u.user.id!))
			this.isDeleting = false
		}

	}

}

export { UserSelection, RemoveUserViewModel }

class MockUserGroupAPI {

	removeUsers = async (teamId: string, users: string[]) => {

		return new Promise((resolve) => {
			setTimeout(() => {
				resolve("Result after 2 seconds");
			}, 2000); // 2 seconds delay
		});
	}

}