import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection"

const CustomURLSchemes: React.FC<MobSFProps> = ({ report, expanded }) => {

	return (
		<SecuritySection title="Custom URL Schemes" subtitle="Custom URL schemes provide a way to reference resources inside your app. Users tapping a custom URL in an email, for example, launch your app in a specified context. Other apps can also trigger your app to launch with specific context data; for example, a photo library app might display a specified image." expanded={expanded}>
			Todo
		</SecuritySection>
	)
}

export { CustomURLSchemes }