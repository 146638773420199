import { Build } from "./Build"
import { User } from "./User"

export enum AppType {
	IOS = 'IOS',
	ANDROID = 'ANDROID',
}

export type App = {
	id: string
	apiKey?: string
	companyId?: string
	name?: string
	bundleId?: string
	groupBy?: string
	icon?: string
	iconFile?: File
	gitlabUrl?: string
	jiraUrl?: string
	createdAt?: string
	updatedAt?: string
	users?: User[]
	tags?: string[]
	platform?: AppType
	builds?: Build[]
}
