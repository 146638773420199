import { useCallback, useEffect, useState } from "react"
import { Badge, BadgeType } from "../../../../_components/Badge"
import { ReqProps } from "./ReqProp"
import { SelectableReqProps } from "./SelectableReqProp"
import { ArrowLongDownIcon, ArrowLongUpIcon, TrashIcon, GlobeAltIcon, WifiIcon } from '@heroicons/react/24/outline'
import { OutlineButton } from "../../../../_components/OutlineButton"
import { Tooltip } from "../../../../_components/Tooltip"
import { BuildAPI } from "../../../../../data/api/BuildAPI"
import { useParams } from "react-router-dom"
import { Request } from "../../../../../data/model/Request"
import { LiveAPI } from "../../../../../data/api/LiveAPI"
import { ButtonGroup } from "../../../../_components/ButtonGroup"
import { isEmptyArray } from "formik"
import { Label } from "../../../../_components/Label"
import { TextInput } from "../../../../_components/TextInput"

const RequestList: React.FC<SelectableReqProps> = ({ sessionId, onSelectRequest }) => {

    const [requests, setRequests] = useState<Request[]>([])
    const [startIndex, setStartIndex] = useState<string>("0");
    const [autoScrolling, setAutoScrolling] = useState<boolean>(false);
    const [pause, setPause] = useState<boolean>(false);
    const [deviceDisconnected, setDeviceDisconnected] = useState<boolean>(false);
    const [search, setSearch] = useState("")

    const defaultFilters = ["1", "2", "3", "4", "5"]

    const [logLevels, setLogLevels] = useState<string[]>(defaultFilters)

    const [selectedReqIndex, setSelectedReqIndex] = useState<number>()

    const didSelect = (req: Request) => {
        console.log(req.id)
        setSelectedReqIndex(Number(req.id))
        onSelectRequest(req)
    }

    const getData = useCallback(async (sessionId: string, startIndex: string) => {
        if (sessionId !== undefined && !pause) {
            const liveAPI = new LiveAPI()
            const newRequests = await liveAPI.getRequests(sessionId, startIndex)
            if (newRequests.length > 0) {
                console.log(`Current requests ${requests.length} requests`)
                console.log(`Receive ${newRequests.length} requests`)
                const r = [...newRequests.reverse(), ...requests];
                console.log(`New requests array:  ${r.length} requests`)
                setRequests(r)

                let lastElement = newRequests[0]
                setStartIndex("" + lastElement.id)
                console.log(`New startIndex  ${startIndex}`)

            }
            else {
                console.log(`0 new requests`)
            }
        }
    }, [requests, startIndex, pause])

    useEffect(() => {
        if (autoScrolling) {
            document.getElementById("down_anchor")?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [autoScrolling])

    useEffect(() => {

        if (sessionId !== undefined) {
            getData(sessionId, startIndex);
            if (autoScrolling) {
                document.getElementById("down_anchor")?.scrollIntoView({ behavior: 'smooth' });
            }
        }
        const intervalCall = setInterval(() => {
            if (sessionId != undefined) {
                getData(sessionId, startIndex);
            }
        }, 2000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };

    }, [sessionId, requests, startIndex, pause, getData]);

    useEffect(() => {
        if (sessionId != undefined) {
            setRequests([])
            setDeviceDisconnected(false)
            setStartIndex("0")
        } else {
            setDeviceDisconnected(requests.length > 0)
        }
    }, [sessionId])

    const changeLogLevel = (levels: number[] | string) => {

        if (levels === "all") {
            setLogLevels(defaultFilters)
        }
        else if (Array.isArray(levels)) {
            let levelsString = levels.map((num) => (num + 1).toString())
            if (isEmptyArray(levelsString)) {
                levelsString = ["all"]
            }
            setLogLevels(levelsString)

            console.log(levelsString)
        }

    }

    return (
        <div className="flex-grow flex flex-col overflow-auto">
            <div>
                <div className="flex w-full pb-4 justify-between items-end">
                    <div className="flex flex-col gap-1">
                        <Label htmlFor={""} label={"HTTP Codes"} />
                         <div className="flex flex-row gap-2 items-baseline">
                        <ButtonGroup mode="multi" onClick={(levels) => changeLogLevel(levels)} >
                            <p>1xx</p>
                            <p>2xx</p>
                            <p>3xx</p>
                            <p>4xx</p>
                            <p>5xx</p>
                        </ButtonGroup>
                         <TextInput name={""}
								placeholder="Search" onChange={ e => setSearch(e.target.value) } />
                       </div>
                       
                    </div>
                    <div className="space-x-2 h-fit">
                        <Tooltip content="Clear output">
                            <button className="rounded-md p-2 text-sm bg-gray-50 text-black ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100 hover:ring-gray-700/10" onClick={() => setRequests([])}>
                                <TrashIcon className="size-4" />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className="table-container flex-grow ">
                <table>
                    <thead className="sticky top-0 bg-white rounded-md">
                        <tr>
                            {/* <th className="w-2"></th> */}
                            <th className="sticky py-1 top-0">URL</th>
                            <th className="sticky py-1 top-0 w-24">Method</th>
                            <th className="sticky py-1 top-0 w-16">Code</th>
                            {/* <th className="sticky py-1 top-0 bg-gray-100 w-24">Time</th> */}
                            <th className="sticky py-1 top-0 w-28">Duration</th>
                            <th className="sticky py-1 top-0 w-28">Request Size</th>
                            <th className="sticky py-1 top-0 w-28">Response Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            requests.length > 0 ? (
                                requests
                                    .filter(req => logLevels.includes(req.code.toString().charAt(0)))
                                    .filter(req => {  
                                        if (search != "") { 
                                            return (req.url.toLowerCase().includes(search.toLowerCase()) || req.requestBody.toLowerCase().includes(search.toLowerCase()) || req.responseBody.toLowerCase().includes(search.toLowerCase())) 
                                        }
                                        else {
                                            return req
                                        }
                                    })
                                    .map((req) => (
                                        <RequestRow key={req.id} req={req} sessionId={sessionId} onSelectRequest={didSelect} selected={Number(req.id) == selectedReqIndex} />
                                    ))
                            ) : (
                                <tr>
                                    <td colSpan={6} className="p-5">

                                        <div className="font-semibold flex text-gray-700 flex-row items-center gap-1 bg-gray-100 p-3 rounded-md">
                                            <GlobeAltIcon className="size-4" />
                                            Noting to show
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            deviceDisconnected && (
                                <div className="text-orange-500 font-semibold flex flex-row items-center gap-1 py-4">
                                    <WifiIcon className="size-6" />
                                    <p className="whitespace-nowrap">Device disconnected</p>
                                </div>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div id="down_anchor"></div>
        </div>
    )
}

const RequestRow: React.FC<ReqProps & SelectableReqProps> = ({ req, onSelectRequest, selected }) => {

    const select = () => {
        onSelectRequest(req!)
    }

    const status = (req: Request) => {
        if (req.code >= 200 && req.code < 300) {
            return (<p className="w-2 h-2 rounded-full bg-green-500"></p>)
        }
        else {
            return (<p className="w-2 h-2 rounded-full bg-red-500"></p>)
        }
    }

    const method = (req: Request) => {
        const method = req.method.toLocaleLowerCase()

        switch (method) {
            case "get": return (<Badge type={BadgeType.SUCCESS} text="GET" customClass="text-xs" />)
            case "post": return (<Badge type={BadgeType.NEUTRAL} text="POST" customClass="text-xs" />)
            case "put": return (<Badge type={BadgeType.WARNING} text="PUT" customClass="text-xs" />)
            case "patch": return (<Badge type={BadgeType.PURPLE} text="PATCH" customClass="text-xs" />)
            case "delete": return (<Badge type={BadgeType.ERROR} text="DELETE" customClass="text-xs" />)
            default: return (<Badge type={BadgeType.INFOLIGHT} text={method.toUpperCase()} customClass="text-xs" />)
        }
    }

    return (
        <tr className={`cursor-pointer even:bg-gray-50 odd:bg-white text-sm`} onClick={select}>

            <td className={` ${selected ? "bg-blue-100" : ""} flex flex-row items-center gap-1 font-semibold`}>
                {status(req!)}
                {
                    req!.url.length > 200 
                    ? (req!.url.substring(0, 200) + "...")
                    : req?.url
                }
            </td>
            <td className={`w-24 ${selected ? "bg-blue-100" : ""}`}>{method(req!)}</td>
            <td className={`w-16 ${selected ? "bg-blue-100" : ""}`}>{req!.code}</td>
            {/* <td className={` ${selected ? "bg-blue-100" : ""}`}>{req!.time}</td> */}
            <td className={`w-28 ${selected ? "bg-blue-100" : ""}`}>{req!.duration/1000}s</td>
            <td className={`w-28 ${selected ? "bg-blue-100" : ""}`}>
                <span className="inline-flex items-center">
                    <ArrowLongUpIcon className="w-3 h-3" />
                    {req!.requestSize/1000} KB
                </span>
            </td>
            <td className={`w-28 ${selected ? "bg-blue-100" : ""}`}>
                <span className="inline-flex items-center">
                    <ArrowLongDownIcon className="w-3 h-3" />
                    {req!.responseSize/1000} KB
                </span>
            </td>
        </tr>
    )
}

export default RequestList