import { Crash } from "../../data/model/Crash";

export class CrashesUseCases {

	/**
	 * Get crashes group by exception, file, function and line
	 * @returns A list of crashes
	 * @throws
	 */
	getCrashesFilteredByOccurrence(input: Crash[]): Crash[] {

		const final: Crash[] = []
		const crashMap = new Map<string, Crash[]>()
		input.reduce((previousValue, crash, currentIndex, crashes) => {

			var groupByKey = `${crash.exception}${crash.file}${crash.function}${crash.line}`
			if (crash.app != undefined) {
				groupByKey += crash.app.id
			}

			if (!crashMap.has(groupByKey)) {
				crashMap.set(groupByKey, [])
			}

			crashMap.get(groupByKey)?.push(crash)

			return crashMap;
		}, {});

		crashMap.forEach((crashes) => {
			if (crashes.length > 0) {
				const crash = crashes.at(0)!
				crash.occurences = crashes.length
				final.push(crash)
			}
		})

		return final.sort((a, b) => {
			if (a.occurences > b.occurences)
				return -1
			else
				return 1
		})
	}
}