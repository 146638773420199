import { FC, useCallback, useEffect, useState } from "react"
import { Button } from "../../../_components/buttons/Button"
import { useAuth } from "../../../../hooks/useAuth"
import { UserGroupType } from "../../../../data/model/UserGroup"
import { TextInput } from "../../../_components/TextInput"
import { Tooltip } from "../../../_components/Tooltip"
import { Avatar } from "../../../_components/avatar/Avatar"
import { AppsAvatar } from "../../../_components/AppsAvatar"
import { ButtonGroup } from "../../../_components/ButtonGroup"
import { PencilSquareIcon, TrashIcon, UserPlusIcon, UserMinusIcon, ChevronDownIcon, InformationCircleIcon, PlusIcon, EllipsisHorizontalCircleIcon, EllipsisHorizontalIcon, UsersIcon } from "@heroicons/react/24/outline"
import { StarIcon } from "@heroicons/react/24/solid"
import { Badge, BadgeType } from "../../../_components/Badge"
import { RemoveUserModal } from "./components/remove/RemoveUserModal"
import { Alert } from "../../../_components/Alert"
import { TeamExtended, TeamsViewModel } from "./TeamsViewModel"
import { observer } from 'mobx-react-lite';
import { EditTeam } from "./components/edit/EditTeam"
import { AddUserModal } from "./components/invite/AddUserModal"
import { TeamBadge } from "../_components/TeamBadge"
import { AddUserViewModel } from "./components/invite/AddUserViewModel"
import { RemoveUserViewModel } from "./components/remove/RemoveUserViewModel"
import { CreateTeamModal } from "./components/CreateTeamModal"
import { CreateTeamViewModel } from "./components/CreateTeamViewModel"
type Props = {
	viewModel: TeamsViewModel
}

const Teams: FC<Props> = observer(({ viewModel }: Props) => {

	const { workspace } = useAuth()
	const [createTeamModalVisible, setCreateTeamModalVisible] = useState(false)
	const [isRemoveUserVisible, setIsRemoveUserVisible] = useState<{ visible: boolean; team: TeamExtended | undefined }>({ visible: false, team: undefined });
	const [isAddUserVisible, setIsAddUserVisible] = useState<{ visible: boolean; team: TeamExtended | undefined }>({ visible: false, team: undefined });
	const [isEditTeamVisible, setIsEditTeamVisible] = useState<{ visible: boolean; team: TeamExtended | null }>({ visible: false, team: null });
	const [isDeleteTeamVisible, setIsDeleteTeamVisible] = useState<{ visible: boolean; team: TeamExtended | null }>({ visible: false, team: null });

	useEffect(() => {
		viewModel.load()
	}, [viewModel, isRemoveUserVisible, isAddUserVisible, isDeleteTeamVisible, createTeamModalVisible])

	return (
		<div className='px-6 h-full overflow-y-scroll'>
			<p className='mt-4 mb-2 font-extrabold text-lg'>Manage your teams</p>

			<div className="text-gray-600  text-sm">A team is a group of users with permission to access one or more applications. <br />
				The defaut team <Badge text={<div className="flex flex-row items-center gap-1"><p>Workspace</p><StarIcon className="size-3 text-yellow-500" /></div>} type={BadgeType.NEUTRAL} /> grants all members access to every application within the workspace.<br />
				You can create custom teams to provide selected users access to specific applications.</div>
			<div className="bg-slate-100 text-gray-600  text-sm w-fit mt-2 p-2 px-3 rounded-md flex flex-row items-center gap-1"><InformationCircleIcon className="size-3" /><p>In future releases, teams will support more granular management rights for enhanced control.</p></div>
			<div className="flex justify-between items-center mt-6">
				<TextInput name="Search" placeholder="Search..." />
				<Button type="dark" text={"New team"} onClick={() => setCreateTeamModalVisible(true)} customClass="mb-3">
					<span className="flex flex-row items-center gap-2 ">
						<UsersIcon className="size-4" />
						New team
					</span>
				</Button>
			</div>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Users</th>
							<th>Apps</th>
							<th className="w-40">Actions</th>
						</tr>
					</thead>
					<tbody>
						{
							viewModel.teams?.map(team => {
								return (
									<TeamRow
										team={team}
										onEditTeam={() => setIsEditTeamVisible({ visible: true, team: team })}
										onAddUser={() => setIsAddUserVisible({ visible: true, team: team })}
										onRemoveUser={() => setIsRemoveUserVisible({ visible: true, team: team })}
										onDeleteTeam={() => setIsDeleteTeamVisible({ visible: true, team: team })} />
								)
							})
						}
					</tbody>
				</table>
			</div>
			{/* -------------------------------------------------------------- */}
			{/* Modal & Alerts */}
			{/* -------------------------------------------------------------- */}
			<RemoveUserModal visible={isRemoveUserVisible.visible} viewModel={new RemoveUserViewModel(workspace, isRemoveUserVisible.team)} onClose={() => setIsRemoveUserVisible({ visible: false, team: undefined })} />
			<AddUserModal visible={isAddUserVisible.visible} onClose={() => setIsAddUserVisible({ visible: false, team: undefined })} viewModel={new AddUserViewModel(workspace, isAddUserVisible.team)} />
			<EditTeam visible={isEditTeamVisible.visible} team={isEditTeamVisible.team?.userGroup} onClose={() => setIsEditTeamVisible({ visible: false, team: null })} />
			<CreateTeamModal visible={createTeamModalVisible}
				onClose={() => setCreateTeamModalVisible(false)}
				viewModel={new CreateTeamViewModel(workspace!, () => {
					setCreateTeamModalVisible(false)
				})} />

			<Alert
				visible={isDeleteTeamVisible.visible}
				title="Are you sure ?"
				message={<>Are you sure you want to delete the <span className="whitespace-nowrap font-bold">{isDeleteTeamVisible.team?.userGroup?.name}</span> team ?<br /><br /> Deleting this team will revoke access to all associated applications for its members.</>}
				primaryButtonTitle={'Yes'} primaryCallback={async () => {
					if (isDeleteTeamVisible.team?.userGroup.id != null) {
						viewModel.deleteTeam(isDeleteTeamVisible.team?.userGroup.id)
						setIsDeleteTeamVisible({ visible: false, team: null })
					}
				}}
				secondaryButtonTitle='Cancel' secondaryCallback={() => { setIsDeleteTeamVisible({ visible: false, team: null }) }}
				closable={false}
				type="destructive"
			/>
		</div>
	)
})

type TeamRowProps = {
	team: TeamExtended
	onEditTeam: (team: TeamExtended) => void
	onAddUser: (team: TeamExtended) => void
	onRemoveUser: (team: TeamExtended) => void
	onDeleteTeam: (team: TeamExtended) => void
}

const TeamRow: FC<TeamRowProps> = (props) => {

	return (
		<tr key={props.team.userGroup.id}>
			<td className="font-bold">
				<TeamBadge team={props.team.userGroup} />
			</td>

			<td>
				<Tooltip visible={(props.team.users ?? []).length > 0 ? undefined : false} content=
					{
						<div className="flex flex-col gap-2">
							{
								props.team.users ? (
									props.team.users.map(user => (
										<div key={user.id} className="flex flex-row items-center gap-3">
											<Avatar key={user.id} user={user} />
											{
												user.status != "PENDING"
													? (<p className="font-semibold">{user.firstname} {user.lastname}</p>)
													: (<p className="font-semibold">{user.email}</p>)
											}

										</div>
									))
								) : null
							}
						</div>
					}>
					<p className="w-fit">
						{props.team.users?.length === 0 ?
							(
								<>No user affected in this team</>
							) : (
								<div className="flex flex-row gap-1 items-center">
									<p>{props.team.users?.length === 1 ? "1 user" : `${props.team.users?.length} users`}</p>
									<ChevronDownIcon className="size-2 cursor-pointer" />

								</div>
							)}
					</p>
				</Tooltip>

			</td>
			<td>
				<div className="flex flex-row items-center gap-2">
					{
						props.team.userGroup.type === UserGroupType.WORKSPACE ? (
							<Badge text="All apps" type={BadgeType.SECURE} />
						) : (
							<AppsAvatar apps={props.team.apps} size="sm" />
						)
					}
				</div>
			</td>
			<td>
				<ButtonGroup mode="click" customClass="ml-auto">

					{
						props.team.userGroup.type !== UserGroupType.WORKSPACE &&
							props.team.userGroup.type !== UserGroupType.APP ? (
							<Tooltip content="Delete team">
								<TrashIcon className="size-4" onClick={() => props.onDeleteTeam(props.team)} />
							</Tooltip>
						) : null
					}
					<Tooltip content="Remove user">
						<UserMinusIcon className="size-4" onClick={() => props.onRemoveUser(props.team)} />
					</Tooltip>
					<Tooltip content="Invite user">
						<UserPlusIcon className="size-4" onClick={() => props.onAddUser(props.team)} />
					</Tooltip>
					{
						props.team.userGroup.type !== UserGroupType.WORKSPACE &&
							props.team.userGroup.type !== UserGroupType.APP ? (
							<Tooltip content="Edit team">
								<PencilSquareIcon className="size-4" onClick={() => props.onEditTeam(props.team)} />
							</Tooltip>
						) : null
					}
				</ButtonGroup>
			</td>
		</tr>
	)
}

export { Teams }
function updateState(arg0: {}): any {
	throw new Error("Function not implemented.")
}

