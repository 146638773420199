import { FC, useEffect, useState } from "react"
import { Modal } from "../../../../_components/Modal"
import { EnvelopeIcon, HandRaisedIcon, UserGroupIcon, UserPlusIcon, UsersIcon  } from "@heroicons/react/24/outline" 
import { Carousel } from "../../../../_components/Carousel"
import { InviteUser } from "./email-form/InviteUser"
import { SelectTeam } from "./team-form/SelectTeam"
import { InviteUserViewModel } from "./InviteUserViewModel"
import { observer } from "mobx-react-lite"

type Props = {
	visible: boolean
	onClose: () => void
	viewModel: InviteUserViewModel
}

const InviteUserModal: FC<Props> = observer((props) => {

	useEffect( ()=> {
		props.viewModel.load()
	}, [props.viewModel])

	return (
		<Modal 
			title={'Invite user'} 
			message={""} 
			visible={ props.visible }
			onClose={ props.onClose }
			>
			<div>
				<div className="flex flex-row px-3 mt-3 mb-6 align-baseline items-center">
					<div className={`flex flex-col gap-2  items-center text-sm ${ props.viewModel.step == 0 ? "font-semibold" : "text-gray-400"}  `}>
						<EnvelopeIcon className="size-5 "/>
						<p>Invitation</p>
					</div>
					<div className="h-full w-full mx-3 border-b-1 border-dashed"></div>
					<div className={`flex flex-col gap-2  items-center text-sm ${ props.viewModel.step == 1 ? "font-semibold" : "text-gray-400"}  `}>
						<UserGroupIcon className="size-5"/>
						<p className="text-sm whitespace-nowrap">Teams & Projects</p>
					</div>
					{/* <div className="h-full w-full mx-3 border-b-1 border-dashed"></div>
					<div className={`flex flex-col gap-2  items-center text-sm ${ props.viewModel.step == 2 ? "font-semibold" : "text-gray-400"}  `}>
						<HandRaisedIcon className="size-5"/>
						<p className="text-sm whitespace-nowrap">Access rights</p>
					</div> */} 
				</div>
				<div className="border-b-1 h-1 mb-7"></div>
				<Carousel 
					confirmIsLoading={props.viewModel.isInviting}
					confirmTitle={  props.viewModel.title }
					onTabChange={ (step) => props.viewModel.goToStep(step) }
					canShowNext={ props.viewModel.nextIsActive } >
						<InviteUser viewModel={props.viewModel} />			
						<SelectTeam viewModel={props.viewModel} />
				</Carousel>
			</div>
		</Modal>
	)
})

export { InviteUserModal }