import { FC } from "react"
import { Label } from "../../../../../_components/Label"
import { TextInput } from "../../../../../_components/TextInput"
import { Step, CreateTeamViewModel } from "../CreateTeamViewModel"

type Props = {
	viewModel: CreateTeamViewModel
}

const TeamSettings: FC<Props> = ({ viewModel }) => {

	return (
		<div className="flex flex-col w-full pt-4">
			<Label htmlFor={""} label={"Team name"} style="md" />
			<TextInput name="name" size="xl" placeholder="Please enter your team name" value={""} customClass="mt-1" onChange={(e) => {
				viewModel.changeTeamName(e.target.value)
			}} />
			{
				viewModel.teamNameError && (
					<p className="text-xs mt-1 text-red-500">Team name alreay exist.</p>
				)
			}
		</div>
	)
}
export { TeamSettings }