import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { UserGroup, UserGroupType } from "../../../../../data/model/UserGroup";
import { CompanyAPI } from "../../../../../data/api/CompanyAPI";
import { UserGroupsAPI } from "../../../../../data/api/UserGroupsAPI";
import { App } from "../../../../../data/model/App";
import { Company } from "../../../../../data/model/Company";
import { User } from "../../../../../data/model/User";
import { UserAPI } from "../../../../../data/api/UserAPI";

class TeamWithApps {
	team: UserGroup
	apps: App[] | undefined

	constructor(team: UserGroup, apps: App[] | undefined) {
		this.team = team
		this.apps = apps
	}
}

class SelectableTeamWithApp {
	teamWithApp: TeamWithApps
	selected: boolean = false

	constructor(teamWithApp: TeamWithApps) {
		this.teamWithApp = teamWithApp
	}
}

class SelectableApp {
	app: App
	selected: boolean = false

	constructor(app: App) {
		this.app = app
	}
}

enum Step {
	MAIL = 0,
	TEAM = 1,
	CONFIRM = 2
}

class InviteUserViewModel {

	private workspace: Company| undefined
	private users: User[] = []

	step = Step.MAIL
	email: string = ""
	apps: SelectableApp[] = []
	teams: SelectableTeamWithApp[] = []
	workspaceIsSelected: boolean = true
	isInviting = false

	onInviteFinish: () => void

	constructor(workspace: Company | undefined, onFinish: () => void) {
		this.workspace = workspace
		this.onInviteFinish = onFinish

		makeObservable(this, {
			step: observable,
			apps: observable,
			teams: observable,
			email: observable,
			isInviting: observable,
			workspaceIsSelected: observable,
			nextIsActive: computed,
			emailError: computed,
			title: computed
		});
	}
	
	/// View life cycle
	/// ---------------------------------------------------------------------------------------------------------------------------------------
	load() {
		(async () => {
			this.getApps()
			this.getTeams()
			this.getUsers()
		})()
	}

	get title()  {
		switch(this.step) {
			case Step.MAIL:
				return "Next"
			case Step.TEAM:
				return "Invite"
			case Step.CONFIRM:
				return "Inviting..."
		}
	}

	private isEmailValid() {
		if (!this.email) return true;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(this.email);
	}

	private isEmailAlreadyExist() {
		if (this.users.map((u) => u.email).includes(this.email)) {
			return true
		}
		return false
	}


	get emailError(): string|null {
		console.log(`Check email error ${this.isEmailValid()}`)
		if (!this.isEmailValid()) return "Please enter a valid email address."
		if (this.isEmailAlreadyExist()) return `${this.email} is already a member of ${this.workspace?.name} workspace. Please manage this user in Teams.`
		return null
	}

	get nextIsActive() {
		switch(this.step) {
			case Step.MAIL:
				return this.email != "" && this.isEmailValid() && !this.isEmailAlreadyExist()
			case Step.TEAM:
				if (this.workspaceIsSelected == true) {
					return true
				}
				else {
					return (this.apps.filter( (a) => a.selected ).length +
						   this.teams.filter( (t) => t.selected ).length) > 0
				}
			case Step.CONFIRM:
				return false
		}
		return false
	}

	setEmail(email: string) {
		this.email = email
	}

	/// Load apps & teams
	/// ---------------------------------------------------------------------------------------------------------------------------------------
	getApps = async () => {
		const api = new CompanyAPI()
		if (this.workspace != null) {
			const response = await api.getAppsForCompany(this.workspace.id)
			this.apps = response.map( (a) => new SelectableApp(a))
		}
	}

	getTeams = async () => {
		const api = new CompanyAPI()
		if (this.workspace != null) {
			const response = await api.getUsergroupsForCompany(this.workspace.id)
			const teamsWithApps: TeamWithApps[] = await Promise.all(
				response.flatMap(async (team) => {
					const apps = await this.getAppsInTeam(team.id!)
					return new TeamWithApps(team, apps)
				})
			)
			this.teams = teamsWithApps.map ( (t) => new SelectableTeamWithApp(t))
		}
	}

	private getAppsInTeam = async (teamId: string) => {
		const groupAPI = new UserGroupsAPI()
		return await groupAPI.appsForUserGroup(teamId)
	}

	private getUsers = async () => {
		const api = new CompanyAPI()
		const response = await api.getUsersForCompany(this.workspace!.id)
		this.users = response
	}

	/// Actions
	/// ---------------------------------------------------------------------------------------------------------------------------------------
	
	goToStep(step: Step) {
		this.step = step
		if (this.step === Step.CONFIRM) {
			this.performInvitation()
		}
	}
	
	handleWorkspaceSelection(selected: boolean) {
		this.workspaceIsSelected = selected
		if (selected) {
			this.apps.forEach( (a) => a.selected = false )
			this.teams.forEach( (t) => t.selected = false )
		}
	}

	handleAppSelection(appId: string, selected: boolean) {
		this.apps = this.apps.map((app) =>
        app.app.id === appId 
            ? { ...app, selected: selected }
            : app
		)
	}

	handleTeamSelection(appId: string, selected: boolean) {
		this.teams = this.teams.map((team) =>
        team.teamWithApp.team.id === appId 
            ? { ...team, selected: selected }
            : team
		)
	}

	private async performInvitation() {
		this.isInviting = true
		let teams: string[] = [] 
		if (this.workspaceIsSelected) {
			const workspaceUserGroup = this.teams.filter ( (t) => t.teamWithApp.team.type === UserGroupType.WORKSPACE).map( (t) => t.teamWithApp.team)[0]
			teams.push(workspaceUserGroup.id)
		}
		else {
			teams = this.teams.filter( (a) => a.selected).map( (a) => a.teamWithApp.team.id)
			
			console.log(`Selected apps : ${teams}`)
		}
		console.log(`Inviting ${this.email} in teams: ${teams}`)

		const api = new UserAPI()
		await api.inviteUserInMultilpleTeams(this.workspace!.id, this.email, teams)
		this.isInviting = false

		this.onInviteFinish()
	}
}

export { InviteUserViewModel }