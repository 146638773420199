import { ReqProps } from "../ReqProp"

const RequestHeader: React.FC<ReqProps> = ({ req }) => {

    return (
        <div>
            {req ? (
                req.requestHeaders.length > 0 ? (
                    req.requestHeaders.map((h) => {
                        return <p key={h}><span className="fw-bold text-xs">{h} </span></p>
                    })
                ) : (<p>No headers sent</p>)
            ) : (<p></p>)}
        </div>
    )
}

export default RequestHeader