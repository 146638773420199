import { axiosInstance } from '../utils/RequestUtils';
import { User } from '../model/User';
import { App } from '../model/App';
import { UserGroup } from '../model/UserGroup';
import { AxiosResponse } from 'axios';

const BASE = `/usergroup`

export class UserGroupsAPI {

	async getUsersForUserGroup(userGroupId: string): Promise<User[]> {
		const response = await axiosInstance.get(`${BASE}/${userGroupId}/users`)
		return response.data
	}

	async appsForUserGroup(userGroupId: string): Promise<App[]> {
		const response = await axiosInstance.get(`${BASE}/${userGroupId}/apps`);
		return response.data
	}

	async createUserGroup(name: string, usersId: string[], appsId: string[]): Promise<UserGroup | undefined> {
		return axiosInstance.post(`${BASE}`, {
			name: name,
			usersId: usersId,
			appsId: appsId
		}).then((d: AxiosResponse<UserGroup>) => {
			return d.data
		}).catch(() => {
			return undefined
		})
	}

	async editUserGroup(group: UserGroup): Promise<UserGroup> {
		const response = await axiosInstance.patch(`${BASE}/${group.id}`, group);
		return response.data
	}

	async deleteUserGroup(userGroupId: string): Promise<UserGroup> {
		const response = await axiosInstance.delete(`${BASE}/${userGroupId}`);
		return response.data
	}

	async deleteUser(userGroupId: string, userId: string): Promise<UserGroup> {
		const response = await axiosInstance.delete(`${BASE}/${userGroupId}/users/${userId}`);
		return response.data
	}

	async inviteUsers(userGroupId: string, userIds: string[]): Promise<void> {

		const response = await axiosInstance.post(`${BASE}/${userGroupId}/users`, { users: userIds });
		return response.data
	}

	async removeUsers(userGroupId: string, userIds: string[]): Promise<void> {

		const response = await axiosInstance.delete(`${BASE}/${userGroupId}/users`, { data: { users: userIds } });
		return response.data
	}
}