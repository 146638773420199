import { useState } from "react"
import { ResponseHeader } from "./response/ResponseHeader"
import { ResponseBody } from "./response/ResponseBody"
import { ReqProps } from "./ReqProp"

const RequestResponse:React.FC<ReqProps> = ({ req }) => {

	const [tab, setTab] = useState('Header')

	return (
		<div className="h-full flex flex-col">
			<ul className="inline-flex space-x-4 py-4">
				<li><a className="font-bold">Response</a></li>
				<li><a className={`cursor-pointer underline-offset-8 ${tab === 'Header' ? 'font-semibold underline' : '' }` } onClick={() => setTab('Header')}>Headers</a></li>
				<li><a className={`cursor-pointer underline-offset-8 ${tab === 'Body' ? 'font-semibold underline' : '' }` } onClick={() => setTab('Body')}>Body</a></li>
			</ul>
			<div className="h-full">
				<div className="overflow-hidden h-full">
				{
					{
						'Header': <ResponseHeader req={req}/>,
						'Body': <ResponseBody req={req}/>
					}[tab]
				}
				</div>
			</div>
		</div>
	)
}
export { RequestResponse }