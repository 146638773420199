import { Badge, BadgeType } from "../../../../../_components/Badge"

// Simple props object used by every page and components related by MobSF
export type MobSFProps = {
    severity: string
}

export const MobSFSeverityBadge: React.FC<MobSFProps> = ({ severity }) => {

    return ( 
        (() => {
            let badgeType = BadgeType.NEUTRAL
            switch (severity) {
                case "info": { 
                    badgeType = BadgeType.NEUTRAL
                    break; 
                } 
                case "warning": { 
                    badgeType = BadgeType.WARNING
                    break; 
                }
                case "high": { 
                    badgeType = BadgeType.ERROR
                    break; 
                }
                case "OK": {
                    badgeType = BadgeType.SUCCESS
                    break;
                }
                case "KO": {
                    badgeType = BadgeType.ERROR
                }
            }
            return <Badge type={ badgeType } text={ severity } customClass="text-sm"/>
        })()
    )
}