import { FC, PropsWithChildren, useEffect, useState } from "react"
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import { Tooltip } from "../../../../../_components/Tooltip"

type Props = {
	title: string
	subtitle: string,
	expanded: boolean
}

const SecuritySection: FC<PropsWithChildren<Props>> = ({ title, subtitle, expanded, children }) => {

	const [collapsed, setCollapsed] = useState(!expanded)

	useEffect(() => {
		setCollapsed(!expanded)
	}, [expanded])

	return (
		
		<div className='mb-16'>

			<div className="mb-2">
				<button  className="flex flex-row items-center" onClick={() => { setCollapsed(!collapsed) }}>
					<p className='font-bold text-xl me-2'>{title}</p>
					<Tooltip content={ collapsed ? "Expand" : "Collapse" }>
					{
						collapsed ? <ChevronDownIcon className="w-4 h-4" /> : <ChevronUpIcon className="w-4 h-4" />
					}
					</Tooltip>
				</button>	
			</div>

			<p className="mb-8 text-gray-500">{subtitle}</p>
			<div className={`pb-4 ${collapsed ? "hidden" : "visible"}`}>
				{children}
			</div>
		</div>
	)

}

export { SecuritySection }