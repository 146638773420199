import { DomainInfo } from "../../../../../../data/model/MobSF";
import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection";
import { MobSFSeverityBadge } from "./MobSFSeverityBadge";
import { Tooltip } from "../../../../../_components/Tooltip";
import { BugAntIcon } from '@heroicons/react/24/outline'

const DomainMalwareCherker: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="Domain Malware Check" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum." expanded={expanded}>
			
				<div className="table-container">
					<table className="table-auto">
					<thead>
						<tr>
							<th>Domain</th>
							<th>Status</th>
							<th>Geolocation</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
					{
						report.domains ? (
							(() => {
								const rows: React.ReactElement[] = [];
								for (const [key, value] of Object.entries(report.domains)) {
									rows.push(<DomainRow key={ key } name={ key } domain={ ((value as unknown) as DomainInfo) } />)
								}
								return rows
							})()
						): <></>
					}
					</tbody>
					</table>
				</div>
			
		</SecuritySection>
	)
}

type DomainRowProps = {
   name: string 
   domain: DomainInfo
}

const DomainRow: React.FC<DomainRowProps> = ({ name, domain }) => {

	return (   
		<tr>
			<td className="font-bold break-all">{ name }</td>
			<td>
				<MobSFSeverityBadge severity={ (domain.bad) ? "OK" : "KO"} />
			</td>
			
			<td>
				<span className="font-bold">IP :</span>{ domain.geolocation?.ip ? (domain.geolocation.ip): "  unknown" }<br/>                            
				<span className="font-bold">Location :</span>{ domain.geolocation?.city ? (domain.geolocation?.city) : "  unknown" }, { domain.geolocation?.region ?  (domain.geolocation?.region) :  "  unknown" }, { domain.geolocation?.country_long ? (domain.geolocation?.country_long) : "  unknown" }<br/>
				<span className="font-bold">Coordinates :</span>{ domain.geolocation?.latitude ? (domain.geolocation?.latitude) : "x" }, { domain.geolocation?.longitude ? (domain.geolocation?.longitude) : "x" }<br/>
			</td>
			<td className="text-right">
				<Tooltip content={<span>Declare an issue</span>}>
					<button className="create-issue border-1 rounded-md p-3 hover:bg-gray-50">
						<BugAntIcon className="size-4" />
					</button>
				</Tooltip>
			</td>
		</tr>
	)
}

export { DomainMalwareCherker }