import { App } from "./App"
import { SAMLConfig } from "./SAMLConfig"

export enum AuthMethod {
	DELIVER = "DELIVER", // Default auth method, login and password deliver
	GOOGLE = "GOOGLE", // Google Oauth2
	SAML = "SAML" // SAML Oauth2
}
export type Company = {
	id: string
	name?: string
	icon?: string
	iconFile?: File
	email?: string
	createdAt?: string
	updatedAt?: string
	apps?: App[]
	authMethods?: AuthMethod[]
	samlIssuer?: string

	// added
	samlConfig?: SAMLConfig
}