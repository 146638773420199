export enum UserGroupType {
	WORKSPACE = 'WORKSPACE',
	APP = 'APP',
	BUILD = 'BUILD',
	CUSTOM = 'CUSTOM'
}

export class UserGroup {
	id: string = ""
	name: string = ""
	description?: string
	avatar?: string
	createdAt?: string
	updatedAt?: string
	type: UserGroupType = UserGroupType.APP
}