export class Utils {
    static breakLongWords(inputString: string) {
        // Regular expression to find words longer than 26 characters
        const longWordRegex = /\w{27,}/g;

        // Function to insert spaces every 26 characters in a long word
        function insertSpaces(word: string) {
            // Insert a space every 26 characters
            return word.replace(/(.{26})/g, '$1 ');
        }

        // Replace each long word in the string with its spaced version
        return inputString.replace(longWordRegex, insertSpaces);
    }
}