import { Dispatch, SetStateAction, createContext, useContext } from "react"
import { User } from "../data/model/User"
import { Company } from "../data/model/Company"

type AuthContextProps = {
    user: User | undefined
    workspace: Company | undefined
    setUser: Dispatch<SetStateAction<User | undefined>>
    setWorkspace: (newWorkspace: Company | undefined) => void;
}

const initialAuthContext = {
    user: undefined,
    workspace: undefined,
    setUser: () => { },
    setWorkspace: () => { }
}

const AuthContext = createContext<AuthContextProps>(initialAuthContext)

const useAuth = () => {
    return useContext(AuthContext)
}

export { useAuth, AuthContext }