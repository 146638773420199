import {
	useState,
	FC,
	PropsWithChildren,
	Children
} from 'react';
import { Button } from './buttons/Button';
import React from 'react';

type Props = {
	onTabChange?: (selectedTab: number) => void;
	canShowNext: boolean
	confirmTitle: string
	confirmIsLoading?: boolean
}

const Carousel: FC<PropsWithChildren<Props>> = ({ children, onTabChange, canShowNext, confirmTitle, confirmIsLoading }) => {

	const [currentIndex, setCurrentIndex] = useState(0);

	const slides: React.ReactElement[] = Children.toArray(children) as React.ReactElement[]
	const totalSlides = slides.length;

	// const [carouselHeight, setCarouselHeight] = useState<number | undefined>(undefined);

	// Determine the current page status
	const isFirstPage = currentIndex === 0;
	const isLastPage = currentIndex === totalSlides - 1;

	const goToNext = () => {
		if (currentIndex < totalSlides) {
			const index = currentIndex + 1
			if (index <= totalSlides - 1) {
				setCurrentIndex(index)
			}
			onTabChange?.(index)
		}

	};

	const goToPrevious = () => {
		if (currentIndex > 0) {
			const index = currentIndex - 1
			setCurrentIndex(index)
			onTabChange?.(index)
		}
	};


	// Update carousel height based on the active slide's height
	// TODO: Update height automatically doesnt works actually...
	//   useEffect(() => {
	//     if (activeSlideRef.current) {
	// 		console.log(activeSlideRef.current.scrollHeight)
	//       setCarouselHeight(activeSlideRef.current.clientHeight); // Set the height based on the active slide
	//     }
	//   }, [currentIndex]); // Update height whenever currentIndex changes

	return (
		<div className="relative transition-all duration-500">
			<div className="overflow-hidden w-full relative">
				<div
					className="flex transition-transform duration-300 ease-slide-ease"
					style={{ transform: `translateX(-${currentIndex * 100}%)` }}
				>
					{
						Children.map(slides, (slide, index) => {

							return (
								<div key={index}//style={{ height: carouselHeight }} 
									//ref={index === currentIndex ? activeSlideRef : null} // Attach ref to the active slide
									className="w-full h-fit flex-shrink-0 flex items-center justify-center"
								>
									{slide}
								</div>
							)
						})
					}
				</div>
				<div className="flex flex-row gap-2 justify-end mt-8">
					<Button type="secondary" customClass={isFirstPage == true ? "hidden" : ""} text="Previous" onClick={goToPrevious} />
					<Button type="dark" tabIndex={2} loading={confirmIsLoading} enabled={canShowNext} text={confirmTitle} onClick={() => { if (canShowNext) goToNext() }} />
				</div>
			</div>
		</div>
	);
}

Carousel.defaultProps = {
	confirmIsLoading: false
}

export { Carousel };
