import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { useAuth } from "../../hooks/useAuth";
import { AuthPage } from "../pages/auth/AuthPage";
import { NotInvitedPage } from "../pages/NotInvitedPage";

const AppRoutes: FC = () => {

    const { user } = useAuth()
    
    return (
        <BrowserRouter>
            <Routes>
               { user && user.id ? (
                    <>
                    <Route path="/*" element= { <PrivateRoutes /> } />
                   	<Route index element={<Navigate to='/store' />} />
                    </>
               ):(
					<>
                    <Route path='auth/*' element={<AuthPage />} />
                    <Route path='auth/too-early-buddy' element={<NotInvitedPage />} />
                    <Route path='*' element={<Navigate to='/auth' />} />
					</>
                )}
            </Routes>
        </BrowserRouter>
    )
}

export { AppRoutes }