import { Tracker } from "../../../../../../data/model/MobSF";
import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection";

const Trackers: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="Trackers" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum" expanded={expanded}>
			<div className="table-container">
			<table>
				<thead >
					<tr>
						<th>Name</th>
						<th>Category</th>
						<th>URL</th>
					</tr>
				</thead>
				<tbody>
				{
					report.trackers ? (
						(() => {
							const rows: React.ReactElement[] = [];
							for (const tracker of report.trackers.trackers) {
								rows.push(<TrackerRow tracker={tracker} />)
							}
							return rows
						})()
					): <></>
				}
				</tbody>
			</table>
			</div>
		</SecuritySection>
	)
}

type EmailRowProps = {
	tracker: Tracker
}

const TrackerRow: React.FC<EmailRowProps> = ({ tracker }) => {
	
	return (   
		<tr>
			<td><span className="font-bold">{ tracker.name }</span></td>
			<td>{ tracker.categories }</td>
			<td><a href={ tracker.url }>{ tracker.url }</a></td>
		</tr>
	)
}

export { Trackers }