import { FC, PropsWithChildren, useEffect, useState } from "react"
import { useOutsideClick } from "../../hooks/useOuterClick"
import { XMarkIcon } from "@heroicons/react/24/outline"

type Props = {
	title: string,
	message: string,
	visible: boolean
	onClose?: () => void,
	closable?: boolean 	
}

const Modal: FC<PropsWithChildren<Props>> = (props) => {
	
	const [shake, setShake] = useState(false)

	/**
	* Callback used to close the dropdown when user click outside
	* see `useOutsideClick` below
	*/
	const closeOnOutside = () => {
		if (props.closable) {
			props.onClose?.()
		}
	};
	/**
	*  Reference of the dropdown menu div
	*	use to detect if user click outside of the element
	* 	to hide/close dropdown 
	*/
	const modalRef = useOutsideClick(closeOnOutside)
	
	
	// Effect to handle ESC key press
	useEffect(() => {
		
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				if (props.closable === false ) {
					setShake(true)
					setTimeout(() => { setShake(false);  }, 1000);
					
				}
				else {
					props.onClose?.();
				}
			}
		};
		
		// Add event listener for keydown
		window.addEventListener('keydown', handleKeyDown);
		
		// Cleanup event listener on unmount
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);
	
	return <>
		{props.visible && (
			// Todo find better way to size modal than max-w-[40%] and max-h-[40%]
			<div className="absolute inset-0 z-50 flex w-screen overflow-y-auto bg-gray-500 bg-opacity-75">
			
				<div ref={modalRef} className={ `relative animate-fadeIn ${ shake ? "animate-shake" : "" } z-50 m-auto min-w-[600px] h-fit max-w-[700px] max-h-[800px] w-fit border-gray-200 bg-white flex flex-col justify-between border shadow-xl rounded-lg`}>				
					<p className="font-extrabold text-xl p-6 pb-3">{props.title}</p>
					{/* <div className="border-b-1"></div> */}
					<button className="absolute right-2 top-3 p-1.5 hover:bg-slate-100 rounded-md" onClick={  () => { props.onClose?.() } }>
							<XMarkIcon className="size-5 text-gray-800 hover:text-gray-800" />
						</button>
					<div className="px-6 pb-4">
						
					{ props.children }
					</div>
					
				</div>
			</div>
		)}
	</>
}

Modal.defaultProps = {
	visible: false,
	closable: true
}

export { Modal }