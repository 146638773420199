import { AxiosResponse } from "axios";
import { Issue } from "../model/Issue";
import { axiosInstance } from "../utils/RequestUtils";

const BASE = `/issues`

export class IssuesAPI {

	/**
	 * Get all issues from DB
	 * @returns A list of Issue
	 * @throws
	 */
	async getAllIssues(): Promise<Issue[]> {
		const response = await axiosInstance.get(`${BASE}`);
		return response.data
	}

	/**
	 * Get an issue by its ID
	 * @returns An issue
	 * @throws
	 */
	async getIssueById(issuesId: string): Promise<Issue> {
		return axiosInstance.get(`${BASE}/${issuesId}`).then((d: AxiosResponse<Issue>) => {
			return d.data
		})
	}

	/**
	 * Update an issue by its ID
	 * @returns The updated Issue
	 * @throws
	 */
	async updateIssueStatusById(issuesId: string, status: IssueStatus): Promise<Issue> {
		return axiosInstance.patch(`${BASE}/${issuesId}`, {
			status: status
		}).then((d: AxiosResponse<Issue>) => {
			return d.data
		})
	}
}

export enum IssueStatus {
	NEW = "NEW", // First time an issue is linked to a build
	REFUSED = "REFUSED", // If a DEVELOPPED issue is not really fixed
	VALIDATED = "VALIDATED", // If a DEVELOPPED issue is fixed
	CLOSED = "CLOSED" // If a DEVELOPPED issue no longer needs to be seen
}

export enum IssueCriticality {
	LOW = "LOW",
	MEDIUM = "MEDIUM",
	HIGH = "HIGH",
	CRITICAL = "CRITICAL"
}
