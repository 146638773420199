import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
// import { AppEditForm } from '../../apps/create/AppEditForm'
// import { AppUpdateViewModel } from '../../apps/create/viewModel/AppUpdateViewModel'
// import { CopyToClipboardInputCell } from '../../../components/table/CopyToClipboardInputCell'
import { CopyToClipboardInput } from '../../../../_components/CopyToClipboard'
import { Label } from '../../../../_components/Label'
import { ImageInput } from '../../../../_components/ImageInput'
import { Alert } from '../../../../_components/Alert'
import { TextInput } from '../../../../_components/TextInput'
import AppFormViewModel, { FormMode } from './AppFormViewModel'
import { Button } from '../../../../_components/buttons/Button'
import { ButtonGroup } from '../../../../_components/ButtonGroup'
import { Tooltip } from '../../../../_components/Tooltip'
import { PlateformLogo } from '../../../../_components/plateformLogo/plateformLogo'
import { AppType } from '../../../../../data/model/App'
import { Tabs } from '../../../../_components/tabs/Tabs'
import { Tab } from '../../../../_components/tabs/Tab'


type Props = {
	viewModel: AppFormViewModel
}

const AppForm: FC<Props> = ({ viewModel }) => {

	const formRef = useRef<HTMLFormElement>(null);
	const [showConfirmation, setShowConfirmation] = useState(false)

	// Called when user changes file
	const onImageUpload = (file: File) => {
		console.log(file)
		viewModel.iconFile = file
	}
	const [, updateState] = useState<any>()
	const forceUpdate = React.useCallback(() => updateState({}), [])

	useEffect(() => {

	}, [viewModel]);

	const update = async () => {
		await viewModel.onValidate()
		setShowConfirmation(false)
		viewModel.onFinish()
	}

	return (
		<div>
			<form ref={formRef} className="max-w-full">

				<Tabs onTabChange={() => { forceUpdate() }}>
					<Tab title="General" customClass=" flex flex-col">
						<div className="flex-grow h-[500px] overflow-scroll no-scrollbar">
							<div className="mb-5">
								<div className='items-center mb-5'>
									<div className='mb-5'>
										<Label style="md" htmlFor="appName" label="Application name" />
										<TextInput name="appName" required={viewModel.mode == FormMode.CREATE} placeholder="Super App" value={viewModel.name} onChange={(e) => { viewModel.name = e.target.value }} />
									</div>

									<div>
										{viewModel.appId &&
											<div>
												<Label style="md" htmlFor={'appId'} label={'Application ID'} />
												<CopyToClipboardInput text={viewModel.appId} className='w-96' />
											</div>
										}
									</div>
								</div>
								<div className='mb-5'>
									<Label style="md" htmlFor="bundleId" label="Application bundleId" />
									<TextInput name='bundleId' required={viewModel.mode == FormMode.CREATE} placeholder='com.workspace.app' value={viewModel.bundleId} onChange={(e) => { viewModel.bundleId = e.target.value }} />
								</div>
								<div className='mb-5'>
									<Label style="md" htmlFor="groupBy" label="Application group" />
									<TextInput name='groupBy' placeholder='com.workspace.app' value={viewModel.groupBy} onChange={(e) => { viewModel.groupBy = e.target.value }} />
								</div>
								<div>
									<Label style="md" htmlFor="bundleId" label="Plateform" />
									<ButtonGroup index={viewModel.platform == AppType.IOS ? 0 : 1} customClass={"mb-5"} mode={"toggle"} onClick={(selection) => {
										if (selection.includes(0)) {
											viewModel.platform = AppType.IOS
										} else {
											viewModel.platform = AppType.ANDROID
										}
									}}>
										<Tooltip content="Create iOS app">
											<PlateformLogo plateform={AppType.IOS} />
										</Tooltip>
										<Tooltip content="Create Android app">
											<PlateformLogo plateform={AppType.ANDROID} />
										</Tooltip>
									</ButtonGroup>
								</div>
								<ImageInput customClass='mt-5' label="Project icon" name={''} initialeImage={viewModel.icon} onImageUpload={onImageUpload} />
							</div>
						</div>
					</Tab>
					{
						viewModel.mode == FormMode.EDIT ? (
							<Tab title="Integration" customClass=" flex flex-col">
								<div className="flex-grow h-[500px] overflow-scroll no-scrollbar">
									<div className="mb-5">
										<div className='items-center mb-5'>
											<div className='mb-5'>
												<Label style="md" htmlFor="gitlabUrl" label="Gitlab base URL" />
												<TextInput name="gitlabUrl" placeholder="https://gitlab.com" value={viewModel.gitlabUrl} onChange={(e) => { viewModel.gitlabUrl = e.target.value }} />
											</div>
											<div className='mb-5'>
												<Label style="md" htmlFor="jiraUrl" label="Jira base URL" />
												<TextInput name="jiraUrl" placeholder="https://www.jira.com/" value={viewModel.jiraUrl} onChange={(e) => { viewModel.jiraUrl = e.target.value }} />
											</div>
										</div>
									</div>
								</div>
							</Tab>
						) : <></>
					}

				</Tabs>




			</form>
			<div className='flex flex-row gap-2 justify-end mt-12'>
				<Button text={'Save changes'} onClick={() => {
					if (formRef.current && formRef.current.reportValidity()) {
						setShowConfirmation(true)
					}
				}} />
			</div>
			<Alert visible={showConfirmation} title={viewModel.mode == FormMode.CREATE ? `Create ${viewModel.name}` : `Edit ${viewModel.name}`} message={'Are you sure ?'} primaryButtonTitle={'OK'} secondaryButtonTitle="Cancel" primaryCallback={() => {
				update()
			}} secondaryCallback={() => {
				setShowConfirmation(false)
			}} closable={false} />
		</div>
	)
}

export { AppForm }

