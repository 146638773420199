import { FC, useEffect, useState } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; 

type Props = {
	children: React.ReactElement<any>;
	content: React.ReactNode
	visible?: boolean | undefined
}

const Tooltip: FC<Props> = ({ children, content, visible }) => {

	const [tooltipContent, setTooltipContent] = useState(content);

	useEffect(() => {
		setTooltipContent(content)
	}, [content]);

	return (
		<Tippy content={ tooltipContent } visible={visible} >
			{ children }
		</Tippy>
	)
}

export { Tooltip }