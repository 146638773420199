import { ReqProps } from "../ReqProp"
import { format } from "prettier/standalone";
import parserGraphql from "prettier/parser-graphql";
import parserJson from "prettier/parser-babel";
import { useEffect, useState } from "react";

const RequestBody: React.FC<ReqProps> = ({req}) => {

    const [body, setBody] =useState("")

    useEffect(() => {

        // Not really beautiful but functional
        // Try to parser request body firstly as a JSON content, if it fail, try GraphQL, else use raw text
        const formatBody = async () => {
            console.log("Calling format body")
            if (req?.requestBody != null) {
                console.log(req.requestBody)
                try {
                    const json = await format(req.requestBody, { parser: "json", plugins: [parserJson] })
                    setBody(json)
                } catch (error) {
                    try {
                        const graphql = await format(req.requestBody, { parser: "graphql", plugins: [parserGraphql] })
                        setBody(graphql)
                    }
                    catch(error) {
                        setBody(req.requestBody)
                    }
                }
            }
            else {
                setBody("")
            }
        };
        formatBody();
    }, [req]);

	return (
         <div className="h-full text-xs overflow-scroll">
        {req?.requestBody ? (
            <pre>{ body }</pre>
        ): (<></>)
        }
        </div>
    )
}

export default RequestBody