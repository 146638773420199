import { FC } from "react"

type Props = {
	htmlFor: string
	label: string
	style?: "default" | "md"
	fontWeight?: "default" | "bold"
}

const Label: FC<Props> = ({ htmlFor, label, style, fontWeight }) => {

	return (
		<label
			htmlFor={htmlFor}
			className={` ${style == "md" ? "text-base" : "text-sm"} ${fontWeight === "bold" ? "font-bold" : "font-semibold"}  leading-6 inline-block`}>{label}</label>
	)
}

export { Label }