import { CubeTransparentIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Badge, BadgeType } from "../../_components/Badge";

const LocalizationPage: FC = () => {
	return (
		<div className="p-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Localization is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming in 2025 🚀" />
				</div>
				<p className="text-gray-600">
					Remote localization management is a technique that allows mobile apps to dynamically update and adjust localized content—such as text strings,
					labels, and messages—without requiring users to download an app update. <br />
					By leveraging a remote configuration service, app administrators can adjust and manage translations for different languages and regions in real time, 
					ensuring the app’s content is always accurate and relevant for its global audience.
				</p>
			</div>

		</div>
	)
}

export { LocalizationPage }