import { action, makeObservable, observable, runInAction } from "mobx"
import { User } from "../../../data/model/User"
import { UserAPI } from "../../../data/api/UserAPI"

class ProfilePageViewModel {

	user: User | undefined

	securityCode: string[] = []

	iconFile?: File

	isLoading: boolean = false

	constructor(user: User | undefined) {
		console.log("Create profile viewModel ")
		makeObservable(this, {
			isLoading: observable,
			setFirstname: action,
			setLastname: action,
			setUsername: action,
			securityCode: observable
		})

		this.user = user
	}

	async updateProfile(): Promise<User | undefined> {
		return new Promise(async (resolve) => {
			if (this.user) {

				runInAction(() => {
					this.isLoading = true
				})
			
				const api = new UserAPI()

				var updateUser: Partial<User> = {}

				if (this.user.firstname != undefined) {
					updateUser.firstname = this.user.firstname
				}
				if (this.user.lastname != undefined) {
					updateUser.lastname = this.user.lastname
				}
				if (this.user.username != undefined) {
					updateUser.username = this.user.username
				}
				if (this.securityCode.length == 6) {
					updateUser.securityCode = this.securityCode.join('')
				}

				const updatedUser = await api.updateProfile("me",
					updateUser,
					this.iconFile)

				setTimeout(() => {
					runInAction(() => {
						this.isLoading = false
					})
					resolve(updatedUser)
				}, 500)

			}
		})
	}

	setFirstname(firstname: string) {
		if (this.user) {
			this.user.firstname = firstname
		}
	}

	setLastname(lastname: string) {
		if (this.user) {
			this.user.lastname = lastname
		}
	}

	setUsername(username: string) {
		if (this.user) {
			this.user.username = username
		}
	}
}

export { ProfilePageViewModel }