import { User } from "../../../../../data/model/User"

class UserSelection {
	user: User
	selected: boolean = false

	constructor(user: User, selected: boolean) {
		this.user = user 
		this.selected = selected
	}
}

export { UserSelection }