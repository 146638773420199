import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AppCard } from "./_components/appcard/AppCard";
import { CompanyAPI } from "../../../../data/api/CompanyAPI";
import { useAuth } from "../../../../hooks/useAuth";
import { App } from "../../../../data/model/App";
import Lottie from 'react-lottie'
import emptyBuildAnimation from '../../../../assets/animations/empty-build.json'
import { Button } from "../../../_components/buttons/Button";
import { ArrowDownOnSquareIcon, PlusIcon, SquaresPlusIcon } from "@heroicons/react/24/outline";
import { DownloadMobileStorePage } from "./_components/DownloadsMobileStoreModal";
import { CreateAppModal } from "./_components/CreateAppModal";
import { Logo } from "../../../_components/logo/logo";
import { observer } from "mobx-react-lite";
import { StorePageViewModel } from "./StorePageViewModel";
import { Loader } from "../../../_components/Loader";

/**
 * This page is the entry point of the store
 * It displays all apps and companies a user can access
 */
const StorePage: FC = observer(() => {

	const { workspace } = useAuth()
	const viewModel = useMemo(() => new StorePageViewModel(workspace), [workspace]);

	const [downloadPageVisible, setDownloadPageVisible] = useState<boolean>(false)
	const [createAppModalVisible, setCreateAppModalVisible] = useState<boolean>(false)

	useEffect(() => {
		if (!createAppModalVisible)
			viewModel.load()
	}, [workspace, createAppModalVisible])

	return (
		<>
			{
				viewModel.isLoading
					? (
						<div className="flex flex-col justify-center items-center h-full">
							<div className="flex flex-row items-center gap-3">
								<Loader size="huge" />
								<p className="text-4xl font-extrabold">Loading your workspace</p>
							</div>
						</div>
					)
					: (
						<div className="w-full h-full">
							<div className="flex items-center">
								{
									viewModel.groupedApps ? (
										<Button size="sm" text={"Create app"} onClick={() => setCreateAppModalVisible(true)} customClass="ml-auto mb-3 mt-5 me-5 py-3">
											<span className="flex flex-row items-center px-2">
												<PlusIcon className="size-4" />
												Create new app
											</span>
										</Button>
									)
										: null
								}

							</div>
							{
								viewModel.groupedApps
									? (
										<div className="ps-8 grid grid-cols-4 gap-6 pb-16">
											<div className="col-1 col-span-3">
												{
													viewModel.groupedApps
														? Object.entries(viewModel.groupedApps).map(([group, apps]) => {
															return (
																<div key={group}>
																	<h1 className="inline align-middle ms-3 text-xl font-bold">{group}</h1>

																	{/* <CompanyHeader company={company} /> */}
																	<div className="grid grid-cols-1 pt-5 pb-14 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 cursor-pointer">
																		{
																			apps?.map((app) => {
																				return (
																					<AppCard app={app} key={app.id} />
																				)
																			})
																		}
																	</div>
																</div>
															)
														})
														: (null)
												}
											</div>
										</div>
									)
									: (
										<div className="flex flex-col justify-center items-center h-full">
											<div className="p-7 bg-gradient-to-b from-slate-100 to-transparent rounded-full">
												<SquaresPlusIcon className="size-20" />
											</div>
											<div className="flex flex-col justify-center items-center mt-6">
												<p className="font-extrabold text-5xl mb-3">Create your first app 🚀</p>
												<p className="text-gray-500 text-center max-w-[600px] mb-5">Your workspace is empty! Get started by creating your first application and ake control of your mobile applications with powerful management tools.</p>
												<Button type="dark" size="lg" onClick={() => setCreateAppModalVisible(true)}>Create my first app</Button>
											</div>
										</div>
									)
							}

							<div className="absolute w-[calc(100vw-256px)] p-5  bottom-0 flex">
								<div className="ml-auto">
									<Button type="secondary" text={"Download mobile store"} onClick={() => setDownloadPageVisible(true)} customClass=" b-3">
										<span className="flex flex-row items-center gap-2 ">
											<ArrowDownOnSquareIcon className="size-4" />
											Download mobile store
										</span>
									</Button>
								</div>
							</div>
							{/* -------------------------------------------------------------- */}
							{/* Modal & Alerts */}
							{/* -------------------------------------------------------------- */}
							<DownloadMobileStorePage visible={downloadPageVisible} onClose={() => setDownloadPageVisible(false)} />
							<CreateAppModal visible={createAppModalVisible} onClose={() => setCreateAppModalVisible(false)} />
						</div>
					)
			}
		</>
	);
})

export { StorePage }