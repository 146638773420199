import { useCallback } from "react";
import { UserAPI } from "../../../../data/api/UserAPI";
import { App } from "../../../../data/model/App";
import { Company } from "../../../../data/model/Company";
import { User } from "../../../../data/model/User";
import { UserGroup } from "../../../../data/model/UserGroup";
import { makeObservable, observable, computed, action, flow, makeAutoObservable, runInAction } from "mobx"
import { CompanyAPI } from "../../../../data/api/CompanyAPI";
import { useAuth } from "../../../../hooks/useAuth";
import React, { useId } from 'react'

class MembersViewModel {
	
	users: UserExtended[] = []
	public workspace?: Company

	constructor(workspace?: Company) {
		makeAutoObservable(this);
		this.workspace = workspace
	}

	public load() {
		this.getMembers()
	}

	// Private (but can't due to mobx limitations)
	getMembers = async () => {
		const userAPI = new CompanyAPI()
		if (this.workspace != null) {
			const response = await userAPI.getUsersForCompany(this.workspace.id)
			const userWithApps: UserExtended[] = await Promise.all(
				response.flatMap(async (user) => {
					const apps = await this.fetchAppsForUser(user.id!)
					const teams = await this.fetchTeamsForUser(user.id!)
					return new UserExtended(user, apps, teams)	
				})
			)
			runInAction(() => {
				this.users = userWithApps
			})
		}
	}

	/**
	 * Fetch teams for the given user
	 */
	private fetchTeamsForUser = async (userId: string) => {
		const api = new UserAPI()
		if (this.workspace != null) {
			const response = await api.getGroupsForUser(userId)
			return response	
		}
	}

	/**
	 * Fetch app for the given user
	 */
	private fetchAppsForUser = async (userId: string) => {
		const api = new UserAPI()
		if (this.workspace != null) {
			const response = await api.getAppForUser(userId, this.workspace.id)
			return response	
		}
	}
}

class UserExtended {
	user: User
	apps?: App[]
	teams?: UserGroup[]
	constructor(user: User, apps: App[]|undefined, teams: UserGroup[]|undefined) {
		
		this.user = user
		this.apps = apps
		this.teams = teams
	}
}


export { MembersViewModel, UserExtended }