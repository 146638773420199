import { FC, useEffect, useState } from "react"
import { Label } from "../../../../../_components/Label"
import { TextInput } from "../../../../../_components/TextInput"
import { useAuth } from "../../../../../../hooks/useAuth"
import { InviteUserViewModel } from "../InviteUserViewModel"
import { observer } from "mobx-react-lite"

type Props = {
	index?: number
	viewModel: InviteUserViewModel
}

const InviteUser: FC<Props> = observer( ({ index, viewModel } ) => {
	
	const { workspace } = useAuth()
	const mailChange = (input: string) => {
		viewModel.setEmail(input)
	}

	return (
		<div className="w-full h-64">
			<p className="mb-6">You're inviting members to the <strong>{workspace?.name}</strong> workspace.</p>
			<Label htmlFor={""} label={"User email"}/>
			<TextInput tabIndex={1} name="email" placeholder="john@mail.com" customClass="mt-1" onChange={(e) => mailChange(e.target.value) }/>
				{ viewModel.emailError != null && (
        			<p className="text-xs mt-1 text-red-500">{ viewModel.emailError }</p>
        		)}
			<p className="text-xs text-gray-500 mt-3">New member will receive invitation via email, and need to accepte it to join the workspace</p>
		</div>
	)
})

export { InviteUser }