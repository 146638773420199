import { FC } from "react";
import { Logo } from "../_components/logo/logo";

const NotInvitedPage: FC = () => {

	return (
		<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
			<div className="mx-auto w-[600px]">
				<Logo customClass="text-center" size="2xl"/>
				<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mb-10">
					Nice try but we are not production ready yet. 
				</h2>
				<p className="text-center text-lg font-semibold text-gray-700 mb-5">Want to be notified when Deliver will be realeased or want to be a part of the private beta testing ? </p>
				<iframe src="http://eepurl.com/i1p1Bc" className="w-full h-[400px]" />
	
			</div>
			
		</div>
	);
}

export { NotInvitedPage }