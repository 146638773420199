import { AxiosResponse } from 'axios';
import { App } from '../model/App';
import { AuthMethod, Company } from '../model/Company';
import { axiosInstance } from '../utils/RequestUtils';
import { UserGroup } from '../model/UserGroup';
import { User } from '../model/User';

const BASE = `/company`

export class CompanyAPI {

	async getCompanyById(companyId: string): Promise<Company> {
		const response = await axiosInstance.get(`${BASE}/${companyId}`)
		return response.data
	}

	async getAppsForCompany(companyId: string): Promise<App[]> {
		const response = await axiosInstance.get(`${BASE}/${companyId}/apps`)
		return response.data
	}

	async getAppsForUserInCompany(companyId: string): Promise<App[]> {
		const response = await axiosInstance.get(`${BASE}/${companyId}/appsForUser`)
		return response.data
	}

	async getUsergroupsForCompany(companyId: string): Promise<UserGroup[]> {
		const response = await axiosInstance.get(`${BASE}/${companyId}/usergroups`)
		return response.data
	}

	async createCompany(name: string, iconFile: File | undefined, authMethods: AuthMethod[], issuerName?: string): Promise<Company> {
		let formData = new FormData()
		if (iconFile != undefined) {
			formData.append('file', iconFile!)
		}
		formData.append('name', name!)
		if (authMethods.length > 0) {
			authMethods.forEach((item, index) => {
				formData.append(`authMethods[${index}]`, item);
			});
		}
		if (issuerName != undefined) {
			formData.append('samlIssuer', issuerName!)
		}
		return axiosInstance
			.post(`${BASE}`, formData, {
				headers: {
					'Content-Type':
						'multipart/form-data; boundary=--------------------------995261027145138292227880',
				},
			})
			.then((d: AxiosResponse<App>) => {
				return d.data
			})
	}

	async updateCompany(companyId: string, name: string, iconFile: File | undefined, authMethods: AuthMethod[], issuerName?: string): Promise<Company> {
		let formData = new FormData()
		if (iconFile != undefined) {
			formData.append('file', iconFile!)
		}
		if (name != undefined) {
			formData.append('name', name)
		}
		if (authMethods.length > 0) {
			authMethods.forEach((item, index) => {
				formData.append(`authMethods[${index}]`, item);
			});
		}
		if (issuerName != undefined) {
			formData.append('samlIssuer', issuerName!)
		}
		return axiosInstance
			.patch(`${BASE}/${companyId}`, formData, {
				headers: {
					'Content-Type':
						'multipart/form-data; boundary=--------------------------995261027145138292227880',
				},
			})
			.then((d: AxiosResponse<App>) => {
				return d.data
			})
	}

	async createApp(app: Partial<App>): Promise<App> {
		let formData = new FormData()
		formData.append('file', app.iconFile!)
		formData.append('name', app.name!)
		formData.append('bundleId', app.bundleId!)
		formData.append('groupBy', app.groupBy || "")
		formData.append('jiraUrl', app.jiraUrl || "")
		formData.append('gitlabUrl', app.gitlabUrl || "")
		formData.append('platform', app.platform!)
		return axiosInstance
			.post(`${BASE}/app`, formData, {
				headers: {
					'Content-Type':
						'multipart/form-data; boundary=--------------------------995261027145138292227880',
				},
			})
			.then((d: AxiosResponse<App>) => {
				return d.data
			})
	}

	async getUsersForCompany(companyId: string): Promise<User[]> {
		const response = await axiosInstance.get(`${BASE}/${companyId}/users`)
		return response.data
	}

	async getAppForUser(companyId: string): Promise<App[]> {
		const response = await axiosInstance.get(`${BASE}/${companyId}/apps`)
		return response.data
	}

	async setWorkspace(companyId: string): Promise<any> {
		const response = await axiosInstance.patch(`${BASE}/${companyId}/workspace`)
		console.log(response.data)
	}
}
