import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection"

const HardcodedSecrets: React.FC<MobSFProps> = ({ report, expanded }) => {
    
    return (
        <SecuritySection title="Possible Hardcoded Secrets" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum." expanded={expanded}>
			<div>
                {
                    report.secrets ? (

                        report.secrets.map((value, index) => (
                            <p key={value} className="break-all">{value}</p>
                        ))
                    ): <p>No secret found</p>
                }
            </div>
		</SecuritySection>
    )
}

export { HardcodedSecrets }