import React, { FC, ReactElement, ReactNode, useState } from "react";
import NTabProps from "./Tab";


interface Props {
	customClass?: string
	children: ReactElement<NTabProps>[] | ReactElement<NTabProps>; // Children should be an array of NTab elements
	onTabChange?: (selectedTab: number) => void;
}


const Tabs: FC<Props> = ({ children, customClass, onTabChange }) => {

	const [selectedIndex, setSelectedIndex] = useState(0)
	const tabs = React.Children.toArray(children).filter(isNTabElement);


	function isNTabElement(child: ReactNode): child is ReactElement<NTabProps> {
		return React.isValidElement(child) && 'title' in child.props;
	}

	return (
		<div className={`${customClass}`} >
			<ul className={`inline-flex gap-4 pb-2 w-full text-lg`}>
				{tabs.map((tab, index) => (
					<li
						key={tab.props.title}
						onClick={() => {
							setSelectedIndex(index)
							if (onTabChange)
								onTabChange(index)
						}
						}
						className={index === selectedIndex ? 'font-bold' : ''}
						style={{ cursor: 'pointer' }}
					>
						{tab.props.title}
					</li>
				))}
			</ul>
			<div className="mt-3 flex-grow">
				{tabs[selectedIndex]?.props.children}
			</div>
		</div>
	)
}

Tabs.defaultProps = {
	customClass: ""
}

export { Tabs }