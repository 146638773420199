import { FC, useState } from "react";
import { ButtonGroup } from "../../../../_components/ButtonGroup";
import { Tooltip } from "../../../../_components/Tooltip";
import { PlateformLogo } from "../../../../_components/plateformLogo/plateformLogo";
import { AppType } from "../../../../../data/model/App";
import { QRCode } from "react-qrcode-logo";
import { Modal } from "../../../../_components/Modal";

type Props = {
	visible: boolean
	onClose: () => void
}

const DownloadMobileStorePage: FC<Props> = ({ visible, onClose }) => {
	const { REACT_APP_API_URL } = process.env
	const [url, setUrl] = useState<string>("mobile-store.ipa")

	return (
		<Modal title={'Witch plateform do you want to download ?'} message={""} visible={visible} onClose={onClose}>
			<div className="p-5 justify-items-center">
				<ButtonGroup index={0} customClass={"mb-5"} mode={"toggle"} onClick={(selection) => {
					if (selection.includes(0)) {
						setUrl(`itms-services://?action=download-manifest&url=${REACT_APP_API_URL}/app/mobile-store-ios`)
						
					} else {
						setUrl(`${REACT_APP_API_URL}/stores/mobile-store.apk`)
						
					}
				}}>
					<Tooltip content="See iOS mobile store QRCode">
						<PlateformLogo plateform={AppType.IOS} />
					</Tooltip>
					<Tooltip content="See Android mobile store QRCode">
						<PlateformLogo plateform={AppType.ANDROID} />
					</Tooltip>
				</ButtonGroup>

				<QRCode value={url}
					removeQrCodeBehindLogo={true}
					logoPadding={5}
					fgColor="rgb(100 100 100)"
					size={150}
					qrStyle='dots'
					eyeColor="rgb(255 150 250)"
					eyeRadius={5} />
			</div>
		</Modal>
	)
}

export { DownloadMobileStorePage }