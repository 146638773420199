import { FC, useEffect, useState } from "react";
import { User } from "../../../data/model/User";
import { HandThumbUpIcon, HandThumbDownIcon } from "@heroicons/react/24/outline";
import { Avatar } from "../avatar/Avatar";
import { Tooltip } from "../Tooltip";

type Props = {
	user: User
	customClass?: string,
	haveValidated?: boolean,
	haveRefused?: boolean
}

const Tester: FC<Props> = ({ user, customClass, haveValidated, haveRefused }) => {

	const [currentUser, setCurrentUser] = useState<User>(user)
	useEffect(() => {
		setCurrentUser(user)
	}, [])

	return (
		<div className="flex -space-x-2 -space-y-1 pt-2">
			<div>
				<Tooltip content={
					haveValidated ? "Validated by " + currentUser.firstname + " " + currentUser.lastname
						: haveRefused ? "Refused by " + currentUser.firstname + " " + currentUser.lastname
							: currentUser.firstname + " " + currentUser.lastname
				}>
					<span
						className="text-primary dark:text-primary-400">
						<Avatar user={currentUser} customClass={customClass} />
					</span>
				</Tooltip>
			</div>
			{
				haveValidated == true && <HandThumbUpIcon className="inline-block h-4 w-4 z-40 rounded border bg-green-200" />
			}
			{
				haveRefused == true && <HandThumbDownIcon className="inline-block h-4 w-4 z-40 rounded border bg-red-200" />
			}
		</div>
	)
}

export { Tester }