import { SAMLConfig } from '../model/SAMLConfig';
import { User } from '../model/User';
import { axiosInstance } from '../utils/RequestUtils';

const BASE = `/auth`

export class AuthAPI {

     /**
     * Get user from the saved session in cookie
     * @returns The connected user
     * @throws
     */
     async me(): Promise<User> {
          const response = await axiosInstance.get(`${BASE}/me`);
          return response.data
     }


     /**
      * Get the SAML config for issuer name
      * @returns The SAML config in the database
      * @throws
      */
     async getSamlConfig(samlIssuer: string): Promise<SAMLConfig> {
          const response = await axiosInstance.get(`${BASE}/samlConfig/${samlIssuer}`);
          return response.data
     }

     /**
      * Post a new SAML config to our database
      * @returns The SAML config created
      * @throws
      */
     async samlConfig(samlConfig: SAMLConfig): Promise<SAMLConfig> {
          const response = await axiosInstance.post(`${BASE}/samlConfig`, samlConfig);
          return response.data
     }
}
