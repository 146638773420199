import { BuildAPI } from "../../data/api/BuildAPI";
import { UserAPI } from "../../data/api/UserAPI";
import { Build } from "../../data/model/Build";
import { User } from "../../data/model/User";

export class BuildUseCases {
    /**
     * Assemble a full build with all added values
     * @returns The complete build model
     * @throws
     */
    async assembleBuild(build: Build): Promise<Build> {
        const buildAPI = new BuildAPI()
        const userAPI = new UserAPI()
        const testers = await buildAPI.getTestersForBuild(build.id)

        if (build.committerId != undefined) {
            const committer = await userAPI.getUserByID(build.committerId)
            build.committer = committer
        }

        build.testers = testers

        return build
    }
}