import { FC, useEffect, useState } from "react"
import { CompanyAPI } from "../../../../../../data/api/CompanyAPI"
import { useAuth } from "../../../../../../hooks/useAuth"
import { App, AppType } from "../../../../../../data/model/App"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { UserGroup, UserGroupType } from "../../../../../../data/model/UserGroup"
import { UserGroupsAPI } from "../../../../../../data/api/UserGroupsAPI"
import { AppAvatar, AppsAvatar } from "../../../../../_components/AppsAvatar"
import { Tooltip } from "../../../../../_components/Tooltip"
import { Tabs } from "../../../../../_components/tabs/Tabs"
import { Tab } from "../../../../../_components/tabs/Tab"
import { InviteUserViewModel } from "../InviteUserViewModel"
import { observer } from "mobx-react-lite"
import { TeamBadge } from "../../../_components/TeamBadge"
import { platform } from "os"


type Props = {
	index?: number
	viewModel: InviteUserViewModel
}

const SelectTeam: FC<Props> =observer(({ index, viewModel }) => {

	useEffect(() => {
	}, [viewModel])


	return (
		<div className="w-full">
			<p>Select project(s) <strong>benjamin.frolicher@gmail.com</strong> will have access</p>
			<div className="flex flex-row gap-2 items-center mt-6 mb-7">
				<input type="checkbox"
					defaultChecked
					checked={viewModel.workspaceIsSelected}
					className="size-4"
					onChange={(e) => viewModel.handleWorkspaceSelection(e.target.checked )}
				/>
				<div className="flex flex-row items-center gap-2">
					<p className="font-semibold">Full workspace acess</p>
					<Tooltip content={
						<p>A user can have access to the entire <strong>Workspace</strong>, which grants them the rights to view and manage <strong>all apps</strong>. <br />
							Alternatively, you can assign the user to a <strong>team</strong>, granting them that team's permissions, <br />
							or assign them to a <strong>specific project</strong> only.</p>}>
						<InformationCircleIcon className="size-6" />
					</Tooltip>
				</div>
			</div>

			{
				<Tabs customClass={`${!viewModel.workspaceIsSelected ? "" : "opacity-30 pointer-events-none"}`}>
					<Tab title="Teams" customClass=" flex flex-col">
						<div className="table-container max-h-52 flex-grow overflow-scroll">
							<table>
								<thead className="sticky top-0 bg-white rounded-md">
									<th></th>
									<th>Name</th>
									<th>Access to</th>
								</thead>
								<tbody className="">
									{
										/// List all teams but not default app team here. There are displays in a dedicated tab
										viewModel.teams?.filter( (t) => [UserGroupType.WORKSPACE].includes(t.teamWithApp.team.type) == false  ).map(team => (
											<tr>
												<td className="w-20">
													<input type="checkbox"  checked={team.selected} onChange={ (e) => viewModel.handleTeamSelection(team.teamWithApp.team.id!, e.target.checked) } ></input>
													</td>
												<td>
													<TeamBadge team={team.teamWithApp.team}/>
												</td>
												<td>
													<div className="flex flex-row items-center gap-2">
														<AppsAvatar size="sm" apps={team.teamWithApp.apps} />
													</div>
												</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</Tab>
					{/* <Tab title="Projects">
						<div className="table-container max-h-52 flex-grow overflow-scroll">
							<table>
								<thead className="sticky top-0 bg-white rounded-md">
									<th></th>
									<th>Name</th>
									<th></th>

								</thead>
								<tbody className="overflow-scroll">
									{
										viewModel.apps?.map(app => (
											<tr>
												<td className="w-20"><input type="checkbox" checked={app.selected} onChange={ (e) => viewModel.handleAppSelection(app.app.id!, e.target.checked) } ></input></td>
												<td>
													<div className="flex flex-row gap-2 items-center font-semibold">
														<AppAvatar app={app.app} size="sm" />
														{app.app.name}
													</div>
												</td>
												<td>{ app.app.platform == AppType.IOS ? "iOS" : "Android" }</td>
											</tr>
										))
									}
								</tbody>
							</table>
						</div>
					</Tab> */}
				</Tabs>
			}

		</div>
	)
})
export { SelectTeam }