import { FC } from "react";
import { TrashIcon } from '@heroicons/react/24/solid'
import { useParams } from "react-router-dom";
import { Utils } from "../../_utils/Utils";
import Moment from "react-moment";
import { Crash } from "../../../data/model/Crash";
import { AppType } from "../../../data/model/App";
import { ReactComponent as AppleLogo } from '../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../assets/images/android-logo.svg'
import { CrashesUseCases } from "../../../domain/usecases/CrashesUseCases";
import { Tooltip } from "../../_components/Tooltip";

type Props = {
	crashes: Crash[],
	onSelect: React.Dispatch<React.SetStateAction<Crash | undefined>>,
	onDelete: React.Dispatch<React.SetStateAction<Crash | undefined>>
}

const CrashesList: FC<Props> = ({ crashes, onSelect, onDelete }) => {

	const { buildId } = useParams()

	const actions = new Map()
	actions.set("delete", onDelete)

	const filteredCrashes = new CrashesUseCases().getCrashesFilteredByOccurrence(crashes)

	return (
		<div className="table-container">
			<table>
				<thead>
					<tr>
						<th>Exception</th>
						<th>Stacktrace</th>
						<th className="w-16"><Tooltip content="Occurences" children={<span>Occ.</span>}></Tooltip></th>
						<th className="w-32">Created</th>
						<th className="w-32">Device</th>
						{
							buildId == undefined && (
								<>
									<th className="w-36">App</th>
									<th className="w-24">Build version</th>
								</>
							)
						}
						<th className="w-20">Actions</th>
					</tr>
				</thead>
				<tbody>
					{filteredCrashes.map((crash) => {
						return <CrashRow crash={crash} key={crash.id} onSelect={onSelect} actions={actions} />
					})}
				</tbody>
			</table>
		</div>
	)
}


type RowProps = {
	crash: Crash,
	onSelect: React.Dispatch<React.SetStateAction<Crash | undefined>>,
	actions: Map<string, React.Dispatch<React.SetStateAction<Crash | undefined>>>
}
const CrashRow: FC<RowProps> = ({ crash, onSelect, actions }) => {
	const { buildId } = useParams()

	return (
		<tr className="cursor-pointer" onClick={() => { onSelect(crash) }}>
			<td className="break-words">
				{crash?.file}::{crash?.function}::{crash?.line} <br />
				{Utils.breakLongWords(crash.exception!)}
			</td>
			<td className="break-words">
				{
					crash.stacktrace.map((line, index) => {
						return <div className={`${index % 2 ? "bg-slate-200" : "bg-slate-100"} p-1 font-normal`}><span >{line} <br /></span></div>
					})
				}
			</td>
			<td className="break-words">{crash?.occurences}</td>
			<td className="break-words">
				<Moment className="text-sm" format="YYYY/MM/DD hh:mm:ss">{crash?.createdAt}</Moment>
			</td>
			<td className="w-32">{crash?.device?.name}</td>
			{
				buildId == undefined && (
					<>
						<td>
							<div className="flex items-center py-1">
								<img className="size-7 rounded-md p-1" src={`/api/img/${crash.app.icon}`} alt=""></img>
								<h3 className="inline items-center me-1">{crash.app.name}</h3>
								{crash.app.platform === AppType.IOS ? <AppleLogo className="inline size-4 items-center" /> : <AndroidLogo className="inline size-4 items-center" />}
							</div>
						</td>
						<td>{crash.build.version}</td>
					</>
				)
			}
			<td>
				<div className="pr-2 inline">
					<button className="bg-rose-500 p-2 rounded" onClick={(event) => { event.stopPropagation(); actions.get("delete")!(crash) }}>
						<TrashIcon className="w-4 h-4 fill-white" />
					</button>
				</div>
			</td>
		</tr>
	)
}


export { CrashesList }