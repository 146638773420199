import { FC, useCallback, useEffect, useState } from "react";
import { Crash } from "../../../data/model/Crash";
import { AppAPI } from "../../../data/api/AppAPI";
import { BuildAPI } from "../../../data/api/BuildAPI";
import { CompanyAPI } from "../../../data/api/CompanyAPI";
import { UserAPI } from "../../../data/api/UserAPI";
import { CrashesList } from "../../_partials/crashes/CrashesList";
import { CrashesFilter } from "./CrashesFilter";
import { CrashesHeader } from "./CrashesHeader";
import { DeviceAPI } from "../../../data/api/DeviceAPI";
import emptyIssuesAnimation from '../../../assets/animations/empty-issues.json'
import issuesNotFoundAnimation from '../../../assets/animations/issues-not-found.json'
import Lottie from "react-lottie";
import { CrashDetails } from "../../_partials/crashes/CrashDetails";
import { Alert } from "../../_components/Alert";
import { CubeTransparentIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { Badge, BadgeType } from "../../_components/Badge";

const CrashesPage: FC = () => {
	// const [crashes, setCrashes] = useState<Crash[]>([])
	// const [filteredCrashes, setFilteredCrashes] = useState<Crash[]>([])
	// const [selectedCrash, setSelectedCrash] = useState<Crash | undefined>(undefined)
	// const [crashToDelete, setCrashToDelete] = useState<Crash | undefined>(undefined)

	// const emptyCrashesAnimationOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: emptyIssuesAnimation,
	// 	rendererSettings: {
	// 		preserveAspectRatio: "xMidYMid slice"
	// 	}
	// }
	// const crashesNotFoundAnimationOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: issuesNotFoundAnimation,
	// 	rendererSettings: {
	// 		preserveAspectRatio: "xMidYMid slice"
	// 	}
	// }

	// const fetchCrashes = useCallback((async () => {
	// 	const userAPI = new UserAPI()
	// 	const companyAPI = new CompanyAPI()
	// 	const appAPI = new AppAPI()
	// 	const buildAPI = new BuildAPI()
	// 	const deviceAPI = new DeviceAPI()

	// 	const companies = await userAPI.getCompaniesForUser("me")

	// 	console.debug(`[CrashesPage] fetch companies for user : ${companies}`)

	// 	var allCrashes: Crash[] = []

	// 	await Promise.all(
	// 		companies.map(async company => {
	// 			const apps = await companyAPI.getAppsForCompany(company.id)

	// 			console.debug(`[CrashesPage] fetch apps for company ${company.name} : ${apps.length} apps`)

	// 			await Promise.all(
	// 				apps.map(async app => {
	// 					const builds = await appAPI.builds(app.id)
	// 					console.debug(`[CrashesPage] fetch builds for app ${app.name} : ${builds.length} builds`)

	// 					await Promise.all(
	// 						builds.map(async build => {
	// 							const crashes = await buildAPI.getCrashesForBuild(build.id)

	// 							await Promise.all(
	// 								crashes.map(async crash => {
	// 									const device = await deviceAPI.findOne(crash.deviceId)

	// 									crash.device = device
	// 									crash.company = company
	// 									crash.app = app
	// 									crash.build = build
	// 								})
	// 							)

	// 							allCrashes = allCrashes.concat(crashes)
	// 						})
	// 					)
	// 					app.builds = builds
	// 				}))

	// 			company.apps = apps
	// 		})
	// 	)

	// 	console.debug(`[CrashesPage] all Crashes : ${(await allCrashes).length} Crashes`)

	// 	setCrashes(allCrashes)
	// 	setFilteredCrashes(allCrashes)
	// }), [])

	// useEffect(() => {
	// 	fetchCrashes()
	// }, [fetchCrashes])


	return (

		<div className="p-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Crashes is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming soon 🚀" />
				</div>
				<p className="text-gray-600">
					
				</p>
				<div className="flex flex-row items-center gap-1 pt-6">
					<InformationCircleIcon className="size-5 " />
					<p className="font-bold">
						Please use crashes in a build detail from the store menu item
					</p>
				</div>
			</div>
		</div>

		// <>
		// 	{
		// 		crashes.length > 0 ? (
		// 			<div className="h-full overflow-y-scroll">
		// 				<div className={`px-6`}>
		// 					<div className="px-6 pt-12">
		// 						<CrashesHeader crashes={crashes} />
		// 						<CrashesFilter setFilteredCrashes={setFilteredCrashes} crashes={crashes} />
		// 						{
		// 							filteredCrashes.length > 0 ? (
		// 								<CrashesList crashes={filteredCrashes} onSelect={setSelectedCrash} onDelete={setCrashToDelete} />
		// 							) : (
		// 								<div className="items-center text-center">
		// 									<Lottie options={crashesNotFoundAnimationOptions} height={150} width={200} />
		// 									<div className="text-2xl font-bold mt-5">No crashes match your filters</div>
		// 								</div>

		// 							)
		// 						}

		// 					</div>
		// 				</div>

		// 				{ /** Crash details */}
		// 				{
		// 					selectedCrash != undefined && (
		// 						<div className="fixed z-50 m-auto fixed inset-0 h-fit w-fit max-w-[60%] max-h-[80%] flex justify-center item-center">
		// 							<CrashDetails crash={selectedCrash} close={function (): void {
		// 								setSelectedCrash(undefined)
		// 							}} />
		// 						</div>
		// 					)
		// 				}
		// 				{ /** Crash to delete modal */}
		// 				{
		// 					<Alert 
		// 						title={"Are-you sure ?"} 
		// 						message={""} 
		// 						type="destructive"
		// 						visible={ crashToDelete != undefined } 
		// 						primaryButtonTitle={"Yes"} 
		// 						primaryCallback={ () => { setCrashToDelete(undefined) } }
		// 						secondaryButtonTitle="Cancel"
		// 						secondaryCallback={ () => { setCrashToDelete(undefined) } } />
		// 				}

		// 			</div>


		// 		) : (
		// 			<div className="mt-16 items-center text-center">
		// 				<Lottie options={emptyCrashesAnimationOptions} height={150} width={200} />
		// 				<div className="text-2xl font-bold mt-5">No crashes found</div>
		// 			</div>
		// 		)
		// 	}
		// </>
	);
}

export { CrashesPage }