import { Permission } from "../../../../../../data/model/MobSF";
import { Badge, BadgeType } from "../../../../../_components/Badge";
import { Tooltip } from "../../../../../_components/Tooltip";
import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection";
import { BugAntIcon } from "@heroicons/react/24/outline"

const Permissions: React.FC<MobSFProps> = ({ report, expanded }) => {

	return (
		<SecuritySection title="Permissions" subtitle="Control an app’s access to protected system services and user data." expanded={expanded}>
			<div className="table-container">
			<table>
				<thead>
					<tr>
						<th className="w-28">Severity</th>
						<th className="w-90">Name</th>
						<th className="hidden xl:table-cell">Info</th>
						<th className="hidden 2xl:table-cell">Reason</th>
						<th className="w-28">Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						report?.permissions ? (
							(() => {
								const rows: React.ReactElement[] = [];
								for (const [key, value] of Object.entries(report.permissions)) {
									rows.push(<PermissonCard key={key} name={key} permission={((value as unknown) as Permission)} />)
								}

								return rows
							})()
						) : <></>
					}
				</tbody>
			</table>
			</div>
		</SecuritySection>
	)
}

type PermissionProps = {
	name: string
	permission: Permission
}

const PermissonCard: React.FC<PermissionProps> = ({ name, permission }) => {

	return (
		<tr>
			<td>
				<Badge type={permission.status as BadgeType} text={permission.status} />
			</td>
			<td className="font-semibold break-words">{name}</td>
			<td className="hidden xl:table-cell">{permission.info}</td>
			<td className="hidden 2xl:table-cell">{permission.description}</td>
			<td className="text-right">
				<Tooltip content={<span>Declare an issue</span>}>
					<button className="create-issue border-1 rounded-md p-3 hover:bg-gray-50">
						<BugAntIcon className="size-4" />
					</button>
				</Tooltip>
			</td>
		</tr>
	)
}

export { Permissions }