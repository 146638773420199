import { AtsFinding } from "../../../../../../data/model/MobSF"
import { Badge, BadgeType } from "../../../../../_components/Badge"
import { Tooltip } from "../../../../../_components/Tooltip"
import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection"
import { BugAntIcon } from '@heroicons/react/24/outline'

const ATS: React.FC<MobSFProps> = ({ report, expanded }) => {

    return (
        <SecuritySection title="App Transport Security (ATS)" subtitle="A description of changes made to the default security for HTTP connections." expanded={expanded}>
			<div className=''>
                <div className='inline-flex mb-8 space-x-10'>
                    <div className='text-center'>
                        <Badge type={BadgeType.ERROR} customClass="px-4 py-2 mb-2" text={report.ats_analysis.ats_summary.info ?? 0 }/>
                        <div className='font-medium'>High</div>
                    </div>
                    <div className='text-center'>
                        <Badge type={BadgeType.WARNING} customClass="px-4 py-2 mb-2" text={report.ats_analysis.ats_summary.info ?? 0 } />
                        <div className='font-medium'>Warning</div>
                    </div>
                    <div className='text-center'>
                        <Badge type={BadgeType.INFOLIGHT} customClass="px-4 py-2 mb-2" text={report.ats_analysis.ats_summary.info ?? 0 } />
                        <div className='font-medium'>Info</div>
                    </div>
                    <div className='text-center'>
                        <Badge type={BadgeType.PURPLE} customClass="px-4 py-2 mb-2" text={report.ats_analysis.ats_summary.secure ?? 0 } />
                        <div className='font-medium'>Secure</div>
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                <thead >
                    <tr>
                        <th>Issue</th>
                        <th>Severity</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {
                    report.ats_analysis.ats_findings ? (
                        (() => {
                            for (const [key, value] of Object.entries(report.ats_analysis.ats_findings)) {
                                return <ATSCard ats={ ((value as unknown) as AtsFinding) } />
                            }
                        })()
                    ): <></>
                }
                </tbody>
                </table>
            </div>
		</SecuritySection>
    )
}

type ATSProps = {
    ats: AtsFinding
}

const ATSCard: React.FC<ATSProps> = ({ ats }) => {

    return (   
        <tr>
            <td><span className="font-semibold">{ ats.issue }</span></td>
            <td>
                <Badge type={BadgeType.ERROR} text={ats.severity} />
            </td>
            <td>{ats.description}</td>
            <td className="text-right">
				<Tooltip content={<span>Declare an issue</span>}>
					<button className="create-issue border-1 rounded-md p-3 hover:bg-gray-50">
						<BugAntIcon className="size-4" />
					</button>
				</Tooltip>
			</td>
        </tr>
    )
}

export { ATS }