import { useState } from "react"
import RequestHeader from "./request/RequestHeader"
import RequestQuery from "./request/RequestQuery"
import RequestBody from "./request/RequestBody"
import { ReqProps } from "./ReqProp"

const RequestRequest: React.FC<ReqProps> = ({ req }) => {

	const [tab, setTab] = useState('Header')

	return (
		<div className="h-full flex flex-col">
			<ul className="inline-flex space-x-4 py-4">
				<li><a className="font-bold">Request</a></li>
				<li><a className={`cursor-pointer underline-offset-8 ${tab === 'Header' ? 'font-semibold underline' : ''}`} onClick={() => setTab('Header')}>Headers</a></li>
				<li><a className={`cursor-pointer underline-offset-8 ${tab === 'Query' ? 'font-semibold underline' : ''}`} onClick={() => setTab('Query')}>Query</a></li>
				<li><a className={`cursor-pointer underline-offset-8 ${tab === 'Body' ? 'font-semibold underline' : ''}`} onClick={() => setTab('Body')}>Body</a></li>
			</ul>
			<div className=" flex flex-1 overflow-scroll">
				
				{
					{
						'Header': <RequestHeader req={req} />,
						'Query': <RequestQuery req={req} />,
						'Body': <RequestBody req={req} />
					}[tab]
				}
				
			</div>
		</div>
	)
}
export { RequestRequest }