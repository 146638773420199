import { AndroidMobSF, CodeAnalysisFindingsMetadata } from "../../../../../../../data/model/MobSF"
import { Badge, BadgeType } from "../../../../../../_components/Badge"
import { MobSFProps } from "../../MobSFProps"
import { SecuritySection } from "../../_components/SecuritySection"

export const CodeAnalysisCard: React.FC<MobSFProps> = ({ report, expanded }) => {

    const androidReport = report as AndroidMobSF

    return (
        <SecuritySection title="Code Analysis" subtitle="Binary code analysis searches your application's compiled code for flaws that could potentially be exploited by an attacker." expanded={expanded}>
            <div className='inline-flex mb-8 space-x-10'>
                <div className='text-center'>
                    <Badge type={BadgeType.ERROR} customClass="px-4 py-2 mb-2" text={androidReport.code_analysis.summary.high ?? 0} />
                    <div className='font-medium'>High</div>
                </div>
                <div className='text-center'>
                    <Badge type={BadgeType.WARNING} customClass="px-4 py-2 mb-2" text={androidReport.code_analysis.summary.warning ?? 0} />
                    <div className='font-medium'>Warning</div>
                </div>
                <div className='text-center'>
                    <Badge type={BadgeType.INFO} customClass="px-4 py-2 mb-2" text={androidReport.code_analysis.summary.info ?? 0} />
                    <div className='font-medium'>Info</div>
                </div>
                <div className='text-center'>
                    <Badge type={BadgeType.SUCCESS} customClass="px-4 py-2 mb-2" text={androidReport.code_analysis.summary.secure ?? 0} />
                    <div className='font-medium'>Secure</div>
                </div>
                <div className='text-center'>
                    <Badge type={BadgeType.PURPLE} customClass="px-4 py-2 mb-2" text={androidReport.code_analysis.summary.suppressed ?? 0} />
                    <div className='font-medium'>Suppressed</div>
                </div>
            </div>
            
                <div className="table-container">
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead >
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th className="pb-2 pr-4">Issue</th>
                                <th className="pb-2 pr-4">Severity</th>
                                <th className="pb-2 pr-4">Standards</th>
                                <th className="pb-2 pr-4">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                androidReport.code_analysis.findings ? (
                                    (() => {
                                        const findings = Object.entries(androidReport.code_analysis.findings)
                                        const rows: React.ReactElement[] = [];
                                        for (const [key, value] of findings) {
                                            const metadata = ((value.metadata as unknown) as CodeAnalysisFindingsMetadata)
                                            rows.push(<CodeAnalyseRow key={key} name={key} metadata={metadata} />)
                                        }

                                        return rows
                                    })()
                                ) : <></>
                            }
                        </tbody>
                    </table>
                </div>
            
        </SecuritySection>
    )
}

type PermissionProps = {
    name: string
    metadata: CodeAnalysisFindingsMetadata
}

const CodeAnalyseRow: React.FC<PermissionProps> = ({ name, metadata }) => {

    return (
        <tr>
            <td><span className="fw-bolder">{name}</span></td>
            <td><Badge type={metadata.severity as BadgeType} customClass="px-4 py-2 mb-2" text={metadata.severity} /></td>
            <td>
                <span className="fw-bolder">CWE: </span>{metadata.cwe}<br />
                <span className="fw-bolder">OWASP Top 10: </span>{metadata["owasp-mobile"]}<br />
                <span className="fw-bolder">OWASP MASVS: </span>{metadata.masvs}<br />
                <span className="fw-bolder">CVSS: </span>{metadata.cvss}<br />
            </td>
            <td>{metadata.description}</td>
        </tr>
    )
}