import { FC, useEffect, useState } from "react"
import { Button } from "../../../_components/buttons/Button"
import { EnvelopeIcon, UserPlusIcon } from "@heroicons/react/24/outline"
import { Avatar } from "../../../_components/avatar/Avatar"
import { AppsAvatar } from "../../../_components/AppsAvatar"
import { ButtonGroup } from "../../../_components/ButtonGroup"
import { PencilSquareIcon } from "@heroicons/react/24/outline"
import { TextInput } from "../../../_components/TextInput"
import { Tooltip } from "../../../_components/Tooltip"
import { observer } from 'mobx-react-lite';
import { MembersViewModel, UserExtended } from "./MembersViewModel"
import { TeamBadge } from "../_components/TeamBadge"
import { InviteUserModal } from "./components/InviteUserModal"
import { InviteUserViewModel } from "./components/InviteUserViewModel"

type Props = {
	viewModel: MembersViewModel
}

const Members: FC<Props> = observer(({ viewModel }: Props) => {

	const [inviteModalVisible, setInviteModalVisible] = useState(false)

	const onInviteFinish = () => {
		setInviteModalVisible(false)
	}

	let inviteUserViewModel = new InviteUserViewModel(viewModel.workspace, onInviteFinish)

	useEffect(() => {
		viewModel.load()
	}, [viewModel, inviteModalVisible]);

	return (
		<div className='px-6 h-full overflow-y-scroll'>
			<p className='py-4 font-extrabold text-lg'>Manage access to workspace and projects</p>
			<p className="text-gray-600 text-sm">Easily view, add, and manage all users with customizable teams and applications permissions.</p>
			<div className="flex justify-between items-center mt-6">
				<TextInput name="Search" placeholder="Search..." />
				<Button type="dark" text={"Invite"} onClick={() => setInviteModalVisible(true)} customClass="mb-3">
					<span className="flex flex-row items-center gap-2 ">
						<UserPlusIcon className="size-4" />
						Invite
					</span>
				</Button>
			</div>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th className=" w-16"></th>
							<th>Name</th>
							{/* <th>Email</th> */}
							<th>Teams</th>
							<th>Apps</th>
							{/* <th className="w-16">Actions</th> */}
						</tr>
					</thead>
					<tbody>
						{
							viewModel.users?.map(user => <MemberRow user={user} />)
						}
					</tbody>
				</table>
			</div>

			<InviteUserModal
				viewModel={inviteUserViewModel}
				visible={inviteModalVisible}
				onClose={() => setInviteModalVisible(false)} />
		</div>
	)
})

type MemberRowProps = {
	user: UserExtended
}
const MemberRow: FC<MemberRowProps> = ({ user }) => {
	return (
		<tr key={user.user.id}>
			<td>
				<Avatar user={user.user} />
			</td>
			<td className={`${user.user.status === "PENDING" ? "text-gray-500" : ""}`}>
				<div className="flex flex-col">
					<p className="font-bold">{user.user.firstname} {user.user.lastname}</p>
					<div className={`flex flex-row items-center gap-2`}>
						<p>{user.user.email}</p>
						{
							user.user.status === "PENDING" ? (
								<Tooltip content={<span>Pending invitation<br /> User must confirm</span>}>
									<EnvelopeIcon className="size-4" />
								</Tooltip>
							) : null
						}
					</div>
				</div>
			</td>
			<td className="">
				<div className="flex flex-row gap-2">
					{
						user.teams?.map(team => <TeamBadge team={team} mode="visible" />)
					}
				</div>
			</td>
			<td>
				<div className="flex flex-row items-center gap-2">
					<AppsAvatar apps={user.apps} size="sm" />
				</div>
			</td>
			{/* <td className="justify-items-end">
				<ButtonGroup mode="click">
					<Tooltip content="Delete member">
						<TrashIcon className="size-4" onClick={ console.log }/>
					</Tooltip> 
					<Tooltip content="Edit member">
						<PencilSquareIcon className="size-4" onClick={ console.log }/>
					</Tooltip>
				</ButtonGroup>
			</td> */}
		</tr>
	)
}

export { Members }