
export enum Status {
	PENDING = "PENDING",
	CONFIRMED = "CONFIRMED"
}

export class User {
	id: string = ""
	email: string = ""
	firstname?: string
	lastname?: string
	username?: string
	securityCode: string = ""
	jobTitle?: string
	status?: string
	role?: string
	userStatus?: Status
	avatar?: string
	avatarFile?: File
	createdAt?: string
	updatedAt?: string
	tags?: string[]

	constructor(init?: Partial<User>) {
		Object.assign(this, init);
	}
}