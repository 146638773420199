import { url } from "inspector";
import { FC, PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";


type Props = {
	to: string
	name: string,
	icon: React.ReactNode,
}

const SideMenuItem: FC<PropsWithChildren<Props>> = ({ to, name, icon }) => {

	const { pathname } = useLocation()
	const isActive = checkIsActive(pathname, to)
	return (
		<li>
			<Link to={to} className={`flex justify-between items-center p-1 pl-2 rounded-lg hover:border-gray-200 hover:border-1 group ${isActive ? "bg-black text-white" : "bg-white text-black"} `}>
				<div className="inline-flex items-center">{ icon }<span className={`ms-3 text-lg ${isActive ? "font-bold " : "font-semibold"}`}>{name}</span></div>
			</Link>
		</li>
	)
}

export { SideMenuItem }

function checkIsActive(pathname: string, url: string) {
	let current = ""
	if(url.startsWith("/")) {
		current = pathname.split(/[?#]/)[0]
	}
	else {
		const splitted = pathname.split(/[?/]/) 
		current = splitted[splitted.length - 1]
	}

	if (!current || !url) {
		return false
	}

	if (current === url) {
		return true
	}

	if (current.startsWith(url)) {
		return true
	}

	return false
}
