import { CubeTransparentIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Badge, BadgeType } from "../../_components/Badge";

const SecurityPage: FC = ()=> {
	return (
		<div className="p-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Security is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming soon 🚀" />
				</div>
				<p className="text-gray-600">
					Recover fastly all your security scans with ease by accessing the Security feature directly by main menu.
					During the beta, access the your security scans by navigating to your target build.
				</p>

				<div className="flex flex-row items-center gap-1 pt-6">
					<InformationCircleIcon className="size-5 " />
					<p className="font-bold">
						Please use security in a build detail from the store menu item
					</p>
				</div>
			</div>

		</div>
	)
}
	
export { SecurityPage }