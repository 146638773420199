import { FC, MouseEventHandler, PropsWithChildren } from "react"
import { Loader } from "../Loader"

type _BaseButtonProps = {
	customClass?: string
	size?: "sm" | "default" | "lg"
	enabled?: boolean,
	text?: string
	loading?: boolean
	tabIndex?: number
	loadingText?: string|null
	onClick: MouseEventHandler | undefined;
}

/**
 * Base button is an abstract classe that defines style and size for a button
 * Base button doesn't include inner value like child, text and image
 * You can use it to defines more complexe button, like Button, image button, loading button...
 */

const _BaseButton: FC<PropsWithChildren<_BaseButtonProps>> = ({
	customClass,
	size = "default",
	children,
	onClick,
	tabIndex
}) => {
	// sizing
	let className = "p-1 font-semibold";
	if (size === "sm") {
		className += ' p1 px-2 text-xs rounded h-6'
	}
	else if (size === "lg") {
		className += ' p-2 px-4 text-base rounded-md h-11 min-w-28'
	} 
	else {
		className += ' px-4 text-base rounded-md h-9'
	}

	return (
		<button tabIndex={tabIndex} className={`${className} ${customClass}`} onClick={onClick}>{children}</button>
	)
}


type ButtonProps = _BaseButtonProps & {
	type?: "primary" | "secondary" | "destructive" | "dark"
}

const Button: FC<PropsWithChildren<ButtonProps>> = (props) => {

	const style = () => {
		let style = "flex flex-row items-center justify-center "

		switch (props.size) {
			case "sm":
				style += ""; break;
			case "lg":
				style += ""; break;
			default: break;
		}

		switch (props.type) {
			case "primary":
				style += `${props.enabled ? "bg-blue-600 hover:bg-blue-700" : "bg-blue-300 hover:bg-blue-300 " }  text-white border-1 border-white`; break;
			case "secondary":
				style += ` ${props.enabled ? "bg-white border-gray-300 hover:bg-gray-200 hover:border-gray-300" : "bg-gray-100 "} border-1 `; break;
			case "destructive":
				style += `${props.enabled ? "hover:bg-red-900 bg-red-700 border-red-800" : "border-red-800 bg-red-700"}  text-white border-1 `; break;
			case "dark":
				style += `${props.enabled ? "hover:bg-black bg-gray-800" : "bg-gray-500"}  text-white `; break;
			default: break;
		}

		if (props.enabled === false) {
			style += " cursor-not-allowed"
		}

		return style + " " + props.customClass
	}

	return (
		/* eslint-disable react/jsx-pascal-case */
		<_BaseButton {...props} customClass={style()}>
			
			{props.loading ? (
				<Loader size={ props.size } customClass="inline-flex align-middle mr-2" />
			) : (<></>)
			}
			{ 
				props.loading
				? props.loadingText ? props.loadingText : props.children ? props.children : props.text
				: props.children ? props.children : props.text
			}

		</_BaseButton>
	)
}

Button.defaultProps = {
	size: "default",
	type: "dark",
	enabled: true,
	customClass: "",
	loading: false,
	loadingText:null
}


export { Button }
export type { _BaseButtonProps }
