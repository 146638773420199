
import { AppAPI } from "../../../../../data/api/AppAPI"
import { CompanyAPI } from "../../../../../data/api/CompanyAPI"
import { App, AppType } from "../../../../../data/model/App"

export enum FormMode {
	CREATE, EDIT
}

class AppFormViewModel {

	public mode: FormMode = FormMode.CREATE
	public name: string = ''
	public bundleId: string = ''
	public groupBy: string = ''
	public platform: AppType = AppType.IOS
	public iconFile?: File
	public icon?: string
	public appId?: string
	public gitlabUrl?: string
	public jiraUrl?: string
	public onFinish: () => void = () => { }

	constructor(app: App | undefined, onFinish: () => void) {

		if (app != undefined) {
			this.mode = FormMode.EDIT
			this.name = app.name || ''
			this.bundleId = app.bundleId || ''
			this.groupBy = app.groupBy || ''
			this.icon = `/api/img/${app.icon}`
			this.iconFile = app.iconFile
			this.appId = app.id
			this.gitlabUrl = app.gitlabUrl || ''
			this.jiraUrl = app.jiraUrl || ''
			this.platform = app.platform || AppType.IOS
		}
		
		this.onFinish = onFinish
	}

	onValidate = (): Promise<App | undefined> => {
		if (this.appId != undefined) {
			const api = new AppAPI()
			return api.updateApp({
				id: this.appId,
				name: this.name,
				bundleId: this.bundleId,
				iconFile: this.iconFile,
				platform: this.platform,
				groupBy: this.groupBy,
				gitlabUrl: this.gitlabUrl,
				jiraUrl: this.jiraUrl
			})
		} else {
			const api = new CompanyAPI()
			return api.createApp({
				name: this.name,
				bundleId: this.bundleId,
				iconFile: this.iconFile,
				platform: this.platform,
				groupBy: this.groupBy,
				gitlabUrl: this.gitlabUrl,
				jiraUrl: this.jiraUrl
			})
		}
	}
}

export default AppFormViewModel