import { BugAntIcon, ScaleIcon } from "@heroicons/react/24/outline"
import { ApexOptions } from "apexcharts"
import { FC, useState, useCallback, useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import { Crash } from "../../../../../data/model/Crash"
import { BuildAPI } from "../../../../../data/api/BuildAPI"
import { useParams } from "react-router-dom"

type Props = {
	crashes: Crash[]
}

const BuildCrashesHeader: FC<Props> = ({ crashes }) => {
	const { buildId } = useParams()
	const [cfu, setCfu] = useState<Number | undefined>(undefined)

	const fetchCrashFreeUser = useCallback((async () => {
		const buildAPI = new BuildAPI()
		const crashFreeUser = await buildAPI.getCrashFreeUser(buildId!)
		setCfu(crashFreeUser)
	}), [])

	useEffect(() => {
		fetchCrashFreeUser()
	}, [fetchCrashFreeUser])

	const options: ApexOptions = {

		chart: {
			type: 'bar',
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			show: false
		},
		colors: ["#22c55e", "#f97316", "#f43f5e"],
		xaxis: {
			categories: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec"
			],
		}
	};
	const series = [
		{
			name: "Normal",
			data: [50, 40, 0, 0, 0, 0, 0, 0, 0, 0, 0, 100]
		},
		{
			name: "Warning",
			data: [0, 0, 300, 320, 0, 350, 200, 230, 190, 0, 0, 0]
		},
		{
			name: "Critical",
			data: [0, 0, 0, 0, 500, 0, 0, 0, 0, 1000, 500, 0],
		}
	]

	return (

		<div className="flex flex-row">
			<div className="h-fit grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 w-1/2 cursor-pointer justify-between">
				{/*  Crashes occurences */}
				<div className=" bg-rose-100 text-rose-500 border-green-200 p-2 rounded-lg mb-10">
					<div className="flex flex-row space-x-2 items-center justify-center">
						<BugAntIcon className="w-6 h-6" />
						<h3 className="inline items-center font-bold text-3xl me-1 pt-1">{crashes.length}</h3>
					</div>
					<p className="text-sm font-medium text-center">Crashes occurences</p>
				</div>
				{/*  Crashes free user */}
				{cfu && (
					<div className=" bg-fuchsia-100 text-fuchsia-700 border-green-200 p-2 rounded-lg mb-10">
						<div className="flex flex-row space-x-2 items-center justify-center">
							<ScaleIcon className="w-6 h-6" />
							<h3 className="inline items-center font-bold text-3xl me-1 pt-1">{cfu.toString()}%</h3>
						</div>
						<p className="text-sm font-medium text-center">Crash free user</p>
					</div>
				)}

			</div>
			<div className="w-1/2">
				{/* TODO usefull ? Better UI ? */}
				<ReactApexChart
					type='bar'
					height="240"
					options={options}
					series={series} />
			</div>
		</div>
	)
}

export { BuildCrashesHeader }