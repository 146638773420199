import React, { useState } from 'react';

interface TokenFieldProps {
  onTokensChange: (tokens: string[]) => void;
}

interface Token {
  value: string;
  isValid: boolean;
}

const TokenField: React.FC<TokenFieldProps> = ({ onTokensChange }) => {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  // Regular expression to validate an email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const addToken = (token: string) => {
    const trimmedToken = token.trim();
    const isValid = emailRegex.test(trimmedToken);
    const newToken = { value: trimmedToken, isValid };

    const newTokens = [...tokens, newToken];
    setTokens(newTokens);
    onTokensChange(newTokens.filter(token => token.isValid).map(token => token.value));

    if (isValid) {
      setInputValue(''); // Clear input only if the token is valid
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addToken(inputValue);
    }
  };

  const removeToken = (index: number) => {
    const newTokens = tokens.filter((_, i) => i !== index);
    setTokens(newTokens);
    onTokensChange(newTokens.filter(token => token.isValid).map(token => token.value));
  };

  return (
    <div className="flex flex-wrap items-center border border-gray-300 p-1 rounded-md">
      {tokens.map((token, index) => (
        <div
          key={index}
          className={`flex items-center rounded px-2 py-1 m-1 text-sm font-semibold ${
            token.isValid ? 'bg-gray-100 border-1 border-gray-200' : 'bg-red-100 text-red-700 border border-red-500'
          }`}
        >
          {token.value}
          <button
            onClick={() => removeToken(index)}
            className="ml-1 hover:text-red-700 focus:outline-none"
          >
            &times;
          </button>
        </div>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter emails separated by comma"
        className="flex-grow outline-none border-none m-1 bg-transparent"
      />
    </div>
  );
};

export default TokenField;
