import { CubeTransparentIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Badge, BadgeType } from "../../_components/Badge";

const RemoteConfigPage: FC = () => {
	return (
		<div className="p-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Remote configuration is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming in 2025 🚀" />
				</div>
				<p className="text-gray-600">
					Remote configuration is a powerful technique that allows mobile applications to dynamically control and update specific features 
					without requiring users to download a new app version. <br />
					By using a remote configuration service, app administrators can manage feature availability, UI elements, and app behavior across user segments in real time, based on configurable rules or specific user attributes.
				</p>
			</div>

		</div>
	)
}

export { RemoteConfigPage }