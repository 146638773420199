import React, { useEffect, useState } from "react"
import { FC, PropsWithChildren } from "react"


type Props = {
	customClass?: string
	index?: number
	mode?: "click" | "select" | "multi" | "toggle"
	onClick?: (selection: number[]) => void
}

const ButtonGroup: FC<PropsWithChildren<Props>> = ({ children, customClass, index, onClick, mode }) => {

	const array = React.Children.toArray(children)
	const [selection, setSelection] = useState<number[]>([])

	const toggleSelection = (index: number) => {
		let sel = selection
		if (mode == "select" || "toggle") {

			if (sel?.includes(index) && mode == "select") {
				sel = []
			}
			else {
				sel = [index]
			}
		}
		else if (mode == "multi") {
			if (sel?.includes(index)) {
				sel = selection.filter(item => item !== index)
			}
			else {
				sel = [...sel, index]
			}
		}

		setSelection(sel)
		onClick?.(sel);
	}

	useEffect(() => {
		if (index !== undefined) {
			toggleSelection(index)
		}
	}, [index]);

	const handleClick = (index: number) => {
		if (["select", "multi", "toggle"].includes(mode!)) {
			toggleSelection(index);
		}
		else {
			onClick?.([index])
		}
	};

	return (
		<div className={`rounded-md overflow-clip w-fit border-gray-200 border-1 font-bold shadow-sm ${customClass}`}>
			{
				array.map((child, index) => {
					const isNotlast = index !== array.length - 1
					return (
						<button key={index}
							className={`${selection.includes(index) ? "bg-gray-200 border-gray-300" : "border-gray-100"} active:bg-gray-300 p-2 text-sm ${isNotlast ? "border-r-1" : ""}  hover:bg-gray-200`}
							onClick={(e) => {
								e.preventDefault()
								handleClick(index);
							}} >
							{child}
						</button>
					)
				})
			}
		</div>
	)
}

ButtonGroup.defaultProps = {
	customClass: "",
	mode: "select"
}

export { ButtonGroup }