import { FC, useState } from "react"
import RequestList from "../network/RequestList"
import { RequestRequest } from "../network/RequestRequest"
import { RequestResponse } from "../network/RequestResponse"
import { Request } from "../../../../../data/model/Request"

type Props = {
	sessionId: string | undefined
}

const NetworkContainer: FC<Props> = ({ sessionId }) => {

	const [selectedRequest, setSelectedRequest] = useState<Request | undefined>(undefined)

	const onSelectRequest = (req: Request) => {
		console.log(`onSelectRequest ${req.url}`)
		setSelectedRequest(req)
	}

	return (
		<div className="h-full flex flex-col">
			<div className="h-1/2 overflow-hidden flex flex-col">
				<RequestList sessionId={sessionId} onSelectRequest={onSelectRequest} />
			</div>
			<div className="h-1/2 flex flex-row">
				<div className="w-1/2 border-r-1 pe-2">
					<RequestRequest req={selectedRequest} />
				</div>
				<div className="w-1/2 pl-2 h-full">
					<RequestResponse req={selectedRequest} />
				</div>
			</div>
		</div>
	)
}
export { NetworkContainer }


//  <div id="main" className="flex-1 flex flex-col bg-orange-400">
//         {/* Section A */}
//         <div id="A" className="flex-1 overflow-y-auto bg-red-300">
//           <div className="h-[0px]">
//             <table className="min-w-full">
//               <thead className="sticky top-0">
//                 <tr>
//                   <th className="p-2">Header 1</th>
//                   <th className="p-2">Header 2</th>
//                   <th className="p-2">Header 3</th>
//                 </tr>
//               </thead>
//               <tbody className="block">
//                 {/* Loop through the rows directly within JSX */}
//                 { [...Array(100)].map((_, index) => (
//                   <tr key={index} className="flex">
//                     <td className="border border-gray-300 p-2 w-1/3">Row {index + 1}, Column 1</td>
//                     <td className="border border-gray-300 p-2 w-1/3">Row {index + 1}, Column 2</td>
//                     <td className="border border-gray-300 p-2 w-1/3">Row {index + 1}, Column 3</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>

//         {/* Section B */}
//         <div id="B" className="flex-1 overflow-y-auto bg-blue-300 p-4">
//          <div className="h-[0px]">
//             <table className="min-w-full">
//               <thead>
//                 <tr>
//                   <th className="p-2">Header 1</th>
//                   <th className="p-2">Header 2</th>
//                   <th className="p-2">Header 3</th>
//                 </tr>
//               </thead>
//               <tbody className="block">
//                 {/* Loop through the rows directly within JSX */}
//                 { [...Array(100)].map((_, index) => (
//                   <tr key={index} className="flex">
//                     <td className="border border-gray-300 p-2 w-1/3">Row {index + 1}, Column 1</td>
//                     <td className="border border-gray-300 p-2 w-1/3">Row {index + 1}, Column 2</td>
//                     <td className="border border-gray-300 p-2 w-1/3">Row {index + 1}, Column 3</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>