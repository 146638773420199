import { FC, useCallback, useEffect, useState } from "react";
import { BuildIssuesFilter } from "./BuildIssueFilter";
import Lottie from 'react-lottie'
import { BuildAPI } from "../../../../../data/api/BuildAPI";
import { Issue } from "../../../../../data/model/Issue";
import emptyIssuesAnimation from '../../../../../assets/animations/empty-issues.json'
import issuesNotFoundAnimation from '../../../../../assets/animations/issues-not-found.json'
import { useParams } from "react-router-dom";
import { IssuesList } from "../../../../_partials/issues/IssuesList";
import { IssueDetails } from "../../../../_partials/issues/IssueDetails";
import { AppAPI } from "../../../../../data/api/AppAPI";
import { UserAPI } from "../../../../../data/api/UserAPI";
import { User } from "../../../../../data/model/User";

const IssuesPage: FC = () => {
	const { buildId } = useParams()

	const [issues, setIssues] = useState<Issue[]>([])
	const [filteredIssues, setFilteredIssues] = useState<Issue[]>([])

	const emptyIssuesAnimationOptions = {
		loop: true,
		autoplay: true,
		animationData: emptyIssuesAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}
	const issuesNotFoundAnimationOptions = {
		loop: true,
		autoplay: true,
		animationData: issuesNotFoundAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	}

	const fetchIssues = useCallback((async () => {
		const buildAPI = new BuildAPI()

		const build = await buildAPI.getBuildById(buildId!)
		const issues = await buildAPI.getIssuesForBuild(buildId!)
		const testers = await buildAPI.getTestersForBuild(buildId!)
		
        if (build.committerId != undefined) {
            const committer = await new UserAPI().getUserByID(build.committerId)
            build.committer = committer
        }
		const app = await new AppAPI().getAppById(build.appId)
		build.testers = testers
		console.debug(`[IssueList] fetch issues for build : ${issues}`)

		issues.forEach(issue => {
			console.debug(`[IssueList] issueID : ${issue.id}`)
			issue.build = build
			issue.app = app

		})

		console.debug(`[IssueList] all issues : ${(await issues).length} issues`)

		const sortedIssues = issues.sort((a, b) => {
			if (a.issueId! > b.issueId!) {
				return 1
			} else {
				return -1
			}
		})
		setIssues(sortedIssues)
		setFilteredIssues(sortedIssues)
	}), [buildId])

	useEffect(() => {
		fetchIssues()
	}, [fetchIssues])

	return (
		<>
			{
				issues.length > 0 ? (
					<div>
						<div className="h-full">
							<>
								<BuildIssuesFilter setFilteredIssues={setFilteredIssues} issues={issues} />
								{
									filteredIssues.length > 0 ? (
										<IssuesList issues={filteredIssues} />
									) : (
										<div className="items-center text-center">

											<Lottie options={issuesNotFoundAnimationOptions} height={150} width={200} />
											<div className="text-2xl font-bold mt-5">No issues match your filters</div>
										</div>

									)
								}
							</>
						</div>
					</div >
				) : (
					<div className="mt-16 items-center text-center">
						<Lottie options={emptyIssuesAnimationOptions} height={150} width={200} />
						<div className="text-2xl font-bold mt-5">No issues found</div>
					</div>
				)
			}
		</>
	);
}

export { IssuesPage }