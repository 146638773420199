import { FC, useCallback, useEffect, useState } from "react"
import { Link, Outlet, useParams } from "react-router-dom"
import { NavigationTabs } from "../../../../_components/NavigationTabs/Tabs"
import { NavigationTab } from "../../../../_components/NavigationTabs/Tab"
import { BuildAPI } from "../../../../../data/api/BuildAPI"
import { Build } from "../../../../../data/model/Build"
import { AppAPI } from "../../../../../data/api/AppAPI"
import { App } from "../../../../../data/model/App"
import { BackLink } from "../../../../_components/BackLink"
import { BuildOverview } from "../_components/BuildOverview"
import { ArrowLeftEndOnRectangleIcon, ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline"
import { BuildUseCases } from "../../../../../domain/usecases/BuildUseCases"

const BuildLayout: FC = () => {

	const { appId, buildId } = useParams()

	const [overviewVisible, setOverviewVisible] = useState(true)

	const [app, setApp] = useState<App>()
	const [build, setBuild] = useState<Build>()

	// TODO make use case to retrieve testers, and all extra information
	const getBuild = useCallback((async () => {
		const buildAPI = new BuildAPI()
		const buildUseCases = new BuildUseCases()

		const response = await buildAPI.getBuildById(buildId!)
		const build = await buildUseCases.assembleBuild(response)
		setBuild(build)
	}), [buildId])

	const getApp = useCallback((async () => {
		const appdAPI = new AppAPI()
		const response = await appdAPI.app(appId!)
		console.log(`BuildLayout fetch app response :${response}`)
		setApp(response)
	}), [buildId])


	useEffect(() => {
		getApp()
		getBuild()
	}, [])

	return (
		<div className={`h-full overflow-scroll ${overviewVisible ? "flex flex-grow" : "w-full"}`}>
			<div className={`pt-8 h-full flex flex-col px-6 ${overviewVisible ? "w-8/12" : "w-full"}`}>
				<div className="flex justify-between">
					<BackLink text="Back to builds list" />
					<button onClick={() => { setOverviewVisible(!overviewVisible) }}>
						{
							overviewVisible ? <ArrowRightEndOnRectangleIcon className="h-6 w-6" /> : <ArrowLeftEndOnRectangleIcon className="h-6 w-6" />
						}
					</button>
				</div>

				<NavigationTabs customClass="pt-5">
					<NavigationTab to={"issue"} title={"Issues"} />
					{/* <NavigationTab to={"crash"} title={"Crashes"} /> */}
					<NavigationTab to={"security"} title={"Security"} />
					<NavigationTab to={"live"} title={"Live log"} />
					<NavigationTab to={"network"} title={"Network"} />
					<NavigationTab to={"performance"} title={"Performance"} />
				</NavigationTabs>

				{ /** pb-40 is a very dirty trick to show entire scrollview, fuck CSS */}
				<div className="pl-2 h-full">
					<Outlet />
				</div>
			</div>
			{ /** Build overview */
				overviewVisible && app && build && (
					<BuildOverview app={app!} build={build!} />)
			}

		</div>
	)
}

export { BuildLayout }