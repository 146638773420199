import { ATS } from "./cards/ATS"
import { BinaryCodeAnalysis } from "./cards/BinaryCodeAnalysis"
import { DomainMalwareCherker } from "./cards/DomainMalwareCherker"
import { Emails } from "./cards/Emails"
import { Files } from "./cards/Files"
import { Librairies } from "./cards/Librairies"
import { MachOAnalysis } from "./cards/MachOAnalysis"
import { MobSFProps } from "./MobSFProps"
import { HardcodedSecrets } from "./cards/HardcodedSecrets"
import { Permissions } from "./cards/PermissionsCard"
import { Trackers } from "./cards/Trackers"
import { CustomURLSchemes } from "./cards/CustomURLSchemes"
import { URLs } from "./cards/URLs"


const BuildSecurityiOS: React.FC<MobSFProps> = ({ report, expanded }) => {

    return (
        <div>
            <CustomURLSchemes report={report} expanded={expanded} />
            <Permissions report={report} expanded={expanded} />
            <ATS report={report} expanded={expanded} />
            <BinaryCodeAnalysis report={report} expanded={expanded} />
            <MachOAnalysis report={report} expanded={expanded} />
            <DomainMalwareCherker report={report} expanded={expanded} />
            <URLs report={report} expanded={expanded} />
            <Emails report={report} expanded={expanded} />
            <Trackers report={report} expanded={expanded} />
            <HardcodedSecrets report={report} expanded={expanded} />
            <Librairies report={report} expanded={expanded} />
            <Files report={report} expanded={expanded} />

        </div>
    )
}

export { BuildSecurityiOS }