import { FC, useCallback, useEffect, useState } from "react"
import { Modal } from "../../../../_components/Modal"
import { Cog6ToothIcon, HandRaisedIcon, UserGroupIcon } from "@heroicons/react/24/outline"
import { Carousel } from "../../../../_components/Carousel"
import { TeamSettings } from "./createTeam/TeamSettings"
import { SelectUsersAndTeams } from "./createTeam/SelectUsersAndTeams"
import { useAuth } from "../../../../../hooks/useAuth"
import { CreateTeamViewModel } from "./CreateTeamViewModel"
import { SelectApps } from "./createTeam/SelectApps"
import { observer } from "mobx-react-lite"

type Props = {
	visible: boolean
	onClose: () => void
	viewModel: CreateTeamViewModel
}

const CreateTeamModal: FC<Props> = observer(({ visible, onClose, viewModel }: Props) => {

	useEffect(() => {
		viewModel.load()
	}, [viewModel]);

	return (
		<Modal title={'Create new team'} message={""} visible={visible}
			onClose={onClose}
		>
			<div>
				<div className="flex flex-row px-3 mt-3 mb-6 align-baseline items-center">
					<div className={`flex flex-col gap-2  items-center text-sm ${viewModel.step == 0 ? "font-semibold" : "text-gray-400"}  `}>
						<Cog6ToothIcon className="size-5 " />
						<p>New team</p>
					</div>
					<div className="h-full w-full mx-3 border-b-1 border-dashed"></div>
					<div className={`flex flex-col gap-2  items-center text-sm ${viewModel.step == 1 ? "font-semibold" : "text-gray-400"}  `}>
						<UserGroupIcon className="size-5" />
						<p className="text-sm whitespace-nowrap">Users & existing Teams</p>
					</div>
					<div className="h-full w-full mx-3 border-b-1 border-dashed"></div>
					<div className={`flex flex-col gap-2  items-center text-sm ${viewModel.step == 2 ? "font-semibold" : "text-gray-400"}  `}>
						<HandRaisedIcon className="size-5" />
						<p className="text-sm whitespace-nowrap">Access rights</p>
					</div>
				</div>
				<div className="border-b-1 h-1 mb-7"></div>
				{viewModel && <Carousel
					confirmIsLoading={viewModel.isCreating}
					onTabChange={(step) => viewModel.goToStep(step)}
					confirmTitle={viewModel.title}
					canShowNext={viewModel.nextIsActive}>
					<TeamSettings viewModel={viewModel} />
					<SelectUsersAndTeams viewModel={viewModel} />
					<SelectApps viewModel={viewModel} />
				</Carousel>}
			</div>
		</Modal >
	)
})

export { CreateTeamModal }