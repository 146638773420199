import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Log, LogSeverity } from "../../../../../data/model/Log";
import Moment from 'react-moment';
import { Loader } from "../../../../_components/Loader";
import { LiveAPI } from "../../../../../data/api/LiveAPI";
import { OutlineButton } from "../../../../_components/OutlineButton";
import { ArrowLongUpIcon, BarsArrowDownIcon, CloudArrowDownIcon, PauseIcon, PlayIcon, TrashIcon, WifiIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "../../../../_components/Tooltip";
import { log } from "console";
import { TextInput } from "../../../../_components/TextInput";

type Props = {
    buildId: string | undefined,
    sessionId: string | undefined
}

const LogContainer: FC<Props> = ({ buildId, sessionId }) => {

    const [logs, setLogs] = useState<Log[]>([]);

	const [search, setSearch] = useState("")

    const [startIndex, setStartIndex] = useState<string>("0");
    const [deviceDisconnected, setDeviceDisconnected] = useState<boolean>(false);
    const [autoScrolling, setAutoScrolling] = useState<boolean>(false);
    const [pause, setPause] = useState<boolean>(false);

    const defaultFilters = ["info", "debug", "error", "fault"]

    const [logLevels, setLogLevels] = useState<string[]>(defaultFilters)

    const getData = useCallback(async (sessionId: string, startIndex: string) => {
        if (buildId !== undefined && sessionId !== undefined && !pause) {
            const liveAPI = new LiveAPI()
            const newLogs = await liveAPI.getLogs(sessionId, startIndex)
            if (newLogs.length > 0) {
                console.log(`Current logs ${logs.length} logs`)
                console.log(`Receive ${newLogs.length} logs`)
                const l = logs.concat(newLogs)
                console.log(`New logs array:  ${l.length} logs`)
                setLogs(l)

                let lastElement = newLogs[newLogs.length - 1];
                setStartIndex("" + lastElement.id)
                console.log(`New startIndex  ${startIndex}`)

            }
            else {
                console.log(`0 new logs`)
            }
        }
    }, [logs, startIndex, pause])

    useEffect(() => {
        if (autoScrolling) {
            document.getElementById("down_anchor")?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [autoScrolling])

    useEffect(() => {

        if (buildId !== undefined && sessionId !== undefined) {
            getData(sessionId, startIndex);
            if (autoScrolling) {
                document.getElementById("down_anchor")?.scrollIntoView({ behavior: 'smooth' });
            }
        }
        const intervalCall = setInterval(() => {
            if (sessionId != undefined) {
                getData(sessionId, startIndex);
            }
        }, 2000);
        return () => {
            // clean up
            clearInterval(intervalCall);
        };

    }, [sessionId, logs, startIndex, pause, getData]);

    useEffect(() => {
        if (sessionId != undefined) {
            setLogs([])
            setDeviceDisconnected(false)
            setStartIndex("0")
        } else {
            setDeviceDisconnected(logs.length > 0)
        }
    }, [sessionId])

    const changeLogLevel = (value: string) => {

        if (value === "all") {
            setLogLevels(defaultFilters)
        }
        else {
            if (logLevels.includes(value)) {
                let levels = logLevels.filter(arrayItem => arrayItem !== value);
                if (levels.length == 0) {
                    levels = defaultFilters
                }
                setLogLevels(levels)
            }
            else {
                const levels = [...logLevels]
                levels.push(value)
                setLogLevels(levels)
            }
        }
    }

    const downloadLogs = () => {
        const jsonString = JSON.stringify(logs, null, 2);

        // Create a Blob with the JSON string
        const blob = new Blob([jsonString], { type: 'application/json' });

        // Generate a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Dynamically create an <a> element
        const a = document.createElement('a');
        a.href = url;
        a.download = "logs.json"; // Name of the downloaded file

        // Trigger a click event on the <a> element
        a.click();
    }

    return (
        <div>
            <div className="inline-flex w-full pb-4 justify-between">
                <div className="inline-flex gap-2">
                    <OutlineButton text={"ALL"} style={"neutral"} enabled={true} onClick={() => changeLogLevel("all")} />
                    •
                    <OutlineButton text={"DEBUG"} style={"success"} enabled={logLevels.includes("debug")} onClick={() => changeLogLevel("debug")} />
                    <OutlineButton text={"INFO"} style={"info"} enabled={logLevels.includes("info")} onClick={() => changeLogLevel("info")} />
                    <OutlineButton text={"ERROR"} style={"error"} enabled={logLevels.includes("error")} onClick={() => changeLogLevel("error")} />
                    <OutlineButton text={"FATAL"} style={"fatal"} enabled={logLevels.includes("fault")} onClick={() => changeLogLevel("fault")} />
					<TextInput name={""} 
								placeholder="Search" onChange={ e => setSearch(e.target.value) } />
                </div>
                <div className="space-x-2">
                    <Tooltip content="Clear output">
                        <button className="rounded-md p-2 text-sm bg-gray-50 text-black ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100 hover:ring-gray-700/10" onClick={() => setLogs([])}>
                            <TrashIcon className="size-4" />
                        </button>
                    </Tooltip>
                    <Tooltip content="Auto scroll">
                        <button className="rounded-md p-2 text-sm bg-gray-50 text-black ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100 hover:ring-gray-700/10" onClick={() => setAutoScrolling(true)} >
                            <BarsArrowDownIcon className="size-4" />
                        </button>
                    </Tooltip>
                </div>
            </div>
            <div className="bg-gray-800 rounded-lg px-3 py-6 overflow-scroll">
                {
                    logs
						?.filter(log => logLevels.includes(log.severity.toLowerCase()))
						.filter(log => {  
							if (search != "") { 
								return (log.file.toLowerCase().includes(search.toLowerCase()) || log.function.toLowerCase().includes(search.toLowerCase()) || log.message.toLowerCase().includes(search.toLowerCase())) 
							}
							else {
								return log
							}
						})
                        .map((log, index) => {
                            return <LogRow key={index} log={log} />
                        })
                }
                {
                    deviceDisconnected && (
                        <div className="text-orange-500 font-semibold flex flex-row items-center gap-1 py-4">
                            <WifiIcon className="size-6" />
                            <p>Device disconnected</p>
                        </div>
                    )
                }
                <div id="down_anchor"></div>
                <div className="w-full inline-flex justify-between my-2">
					<div>
                    {
                        !deviceDisconnected
                            ? (<Loader size="default" />)
                            : null
                    }
					</div>
                    <div className="space-x-1">
                        <Tooltip content={pause ? "Play" : "Pause"}>
                            <button className="rounded bg-gray-200 p-2" onClick={() => { setPause(!pause) }} >

                                {
                                    pause ? <PlayIcon className="size-4" /> : <PauseIcon className="size-4" />
                                }

                            </button>
                        </Tooltip>
                        <Tooltip content="Scroll up">
                            <button className="rounded bg-gray-200 p-2" onClick={() => {
                                document.getElementById("up_anchor")?.scrollIntoView({ behavior: 'smooth' });
                                setAutoScrolling(false)
                            }} >
                                <ArrowLongUpIcon className="size-4" />
                            </button>
                        </Tooltip>
                        <Tooltip content="Auto scroll">
                            <button className="rounded-md p-2 text-sm bg-gray-50 text-black ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100 hover:ring-gray-700/10" onClick={() => setAutoScrolling(true)} >
                                <BarsArrowDownIcon className="size-4" />
                            </button>
                        </Tooltip>
                        {
                            logs.length > 0 && (
                                <Tooltip content="Download logs">
                                    <button className="rounded-md p-2 text-sm bg-gray-50 text-black ring-1 ring-inset ring-gray-500/10 hover:bg-gray-100 hover:ring-gray-700/10" onClick={() => downloadLogs()} >
                                        <CloudArrowDownIcon className="size-4" />
                                    </button>
                                </Tooltip>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

type LogProps = {
    log: Log | null
}

const LogRow: React.FC<LogProps> = ({ log }) => {


    function color() {
        if (log) {
            switch (log!.severity) {
                case LogSeverity.INFO:
                    return "text-white"
                case LogSeverity.DEBUG:
                    return "text-green-500"
                case LogSeverity.ERROR:
                    return "text-red-500"
                case LogSeverity.FAULT:
                    return "text-purple-500"
            }
        }
        return "text-danger"
    }

    function severity() {
        if (log) {
            switch (log!.severity) {
                case LogSeverity.INFO:
                    return "INFO"
                case LogSeverity.DEBUG:
                    return "DEBUG"
                case LogSeverity.ERROR:
                    return "ERROR"
                case LogSeverity.FAULT:
                    return "FAULT"
            }
        }
        return "text-danger"
    }

    return (
        <div className="text-white"> {/** TODO Add code font, but check why this introduce line breaks */}
            <span className={'me-3 ' + color()}>[<Moment format="YYYY/MM/DD&nbsp;hh:mm:ss">{log?.date}</Moment>]</span>
            <span className={'me-3 ' + color()}>{severity()}</span>
            <span className={'me-3 ' + color()}>{log?.thread}</span>
            <span className={'me-3 ' + color()}>{log?.file}</span>
            <span className={'me-3 ' + color()}>{log?.function}</span>
            <span className={'me-3 ' + color()}>{log?.line}</span>
            <span className={'me-3 ' + color()}>{log?.message} </span>
        </div>
    )
}

export { LogContainer }
