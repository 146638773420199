import { ReqProps } from "../ReqProp"

const RequestQuery: React.FC<ReqProps> = ({req}) => {

	return (
        <div>
            {req? (
                (() => {
                    const queries = new URL(req!.url)
                    const params = queries.searchParams 
                    
                    const rows: React.ReactElement[] = [];
                    for (const [key, value] of params) {
                        rows.push(<p key={key} className="text-xs"><span>{key} : </span>{value}</p>)
                    }
                    return rows
                })()
            ): (<></>)}
        </div>
    )
}

export default RequestQuery