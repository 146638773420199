import { Build, BuildStatus } from '../model/Build';
import { axiosInstance } from '../utils/RequestUtils';
import { Issue } from '../model/Issue';
import { User } from '../model/User';
import { MobSF } from '../model/MobSF';
import { Crash } from '../model/Crash';
import { Request } from '../model/Request';
import { AxiosResponse } from 'axios';

const BASE = `/build`

export class BuildAPI {

     /**
     * Get build by its ID
     * @returns The related build
     * @throws
     */
     async getBuildById(buildId: string): Promise<Build> {
          const response = await axiosInstance.get(`${BASE}/${buildId}`)
          return response.data
     }

     /**
     * Delete build by its ID
     * @returns The related build
     * @throws
     */
     async deleteBuildById(buildId: string): Promise<Build> {
          const response = await axiosInstance.delete(`${BASE}/${buildId}`)
          return response.data
     }

     /**
     * Get issues for build with ID
     * @returns The related issues
     * @throws
     */
     async getIssuesForBuild(buildId: string): Promise<Issue[]> {
          const response = await axiosInstance.get(`${BASE}/${buildId}/issues`)
          return response.data
     }

     /**
     * Get crashes for build with ID
     * @returns The related crashes
     * @throws
     */
     async getCrashesForBuild(buildId: string): Promise<Crash[]> {
          const response = await axiosInstance.get(`${BASE}/${buildId}/crashes`)
          return response.data
     }

     /**
      * Get crash free user
      * @returns A percentage
      * @throws
      */
     async getCrashFreeUser(buildId: string): Promise<Number> {
          const response = await axiosInstance.get(`${BASE}/${buildId}/crashFreeUser`);
          return response.data
     }

     /**
     * Get requests for build with ID
     * @returns The related crashes
     * @throws
     */
     async getRequestsForBuild(buildId: string): Promise<Request[]> {
          const response = await axiosInstance.get(`${BASE}/${buildId}/requests`)
          return response.data
     }

     /**
     * Get testers for build with ID
     * @returns The related testers
     * @throws
     */
     async getTestersForBuild(buildId: string): Promise<User[]> {
          const response = await axiosInstance.get(`${BASE}/${buildId}/testers`)
          return response.data
     }

     /**
      * Get security report for the givent build id
      * @param buildId 
      * @returns 
      */
     async securityReport(buildId: string) {
          const response = axiosInstance.get<MobSF>(`${BASE}/${buildId}/mobsf`)
          return (await response).data
     }

     /**
      * Update build with its ID
      * @param buildId 
      * @returns 
      */
     async updateBuildStatusById(buildId: string, status: BuildStatus): Promise<Build> {
          return axiosInstance
               .patch(`${BASE}/${buildId}`, {
                    status: status
               })
               .then((d: AxiosResponse<Build>) => {
                    return d.data
               })
     }
}
