import { FC, useCallback, useEffect, useState } from "react";
import { Avatar } from "../_components/avatar/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Company } from "../../data/model/Company";
import { App, AppType } from "../../data/model/App";
import { AppAPI } from "../../data/api/AppAPI";
import { BuildAPI } from "../../data/api/BuildAPI";
import { Build } from "../../data/model/Build";
import { ReactComponent as AppleLogo } from '../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../assets/images/android-logo.svg'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { useOutsideClick } from "../../hooks/useOuterClick";
import { Label } from "../_components/Label";
import { DropDownField, DropDownItem } from "../_components/Dropdown";
import { UserAPI } from "../../data/api/UserAPI";

export type Breadcrumb = {
	mainFeature: String | undefined
	company: Company | undefined
	app: App | undefined
	build: Build | undefined
}

const Navbar: FC = () => {

	const { user, workspace, setWorkspace } = useAuth()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { appId, buildId } = useParams()
	const mainFeature = pathname.split("/")[1].charAt(0).toUpperCase() + pathname.split("/")[1].slice(1)
	const [breadcrumb, setBreadcrumb] = useState<Breadcrumb>()
	const [companies, setCompanies] = useState<Company[]>()
	const [openMenu, setOpenMenu] = useState(false);

	const [workspaceImageError, setWorkspaceImageError] = useState(false);
	const [appImageError, setAppImageError] = useState(false);

	/**
	  * Callback used to close the dropdown when user click outside
	  * see `useOutsideClick` below
	 */
	const closeOnOutside = () => { setOpenMenu(false) };
	/**
	 *  Reference of the dropdown menu div
	 *	use to detect if user click outside of the element
	 * 	to hide/close dropdown 
	 */
	const ref = useOutsideClick(closeOnOutside)

	const switchWorkspace = (company: Company) => {
		setWorkspace(company)
	};

	const builWorkspaceDropdown = useCallback((async () => {
		const userAPI = new UserAPI()
		const companies = await userAPI.getCompaniesForUser("me")
		setCompanies(companies)
	}), [user, pathname])

	const buildBreadcrumb = useCallback((async () => {
		const appAPI = new AppAPI()
		const buildAPI = new BuildAPI()

		const app = await appAPI.getAppById(appId!)

		var build = undefined
		if (buildId !== undefined) {
			build = await buildAPI.getBuildById(buildId)
		}

		setBreadcrumb({
			mainFeature: "",
			company: workspace,
			app: app,
			build: build
		})

	}), [appId, buildId])

	useEffect(() => {
		builWorkspaceDropdown()
	}, [user, pathname, builWorkspaceDropdown])

	useEffect(() => {
		if (appId !== undefined) {
			buildBreadcrumb()
		} else (
			setBreadcrumb({
				mainFeature: mainFeature,
				company: workspace,
				app: undefined,
				build: undefined
			})
		)

	}, [appId, buildId, buildBreadcrumb])

	useEffect(() => {
		if (workspace != undefined && openMenu) {
			setOpenMenu(false)
			navigate("/store")
		}
	}, [workspace])

	return (
		<header className="flex justify-between border-b border-gray-100 body-font h-[5rem] items-center">

			{/* Breadcrumb navigation
			Contains the active main feature at least
			Can contains current company, app and build selected in store feature */}
			<div id="breadcrumb" className='flex flex-row items-center'>

				<span className="flex font-black text-3xl ps-8">{mainFeature}</span>
				{
					workspace !== undefined && (
						<>
							<ChevronRightIcon className="size-5 m-1" />

							{
								workspace.icon && !workspaceImageError && <img className="size-8 rounded-md p-1" src={`/api/img/${workspace.icon}`} alt="" onError={() => setWorkspaceImageError(true)} />
							}

							<span className='font-black text-3xl'>{workspace.name}</span>
						</>
					)
				}
				{
					appId !== undefined && (
						<>
							<ChevronRightIcon className="size-5 m-1" />

							{
								breadcrumb?.app?.icon && !appImageError && <img className="size-8 rounded-md p-1" src={`/api/img/${breadcrumb?.app?.icon}`} alt="" onError={() => setAppImageError(true)} />
							}
							<span className='font-black text-3xl'>{breadcrumb?.app?.name}</span>
							{breadcrumb?.app?.platform === AppType.IOS ? <AppleLogo className="inline size-5 items-center ml-1" /> : <AndroidLogo className="inline size-5 items-center ml-1" />}
						</>
					)
				}
				{
					buildId !== undefined && (
						<>
							<ChevronRightIcon className="size-5 m-1" />
							<span className='font-black text-3xl'>#{breadcrumb?.build?.version}-({breadcrumb?.build?.buildNumber})</span>
						</>
					)

				}
			</div>

			{/* Top right avatar and submenu
			Use to logout and see connected user */}
			<div ref={ref} className="relative">
				<div className={`inline-flex py-2 h-26 pe-8 justify-between items-center cursor-pointer`} onClick={() => { openMenu ? setOpenMenu(false) : setOpenMenu(true) }}>
					<Avatar user={user!} />
				</div>
				<div className={`${openMenu ? "visible" : "invisible"} divide-y right-6 min-w-48 absolute z-50 px-2 py-4 bg-white space-y-2 shadow-lg rounded-md ring-1 ring-black ring-opacity-5`}>
					<div className='px-4'>
						<Label htmlFor={""} label="Workspace" />
						<div className="rounded-md  align-center">
							<DropDownField defaultValue={workspace?.name || ""} onSelect={(value) => {
								const selected = companies?.find(company => { return company.name == value })
								if (selected != undefined) {
									switchWorkspace(selected)
								}
							}}>
								{
									companies?.map(company => {
										return <DropDownItem key={company.id} value={company.name || company.id} title={company.name || company.id} />
									})
								}
							</DropDownField>
						</div>
					</div>
					<Link to={"/profile"} onClick={() => { setOpenMenu(false) }} className='px-4 pt-4 flex flex-col gap-1'>
						<span className="font-bold">{user?.firstname} {user?.lastname}</span>
						<span className="">{user?.email}</span>
					</Link>
					<div className='px-2 pt-4'>
						<Link to={"/profile"} onClick={() => { setOpenMenu(false) }} className='flex font-semibold hover:bg-gray-100 hover:rounded py-1 px-2'>Connect to mobile app</Link>
					</div>
					<div className='px-2 pt-4'>
						<a href='/api/auth/logout' className='flex font-semibold hover:bg-gray-100 hover:rounded py-1 px-2'>
							Sign Out
						</a>
					</div>
				</div>
			</div>
		</header>
	)
}

export { Navbar }