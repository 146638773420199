import { FC, PropsWithChildren, useState, Children, useRef, useEffect } from "react"
import "./Dropdown.css"
import { useOutsideClick } from "../../hooks/useOuterClick"
import { ChevronDownIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import React from "react"

/**
 * Simple dropdown in react
 * 
 * Usage : 
 * <DropDownField onSelect={value}>
 * 	 <DropDownItem value={} title={} key={} />
 * 	 <DropDownItem value={} title={} key={} />
 * </DropDownField>
 * 
 * title: is displayed
 * value: is returned in onSelect function
 * key: as usall
 */

type Props = {
	defaultValue?: string
	customClass?: string
	emptyMessage?: string
	onSelect: (value: string) => void
}

const DropDownField: FC<PropsWithChildren<Props>> = ({ customClass, children, defaultValue, onSelect, emptyMessage = "Empty list" }) => {

	/**
	 *  Is the dropdown is opened
	 */
	const [open, setOpen] = useState(false);

	/**
	 *  Selected value
	 */
	const [selectedOption, setSelectedOption] = useState<ItemProps | undefined>(undefined);

	/**
	 * Callback used to close the dropdown when user click outside
	 * see `useOutsideClick` below
	 */
	const closeOnOutside = () => { setOpen(false) };
	/**
	 *  Reference of the dropdown menu div
	 *	use to detect if user click outside of the element
	 * 	to hide/close dropdown 
	 */
	const ref = useOutsideClick(closeOnOutside)

	/**
	 * Reference to the search input text
	 * This ref is used to clear the search when user select or close the dropdown
	 */
	const inputRef = useRef<HTMLInputElement>(null)

	/**
	 * List of child elements of the dropdown
	 * We iterate throught all children to filter and render them
	 */
	let arrayChildren: React.ReactElement<ItemProps>[] = Children.toArray(children) as React.ReactElement<ItemProps>[]

	/**
	 * List of child elements of the dropdown filtered by the search input
	 */
	const [filteredArrayChildren, setFilteredArrayChildren] = useState<React.ReactElement<ItemProps>[] | undefined>(undefined)

	useEffect(() => {
		if (open) {
			if (arrayChildren.length == 0) {
				setFilteredArrayChildren(undefined);
			} else {
				setFilteredArrayChildren(arrayChildren);
			}
		}

	}, [open]);


	// TODO Find a way to not refresh if arrayChildren are exactly the same
	useEffect(() => {

		Children.map(arrayChildren, (child: React.ReactElement<ItemProps>, index) => {
			if (child.props.value == defaultValue) {
				setSelectedOption(child.props)
			}
		})

		if (open) {
			if (arrayChildren.length == 0 && filteredArrayChildren != undefined) {
				setFilteredArrayChildren(undefined);
			}
			if (arrayChildren.length > 0 && filteredArrayChildren == undefined) {
				setFilteredArrayChildren(arrayChildren);
			}
		}

	}, [arrayChildren]);

	/**
	 * Private on select function
	 * This function will affect the selected value to the field, call the parent's `onSelect()` function
	 */
	const _onSelect = (props: ItemProps) => {
		setSelectedOption(props)
		onSelect(props.value)
		setOpen(false)
		setFilteredArrayChildren(arrayChildren)
		if (inputRef.current != null) {
			inputRef.current!.value = ""
		}
	}

	return (
		<div ref={ref} className="relative">
			<div className={`inline-flex rounded-md border-0 py-2 min-w-40 h-26 px-2 justify-between items-center cursor-pointer ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${customClass}`} onClick={() => { open ? setOpen(false) : setOpen(true) }}>
				<p className="pr-2 font-semibold min-h-5">{selectedOption?.title}</p>
				<ChevronDownIcon className="w-4 h-4 text-gray-300" />
			</div>

			<div className={`${open ? "visible" : "invisible"} mt-0 z-[100] max-h-64 min-w-64 no-scrollbar absolute pt-3 pb-2 bg-white space-y-2 shadow-lg rounded-md ring-1 ring-black ring-opacity-5`}>

				{
					arrayChildren.length <= 0 ? (
						<p className="p-4">{emptyMessage}</p>
					) : (
						<>
							<div className="rounded-md w-full px-3">
								<input
									ref={inputRef}
									type="text"
									name="Search"
									placeholder="Search"
									className={`w-full rounded-md border-0 py-2 pl-2 ring-1 ring-inset ring-gray-200 placeholder:text-base placeholder:text-gray-400 ${customClass}`}
									onChange={
										(e) => {

											const filtered = arrayChildren.filter((child) => {
												if (child.props.title.toLowerCase().includes(e.target.value.toLowerCase())) {
													return true
												}
												return false
											})
											setFilteredArrayChildren(filtered)
										}
									}
								/>
							</div>

							{
								filteredArrayChildren && Children.map(filteredArrayChildren, (child: React.ReactElement<ItemProps>, _) => {
									const isSelected = child.props.value === (selectedOption?.value)

									return (
										<>
											<div onClick={() => { _onSelect(child.props) }}>
												{React.cloneElement(child, { selected: isSelected })} { /* Clone to inject selected state */}
											</div>
										</>
									)
								})
							}
						</>
					)
				}
			</div>
		</div>
	)
}

type ItemProps = {
	title: string
	value: string
	selected?: boolean
}

const DropDownItem: FC<ItemProps> = ({ value, title, selected }) => {

	return (
		<div className="hover:bg-blue-100 py-2 cursor-pointer flex items-center">
			<p className={`text-base ps-4 pe-1 ${selected ? " font-extrabold" : "font-semibold"} `}> {title}</p>
			{selected ? (
				<CheckCircleIcon className="size-4 font-bold" />
			) : <></>
			}
		</div>
	)
}

export { DropDownField, DropDownItem }