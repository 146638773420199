import { FC, useCallback, useEffect, useState } from "react"
import { IssueCriticality, IssueStatus } from "../../../../../data/api/IssuesAPI"
import { Issue } from "../../../../../data/model/Issue"
import { DropDownField, DropDownItem } from "../../../../_components/Dropdown"
import { Label } from "../../../../_components/Label"

type Props = {
	issues: Issue[],
	setFilteredIssues: React.Dispatch<React.SetStateAction<Issue[]>>
}

export type Filters = {
	status: string | 'all',
	criticality: string | "all"
}

const BuildIssuesFilter: FC<Props> = ({ issues, setFilteredIssues }) => {

	const [filters, setFilters] = useState<Filters>({
		status: 'all',
		criticality: 'all',
	})

	const filterIssues = useCallback((() => {

		console.debug(JSON.stringify(filters))

		setFilteredIssues(issues.filter(issue => {
			return (filters?.status === 'all' || issue.status === filters?.status)
			&& (filters?.criticality === 'all' || issue.criticality === filters?.criticality)
		}))

	}), [filters, issues, setFilteredIssues])

	useEffect(() => {
		if (filters?.status !== undefined) {
			filterIssues()
		}
	}, [filters.status, filterIssues])

	return (
		<div className=" flex flex-row gap-4">
			<div className="">
				<Label htmlFor={""} label="Status" />
				<div className="rounded-md">
					<DropDownField customClass="mb-4" onSelect={(value) => {
						setFilters({
							status: value,
							criticality: filters.criticality
						})
					}} defaultValue="&nbsp;">
						<DropDownItem value="all" title="All" />
						<DropDownItem value={IssueStatus.NEW} title={IssueStatus.NEW} />
						<DropDownItem value={IssueStatus.CLOSED} title={IssueStatus.CLOSED} />
						<DropDownItem value={IssueStatus.REFUSED} title={IssueStatus.REFUSED} />
						<DropDownItem value={IssueStatus.VALIDATED} title={IssueStatus.VALIDATED} />
					</DropDownField>
				</div>
			</div>
			<div className="">
				<Label htmlFor={""} label="Criticality" />
				<div className="rounded-md">
					<DropDownField customClass="mb-4" onSelect={(value) => {
						setFilters({
							status: filters.status,
							criticality: value
						})
					}} defaultValue="&nbsp;">
						<DropDownItem value="all" title="All" />
						<DropDownItem value={IssueCriticality.LOW} title={IssueCriticality.LOW} />
						<DropDownItem value={IssueCriticality.MEDIUM} title={IssueCriticality.MEDIUM} />
						<DropDownItem value={IssueCriticality.HIGH} title={IssueCriticality.HIGH} />
						<DropDownItem value={IssueCriticality.CRITICAL} title={IssueCriticality.CRITICAL} />
					</DropDownField>
				</div>
			</div>
		</div>
	)
}

export { BuildIssuesFilter }