import { FC, useState } from "react"
import { Build, BuildStatus } from "../../../../../../data/model/Build"
import { Badge, BadgeType } from "../../../../../_components/Badge"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Tooltip } from "../../../../../_components/Tooltip"
import { CloudArrowDownIcon, HandThumbDownIcon, HandThumbUpIcon, TrashIcon, LinkIcon } from '@heroicons/react/24/outline'
import { Tester } from "../../../../../_components/tester/Tester"
import { Action } from "../../../../../_props/Actions"
import { BuildAPI } from "../../../../../../data/api/BuildAPI"
import { Avatar } from "../../../../../_components/avatar/Avatar"
import { useAuth } from "../../../../../../hooks/useAuth"
import { Alert } from "../../../../../_components/Alert"
import { ButtonGroup } from "../../../../../_components/ButtonGroup"
import { AppAPI } from "../../../../../../data/api/AppAPI"
import { App } from "../../../../../../data/model/App"

type Props = {
	app: App
	builds: Build[]
}

const BuildList: FC<Props> = ({ app, builds }) => {
	const { REACT_APP_API_URL } = process.env

	const buildAPI = new BuildAPI()
	const { user } = useAuth()
	const [buildToValidate, setBuildToValidate] = useState<Build | undefined>(undefined)
	const [buildToRefuse, setBuildToRefuse] = useState<Build | undefined>(undefined)
	const [buildToDelete, setBuildToDelete] = useState<Build | undefined>(undefined)
	const [buildToDL, setBuildToDL] = useState<Build | undefined>(undefined)

	const actions: Action<Build>[] = [
		{
			description: "Download binary",

			icon: <CloudArrowDownIcon className="size-4" />,
			action: (build) => { setBuildToDL(build) }
		},
		{
			description: "Validate build",

			icon: <HandThumbUpIcon className="size-4" />,
			action: (build) => { setBuildToValidate(build) }
		},
		{
			description: "Refuse build",

			icon: <HandThumbDownIcon className="size-4" />,
			action: (build) => { setBuildToRefuse(build) }
		},
		{
			description: "Delete build",
			icon: <TrashIcon className="size-4" />,
			action: (build) => { setBuildToDelete(build) }
		}
	]

	const validateBuild = async () => {
		if (buildToValidate) {
			const updatedBuild = await buildAPI.updateBuildStatusById(buildToValidate.id, BuildStatus.OK)
			if (buildToValidate.testers.findIndex((tester) => { return tester.id == user!.id }) == -1) {
				buildToValidate.testers.push(user!)
			}

			buildToValidate.validatorsId = updatedBuild.validatorsId
			buildToValidate.deniesId = updatedBuild.deniesId
			buildToValidate.status = updatedBuild.status
			setBuildToValidate(undefined)
		}
	}

	const refuseBuild = async () => {

		if (buildToRefuse) {
			const updatedBuild = await buildAPI.updateBuildStatusById(buildToRefuse.id, BuildStatus.KO)

			if (buildToRefuse.testers.findIndex((tester) => { return tester.id == user!.id }) == -1) {
				buildToRefuse.testers.push(user!)
			}

			buildToRefuse.validatorsId = updatedBuild.validatorsId
			buildToRefuse.deniesId = updatedBuild.deniesId
			buildToRefuse.status = updatedBuild.status
			setBuildToRefuse(undefined)
		}
	}
	const deleteBuild = async () => {
		if (buildToDelete) {
			await buildAPI.deleteBuildById(buildToDelete.id)
			console.log(builds.findIndex((value) => { return value.id == buildToDelete.id }))
			builds.splice(builds.findIndex((value) => { return value.id == buildToDelete.id }), 1)
			setBuildToDelete(undefined)
		}
	}
	return (

		<>
			{
				builds.length > 0 ? (
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th className="w-16"></th>
									<th className="w-20">Version</th>
									<th className="w-fit">Issue ID</th>
									<th className="w-fit">Size</th>
									<th className="w-fit">Date</th>
									<th className="w-fit hidden xl:table-cell">Commit Hash<br />Pipeline</th>
									<th className="w-fit hidden sm:table-cell">Tested by</th>
									<th className="w-fit">Status</th>
									<th className="w-48">Actions</th>
								</tr>
							</thead>
							<tbody>
								{builds.map((build) => { return <BuildRow app={app} build={build} key={build.id} actions={actions} /> })}
							</tbody>
						</table>

						{ /** Build to DL modal */}
						{
							<Alert
								title={"Downloading binary file"}
								message={""}
								visible={buildToDL != undefined}
								primaryButtonTitle={"Yes"}
								primaryCallback={() => {
									downloadBlob(`${REACT_APP_API_URL}/binary/${buildToDL?.binary}`)
									setBuildToDL(undefined)
								}
								}
								secondaryButtonTitle="Cancel"
								secondaryCallback={() => {
									setBuildToDL(undefined)
								}
								} />
						}
						{ /** Build to validate modal */}
						{
							<Alert
								title={`Validating Build version ${buildToValidate?.version}`}
								message={""}
								visible={buildToValidate != undefined}
								primaryButtonTitle={"Yes"}
								primaryCallback={validateBuild}
								secondaryButtonTitle="Cancel"
								secondaryCallback={() => { setBuildToValidate(undefined) }} />
						}
						{ /** Build to refuse modal */}
						{

							<Alert
								title={`Refusing Build version ${buildToRefuse?.version}`}
								message={""}
								visible={buildToRefuse != undefined}
								primaryButtonTitle={"Yes"}
								primaryCallback={refuseBuild}
								secondaryButtonTitle="Cancel"
								secondaryCallback={() => { setBuildToRefuse(undefined) }} />
						}
						{ /** Build to delete modal */}
						{
							<Alert
								title={`Deleting Build version ${buildToDelete?.version}`}
								message={""}
								type="destructive"
								visible={buildToDelete != undefined}
								primaryButtonTitle={"Yes"}
								primaryCallback={deleteBuild}
								secondaryButtonTitle="Cancel"
								secondaryCallback={() => { setBuildToDelete(undefined) }} />
						}
					</div>
				) : <div className="items-center text-center">
					<div className="text-2xl font-bold mt-10">No more builds</div>
				</div>
			}
		</>

	)

}

type RowProps = {
	app: App,
	build: Build,
	actions?: Action<Build>[]
}
const BuildRow: FC<RowProps> = ({ app, build, actions }) => {

	const navigate = useNavigate();

	const date = new Date(build.createdAt)
	const day = date.toLocaleDateString()
	const time = date.toLocaleTimeString()

	const statusBadge = () => {
		if (build.status === BuildStatus.NEW) {
			return <Badge type={BadgeType.INFO} text={"New"} />
		} else if (build.status === BuildStatus.OK) {
			return <Badge type={BadgeType.GOOD} text={"Validated"} />
		} else if (build.status === BuildStatus.TEST_IN_PROGRESS) {
			return <Badge type={BadgeType.WARNING} text={"Testing"} />
		}
		else {
			return <Badge type={BadgeType.ERROR} text={" Refused"} />
		}
	}

	const issuerID = () => {
		return (
			<>
				{
					app.jiraUrl ? <Link to={`${app.jiraUrl}${build.customerIssue}`} onClick={(event) => { event.stopPropagation() }}>
						<div className="w-fit flex flex-row space-x-1 hover:text-blue-600 hover:underline">
							<p className={`font-bold`}>{build.customerIssue !== "unknown" ? `#${build.customerIssue}` : "-"}</p>
							<LinkIcon className="size-4" />
						</div>
					</Link> :
						<Tooltip content={'To be linked to your Jira instance, please go configure Integration in your app settings'}>
							<div className="w-fit flex flex-row space-x-1 hover:text-blue-600 hover:underline">
								<p className={`font-bold`}>{build.customerIssue !== "unknown" ? `#${build.customerIssue}` : "-"}</p>
								<LinkIcon className="size-4" />
							</div>
							{/* </Link> */}
						</Tooltip>
				}
			</>
		)
	}

	const buildSize = () => {
		if (build.size > 0) {
			const truncatedValue = Math.trunc((build.size / (1024 * 1024)) * 100) / 100;
			return truncatedValue + "MB"
		}
		else {
			return "-"
		}
	
	}

	return (
		<tr className="cursor-pointer" onClick={() => { navigate(`build/${build.id}`) }}>
			<td className="">
				{
					build.committer ? (
						<Tooltip content={build.committer?.firstname + " " + build.committer?.lastname}>
							<span>
								<Avatar customClass="h-8 w-8 rounded-full" user={build.committer!} />
							</span>
						</Tooltip>
					) : build.committerEmail ? (<Tooltip content={build.committerEmail}>
						<div className={`flex items-center justify-center bg-slate-100 text-black font-bold`}>
							{`${build.committerEmail[0] || ""}`.toUpperCase() || "?"}
						</div>
					</Tooltip>) : <></>
				}
			</td>
			<td>{build.version}</td>
			<td>{issuerID()}</td>
			<td>{buildSize()}</td>
			<td><span className="font-bold">{day}</span><br />{time}</td>
			<td className="hidden xl:table-cell">

				{
					app.gitlabUrl ? <Link to={`${app.gitlabUrl}/-/commit/${build.commitHash}`} onClick={(event) => { event.stopPropagation() }}>
						<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build.commitHash}</div>
					</Link> :
						<Tooltip content={'To be linked to your gitlab instance, please go configure Integration in your app settings'}>
							<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build.commitHash}</div>
						</Tooltip>
				}
				<br />
				{
					app.gitlabUrl ? <Link to={`${app.gitlabUrl}/-/pipelines/${build.pipelineId}`} onClick={(event) => { event.stopPropagation() }}>
						<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build.pipelineId}</div>
					</Link> :
						<Tooltip content={'To be linked to your gitlab instance, please go configure Integration in your app settings'}>
							<div className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">#{build.pipelineId}</div>
						</Tooltip>
				}

			</td>
			<td className="hidden sm:table-cell whitespace-nowrap items-center"><div className="flex -space-x-2 overflow-hidden items-center">
				{
					build.testers?.map(tester => {
						return (
							<Tester user={tester} customClass="inline-block h-8 w-8 rounded-full"
								haveValidated={build.validatorsId.includes(tester.id!)}
								haveRefused={build.deniesId.includes(tester.id!)} />
						)
					})
				}
			</div>
			</td>
			<td>{statusBadge()}</td>
			<td>
				<ButtonGroup mode="click">
					{
						actions?.map(action => {
							return (
								<Tooltip content={action.description}>
									<p onClick={(e) => { e.stopPropagation(); action.action!(build) }}>{action.icon}</p>
								</Tooltip>
							)
						})
					}
				</ButtonGroup>
				{/* {
					actions?.map(action => {
						return <>
							<Tooltip content={action.description}>
								<button className={`border-1 rounded-md me-1 p-2 ${action.bgColor}`} onClick={(event) => {
									event.stopPropagation()
									action.action!(build)
								}}>
									{action.icon}
								</button>
							</Tooltip>
						</>
					})
				} */}
			</td>
		</tr>
	)
}

export function downloadBlob(url: string) {

	console.log(`Downloading ${url}`)

	// Create a new anchor element
	const a = document.createElement('a');

	// Set the href and download attributes for the anchor element
	// You can optionally set other attributes like `title`, etc
	// Especially, if the anchor element will be attached to the DOM
	a.href = url;
	a.download = 'download';

	// Click handler that releases the object URL after the element has been clicked
	// This is required for one-off downloads of the blob content
	const clickHandler = () => {
		setTimeout(() => {
			URL.revokeObjectURL(url);
			// removeEventListener('click', clickHandler);
		}, 150);
	};

	// Add the click event listener on the anchor element
	// Comment out this line if you don't want a one-off download of the blob content
	a.addEventListener('click', clickHandler, false);

	// Programmatically trigger a click on the anchor element
	// Useful if you want the download to happen automatically
	// Without attaching the anchor element to the DOM
	// Comment out this line if you don't want an automatic download of the blob content
	a.click();

	// Return the anchor element
	// Useful if you want a reference to the element
	// in order to attach it to the DOM or use it in some other way
	return a;
}


export { BuildList }