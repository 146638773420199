import { FC, useState } from "react";
import { Issue } from "../../../data/model/Issue";
import { Badge, BadgeType } from "../../_components/Badge";
import { IssueCriticality, IssueStatus, IssuesAPI } from "../../../data/api/IssuesAPI";
import { AppType } from "../../../data/model/App";
import { ReactComponent as AppleLogo } from '../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../assets/images/android-logo.svg'
import { TrashIcon, FireIcon, FlagIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { useParams } from "react-router-dom";
import { Utils } from "../../_utils/Utils";
import Moment from "react-moment";
import Tooltip from "@material-tailwind/react/components/Tooltip";
import { Action } from "../../_props/Actions";
import { IssueDetails } from "./IssueDetails";
import { Alert } from "../../_components/Alert";
import { ButtonGroup } from "../../_components/ButtonGroup";

type Props = {
	issues: Issue[]
}

const IssuesList: FC<Props> = ({ issues }) => {

	const { buildId } = useParams()
	const issuesAPI = new IssuesAPI()
	const [issueToSelect, setIssueToSelect] = useState<Issue | undefined>(undefined)
	const [issueToValidate, setIssueToValidate] = useState<Issue | undefined>(undefined)
	const [issueToRefuse, setIssueToRefuse] = useState<Issue | undefined>(undefined)
	const [issueToDelete, setIssueToDelete] = useState<Issue | undefined>(undefined)

	const actions: Action<Issue>[] = [
		{
			description: "Validate issue",
			icon: <HandThumbUpIcon className="size-4" />,
			action: (issue) => { setIssueToValidate(issue) }
		},
		{
			description: "Refuse issue",
			icon: <HandThumbDownIcon className="size-4" />,
			action: (issue) => { setIssueToRefuse(issue) }
		},
		{

			description: "Delete issue",
			icon: <TrashIcon className="size-4" />,
			action: (issue) => { setIssueToDelete(issue) }
		}
	]

	const validateIssue = async () => {

		if (issueToValidate) {
			await issuesAPI.updateIssueStatusById(issueToValidate.id!, IssueStatus.VALIDATED)
			issueToValidate.status = IssueStatus.VALIDATED
			setIssueToValidate(undefined)
			setIssueToSelect(undefined)
		}
	}
	const refuseIssue = async () => {

		if (issueToRefuse) {
			await issuesAPI.updateIssueStatusById(issueToRefuse.id!, IssueStatus.REFUSED)
			issueToRefuse.status = IssueStatus.REFUSED
			setIssueToRefuse(undefined)
			setIssueToSelect(undefined)
		}
	}
	const deleteIssue = async () => {

		if (issueToDelete) {
			await issuesAPI.updateIssueStatusById(issueToDelete.id!, IssueStatus.CLOSED)
			issueToDelete.status = IssueStatus.CLOSED
			setIssueToDelete(undefined)
			setIssueToSelect(undefined)
		}
	}
	
	
	

	return (
		<>
			{
				issues.length > 0 ? (
					<div className="table-container">
						<table>
							<thead>
								<tr>
									<th>Title</th>
									<th className="w-44">Created /<br /> Updated</th>
									<th className="w-28">Criticality</th>
									{
										buildId == undefined && (
											<>
												<th className="w-32">App</th>
												<th className="w-24">Build version</th>
											</>
										)
									}
									<th className="w-24">Status</th>
									<th className="w-32">Actions</th>
								</tr>
							</thead>
							<tbody>
								{issues.map((issue) => {
									return <IssueRow issue={issue} key={issue.id} onSelect={() => { setIssueToSelect(issue) }} actions={actions} />
								})}
							</tbody>
						</table>
						{ /** Issue to select modal */}
						{
							issueToSelect != undefined && (
								<IssueDetails issue={issueToSelect} actions={actions} close={() => { setIssueToSelect(undefined); } } visible={issueToSelect != undefined} />
							)
						}
						{ /** Issue to validate modal */}
						{
							<Alert 
								title={"Are-you sure ?"} 
								message={""} 
								visible={ issueToValidate != undefined } 
								primaryButtonTitle={"Yes"} 
								primaryCallback={ validateIssue } 
								secondaryButtonTitle="Cancel"
								secondaryCallback={ () => {
									setIssueToValidate(undefined)
									setIssueToSelect(undefined)
								}} />
						}
						{ /** Issue to validate modal */}
						{

							<Alert 
								title={"Are-you sure ?"} 
								message={""} 
								visible={ issueToRefuse != undefined } 
								primaryButtonTitle={"Yes"} 
								primaryCallback={ refuseIssue }
								secondaryButtonTitle="Cancel"
								secondaryCallback={ () => {
									setIssueToRefuse(undefined)
									setIssueToSelect(undefined)
								}} />
						}
						{ /** Issue to delete modal */}
						{
							<Alert 
								title={"Are-you sure ?"} 
								message={""} 
								type="destructive"
								visible={ issueToDelete != undefined } 
								primaryButtonTitle={"Yes"} 
								primaryCallback={ deleteIssue }
								secondaryButtonTitle="Cancel"
								secondaryCallback={ () => {
									setIssueToDelete(undefined)
									setIssueToSelect(undefined)
								}} />
						}
					</div>
				) : <div className="items-center text-center">
					<div className="text-2xl font-bold mt-10">No more issues</div>
				</div>
			}
		</>

	)
}


type RowProps = {
	issue: Issue,
	onSelect: () => void,
	actions?: Action<Issue>[]
}
const IssueRow: FC<RowProps> = ({ issue, onSelect, actions }) => {

	const { buildId } = useParams()

	const statusBadge = () => {
		if (issue.status === IssueStatus.NEW) {
			return <Badge type={BadgeType.INFO} text={"New"} />
		} else if (issue.status === IssueStatus.CLOSED) {
			return <Badge type={BadgeType.NEUTRAL} text={" Closed"} />
		} else if (issue.status === IssueStatus.REFUSED) {
			return <Badge type={BadgeType.ERROR} text={" KO"} />
		} else { // VALIDATED
			return <Badge type={BadgeType.GOOD} text={" OK"} />
		}
	}

	const criticality = () => {
		if (issue.criticality === IssueCriticality.LOW) {
			return (
				<Badge type={BadgeType.INFO} text="Low" />
			)
		} else if (issue.criticality === IssueCriticality.MEDIUM) {
			return (
				<Badge type={BadgeType.WARNING} text="Medium" />
			)
		} else if (issue.criticality === IssueCriticality.HIGH) {

			return (
			<Badge type={BadgeType.HIGH} text="High" />
			)
		} else { // CRITICAL
			return <Badge type={BadgeType.DANGEROUS} text="Hight" />
		}
	}

	return (
		<tr className="cursor-pointer" onClick={() => { onSelect() }}>
			<td className="break-words">
				{Utils.breakLongWords(issue.title!)}
			</td>
			<td className="break-words">
				<Moment className="text-xs" format="YYYY/MM/DD hh:mm:ss">{issue?.createdAt}</Moment>
				<br />
				<Moment className="text-xs" format="YYYY/MM/DD hh:mm:ss">{issue?.createdAt}</Moment>
			</td>
			<td>{criticality()}</td>
			{
				buildId == undefined && (
					<>
						<td>
							<div className="flex items-center py-1">
								<img className="size-7 rounded-md p-1" src={`/api/img/${issue.app.icon}`} alt=""></img>
								<h3 className="inline items-center me-1">{issue.app.name}</h3>
								{issue.app.platform === AppType.IOS ? <AppleLogo className="inline size-4 items-center" /> : <AndroidLogo className="inline size-3 items-center" />}
							</div>
						</td>
						<td>{issue.build.version}</td>
					</>
				)
			}


			<td>{statusBadge()}</td>
			<td>
				<td className="justify-center whitespace-nowrap">
					{
						<ButtonGroup mode="click">
					{
						actions?.map ( action => { 
							return (
							<Tooltip content={action.description}>
								<p onClick={ (e) =>  { e.stopPropagation(); action.action!(issue) } }>{ action.icon }</p>
							</Tooltip>
							)
						})
					}
				</ButtonGroup>
						// actions?.map(action => {
						// 	return <>
						// 		<Tooltip content={action.description}>
						// 			<button className={`border-1 rounded-md me-1 p-2 ${action.bgColor}`} onClick={(event) => {
						// 				event.stopPropagation()
						// 				action.action!(issue)
						// 			}}>
						// 				{action.icon}
						// 			</button>
						// 		</Tooltip>
						// 	</>
						// })
					}
				</td>
			</td>
		</tr>
	)
}


export { IssuesList }