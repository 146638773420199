import { Device } from '../model/Device';
import { LiveConfiguration } from '../model/LiveConfiguration';
import { Log } from '../model/Log';
import { Request } from '../model/Request';
import { axiosInstance } from '../utils/RequestUtils';

const BASE = `/live`

class LiveAPI {

	/**
     * Get a list of devices currently connected to an app build
     * @returns A list of devices
     * @throws
     */
	async devices(buildId: string): Promise<Device[]> {
	     const response = await axiosInstance.get(`${BASE}/${buildId}/devices`)
          return response.data
	}

     /**
      * Activate remote logging for the given device on given build
      */
     async activate(buildId: string, sessionId: string): Promise<LiveConfiguration> {
          console.log(`Activate live for build: ${buildId} sessionId: ${sessionId}`)
          const response = await axiosInstance.get(`${BASE}/${buildId}/${sessionId}/activate`)
          return response.data
     }


     /**
      * Disable remote logging for the given device on given build
      */
     async disable(buildId: string, deviceId: string): Promise<LiveConfiguration> {
		console.log(`Disable live for build: ${buildId} device: ${deviceId}`)
	     const response = await axiosInstance.get(`${BASE}/${buildId}/${deviceId}/disable`)
		return response.data
	}


     /**
      * Fetch remote logs
      */
	async getLogs(sessionId: string, lastLogIndex: string): Promise<Log[]> {
		console.log(`Fetching logs for session: ${sessionId} index: ${lastLogIndex}`)
		const response = await axiosInstance.get(`${BASE}/logs/${sessionId}/${lastLogIndex}`)
		return response.data
	}

     /**
      * Fetch remote requests
      */
	async getRequests(sessionId: string, lastRequestIndex: string): Promise<Request[]> {
		console.log(`Fetching requests for sessionId: ${sessionId} index: ${lastRequestIndex}`)
		const response = await axiosInstance.get(`${BASE}/requests/${sessionId}/${lastRequestIndex}`)
		return response.data
	}

}

export { LiveAPI }