import React, { Children, FC, PropsWithChildren } from "react";

type Props = {
    customClass?: string
}

const NavigationTabs: FC<PropsWithChildren<Props>> = ({ children, customClass }) => {

    const arrayChildren = Children.toArray(children);

    return (
        <div className={`${customClass}`}>
            <ul className={`inline-flex gap-2 pb-4 w-full`}>
                {Children.map(arrayChildren, (child, index) => {
                    // const isLast = index === arrayChildren.length - 1;
                    return (
                        <>
                            {child}
                        </>
                    )
                })}
            </ul>
        </div>
    )
}

export { NavigationTabs }