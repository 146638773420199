export enum LogSeverity {
    INFO = "INFO",
    DEBUG = "DEBUG",
    ERROR = "ERROR",
    FAULT = "FAULT"
}

export interface Log {
    id: number
    date:              Date
    severity:          string
    file:              string
    function:          string
    line:              string
    thread:            string
    message:           string
}