import { FC } from 'react'
import { AppType } from '../../../data/model/App'
import { ReactComponent as AppleLogo } from '../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../assets/images/android-logo.svg'

type Props = {
	plateform: AppType
	size?: 'small' | 'default' | 'xl' | '2xl'
	customClass?: string
}

const PlateformLogo: FC<Props> = (props: Props) => {
	return (
		<>
			{props.plateform === AppType.IOS ? <AppleLogo className={`logo ${props.size} ${props.customClass} inline size-4 items-center p`} /> : <AndroidLogo className={`logo ${props.size} ${props.customClass} inline size-4 items-center p`} />}
		</>
	)
}

PlateformLogo.defaultProps = {
	size: 'default',
	plateform: AppType.IOS,
	customClass: ""
}

export { PlateformLogo }
