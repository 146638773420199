import { FC, useCallback, useEffect, useState } from "react"
import { BuildAPI } from "../../../../../data/api/BuildAPI"
import { Crash } from "../../../../../data/model/Crash"
import { useParams } from "react-router-dom"
import { CrashesList } from "../../../../_partials/crashes/CrashesList"
import { BuildCrashesHeader } from "./BuildCrashesHeader"

const BuildCrash: FC = () => {
	const { buildId } = useParams()

	const [crashes, setCrashes] = useState<Crash[]>([])
	const [filteredCrashes, setFilteredCrashes] = useState<Crash[]>([])
	const [selectedCrash, setSelectedCrash] = useState<Crash | undefined>(undefined)
	const [crashToDelete, setCrashToDelete] = useState<Crash | undefined>(undefined)

	const fetchCrashes = useCallback((async () => {
		const buildAPI = new BuildAPI()
		const crashes = await buildAPI.getCrashesForBuild(buildId!)
		setCrashes(crashes)
		setFilteredCrashes(crashes)
	}), [buildId])

	useEffect(() => {
		fetchCrashes()
	}, [fetchCrashes])
	
	return (
		<>
			{
				crashes.length > 0 ? (
					<div className="h-full overflow-y-scroll">
						<div className={`px-6`}>
							<div className="px-6 pt-12">
								<BuildCrashesHeader crashes={crashes} />
								{
									filteredCrashes.length > 0 ? (
										<CrashesList crashes={filteredCrashes} onSelect={setSelectedCrash} onDelete={setCrashToDelete} />
									) : (
										<div className="items-center text-center">
											<div className="text-2xl font-bold mt-5">No crashes match your filters</div>
										</div>

									)
								}

							</div>
						</div>
					</div>
				) : (
					<div className="mt-16 items-center text-center">
						<div className="text-2xl font-bold mt-5">No crashes found</div>
					</div>
				)
			}
		</>)
}

export { BuildCrash }