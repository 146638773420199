import { AndroidMobSF } from "../../../../../../../data/model/MobSF"
import { Badge, BadgeType } from "../../../../../../_components/Badge"
import { MobSFProps } from "../../MobSFProps"
import { SecuritySection } from "../../_components/SecuritySection"

export const CertificateAnalysisCard: React.FC<MobSFProps> = ({ report, expanded }) => {

    const androidReport = report as AndroidMobSF

    return (
        <SecuritySection title="Certificate Analysis" subtitle={"Show what signatures is used for your application"} expanded={expanded}>
            <div className='inline-flex mb-8 space-x-10'>
                <div className='text-center'>
                    <Badge type={BadgeType.ERROR} customClass="px-4 py-2 mb-2" text={androidReport.certificate_analysis.certificate_summary.high ?? 0} />
                    <div className='font-medium'>High</div>
                </div>
                <div className='text-center'>
                    <Badge type={BadgeType.WARNING} customClass="px-4 py-2 mb-2" text={androidReport.certificate_analysis.certificate_summary.warning ?? 0} />
                    <div className='font-medium'>Warning</div>
                </div>
                <div className='text-center'>
                    <Badge type={BadgeType.INFO} customClass="px-4 py-2 mb-2" text={androidReport.certificate_analysis.certificate_summary.info ?? 0} />
                    <div className='font-medium'>Info</div>
                </div>
            </div>
            
                <div className="table-container">
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead >
                            <tr className="fw-bold fs-6 text-gray-800">
                                <th className="pb-2 pr-4">Severity</th>
                                <th className="pb-2 pr-4">About</th>
                                <th className="pb-2 pr-4">Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                androidReport.code_analysis.findings ? (
                                    (() => {
                                        const findings = androidReport.certificate_analysis.certificate_findings
                                        const rows: React.ReactElement[] = [];

                                        for (const finding of findings) {
                                            rows.push(<CertificateAnalyseRow content={finding} />)
                                        }

                                        return rows
                                    })()
                                ) : <></>
                            }
                        </tbody>
                    </table>
                </div>
            
        </SecuritySection>
    )
}

type PermissionProps = {
    content: string[]
}

const CertificateAnalyseRow: React.FC<PermissionProps> = ({ content }) => {

    return (
        <tr>
            <td>
                <Badge type={content[0] as BadgeType} text={content[0]} />
            </td>
            <td><span className="fw-bolder">{content[2]}</span></td>
            <td><span className="fw-bolder">{content[1]}</span></td>
        </tr>
    )
}