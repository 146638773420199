import { CubeTransparentIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Badge, BadgeType } from "../../_components/Badge";

const LivelogPage: FC = ()=> {
	return (
		<div className="p-6">
			<div className="p-4 bg-gray-100 border-1 border-slate-200 rounded-md item space-y-2">
				<div className="flex flex-row justify-between">
					<div className="flex flex-row gap-1 items-center">
						<p className="font-semibold text-lg">Live log is under construction </p>
						<CubeTransparentIcon className="size-5" />
					</div>
					<Badge type={BadgeType.WARNING} text="Comming soon 🚀" />
				</div>
				<p className="text-gray-600">
					Remote debug all your runnings builds with ease by accessing the Live log feature directly by main menu.
					During the beta, access the remote debug feature by navigating to your target build.
				</p>
				<div className="flex flex-row items-center gap-1 pt-6">
					<InformationCircleIcon className="size-5 " />
					<p className="font-bold">
						Please use livelog in a build detail from the store menu item
					</p>
				</div>
			</div>

		</div>
	)
}
	
export { LivelogPage }