import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection"

const Librairies: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="Librairies" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum." expanded={expanded}>
			<div>
				{
						report.libraries ? (

						report.libraries.map((value, index) => (
							<p key={value}><span>{value}</span><br/></p>
						))
					): <></>
				}
			</div>
		</SecuritySection>
	)
}

export { Librairies }