import { FC, useState } from "react";
import { SideMenu } from "./SideMenu";
import { Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";

const MasterLayout: FC = () => {

	const [forceOpenSideMenu, setForceOpenSideMenu] = useState(false);

	return (
		<>
			<button onClick={() => { setForceOpenSideMenu(!forceOpenSideMenu) }} type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
				<span className="sr-only">Open sidebar</span>
				<Bars3BottomLeftIcon className="w-6 h-6" />
			</button>
			<SideMenu forceOpenSideMenu={forceOpenSideMenu} />
			<div onClick={() => { setForceOpenSideMenu(false) }} className="sm:ml-64 h-full overflow-hidden flex flex-col">
				<div className="sticky top-0 bg-white z-50"><Navbar /></div>
				<div className="h-[calc(100vh-5rem)] min-h-[470px]"><Outlet /></div>
			</div>
		</>
	)
}

export { MasterLayout }

