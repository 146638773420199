import { FC, useEffect } from "react"
import { Modal } from "../../../../../_components/Modal"
import { Avatar } from "../../../../../_components/avatar/Avatar"
import { Button } from "../../../../../_components/buttons/Button"
import { RemoveUserViewModel } from "./RemoveUserViewModel"
import { observer } from "mobx-react-lite"

type Props = {
	visible: boolean
	onClose: () => void
	viewModel: RemoveUserViewModel
}

const RemoveUserModal: FC<Props> = observer(({ viewModel, visible, onClose }) => {

	useEffect(() => {
		viewModel.load()
	}, [viewModel])


	const performRemove = () => {
		(async () => {
			await viewModel.removeUsers()
			onClose()
		})()
	}

	return (
		<Modal title={'Manage user'} message={""} visible={visible}
			onClose={onClose}
		>
			<div className="flex flex-col w-full pt-4">
				<p className="text-gray-500 mb-2">Select users to remove from <span className="font-semibold text-gray-900">{viewModel.team?.userGroup.name}</span></p>
				<div className="table-container max-h-52 flex-grow overflow-scroll">

					<table>
						<thead className="sticky top-0 bg-white rounded-md">
							<th></th>
							<th>Name</th>
							<th>Access to</th>
						</thead>
						<tbody className="">
							{
								viewModel.users?.map(user => {
									return (
										<tr>
											<td>
												<input type="checkbox" checked={user.selected} onChange={(e) => viewModel.handleMemberSelection(user.user.id!, e.target.checked)} />
											</td>
											<td>
												<Avatar user={user.user} />
											</td>
											<td className="">
												<div className="flex flex-col">
													<p className="font-bold">{user.user.firstname} {user.user.lastname}</p>
													<p className="">{user.user.email}</p>
												</div>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
				<div className="ml-auto mt-14">
					<Button
						loading={viewModel.isDeleting}
						loadingText="Removing..."
						type="destructive"
						enabled={viewModel.users.filter((u) => u.selected == true).length > 0}
						onClick={() => { if (viewModel.users.filter((u) => u.selected == true).length > 0) performRemove() }}>Remove</Button>
				</div>
			</div>
		</Modal>
	)
})

export { RemoveUserModal }