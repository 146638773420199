import { Issue } from "./Issue"
import { User } from "./User"

export enum BuildStatus {
	NEW = "NEW", // Default status when a build is created and there is no issues
	KO = "KO", // If at leat one issue is NEW
	OK = "OK", // User action to validate test session of all issues are VALIDATED or CLOSED
	TEST_IN_PROGRESS = "TEST_IN_PROGRESS" // Build is under test
}

export enum SecurityScanStatus {
	LOADING = "LOADING",
	PENDING = "PENDING",
	ENDED = "ENDED"
}

export type Build = {
	id: string
	committerId?: string
	committerEmail: string
	customerIssue: string
	version: string
	size: number,
	securityScanStatus: SecurityScanStatus
	buildNumber: string
	commitHash: string
	pipelineId: string
	createdAt: string
	status: string,
	validatorsId: string[]
	deniesId: string[]
	appId: string
	commitMessage: string
	changeLog: string
	branch: string
	binary: string

	// added
	issues: Issue[]
	committer?: User
	testers: User[]
}