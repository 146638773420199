import { App } from '../model/App';
import { Company } from '../model/Company';
import { User } from '../model/User';
import { UserGroup } from '../model/UserGroup';
import { axiosInstance } from '../utils/RequestUtils';

const BASE = `/users`

export class UserAPI {

	async getUserByID(userId: string): Promise<User> {
		const response = await axiosInstance.get(`${BASE}/${userId}`)
		return response.data
	}

	async updateProfile(userId: string, user: Partial<User>, avatar?: File | undefined) {

		const formData = new FormData();
		if (user.firstname) { formData.append('firstname', user.firstname) }
		if (user.lastname) { formData.append('lastname', user.lastname) }
		if (user.username) { formData.append('username', user.username) }
		if (user.securityCode) { formData.append('securityCode', user.securityCode) }
		if (avatar) { formData.append('file', avatar) }

		const response = await axiosInstance.patch(`${BASE}/${userId}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
		return response.data
	}

	async getCompaniesForUser(userId: string): Promise<Company[]> {
		const response = await axiosInstance.get(`${BASE}/${userId}/companies`)
		return response.data
	}

	async getAppForUser(userId: string, companyId: string): Promise<App[]> {
		const response = await axiosInstance.get(`${BASE}/${userId}/${companyId}/apps`)
		return response.data
	}

	async getGroupsForUser(userId: string): Promise<UserGroup[]> {
		const response = await axiosInstance.get(`${BASE}/${userId}/usergroups`)
		return response.data
	}

	async inviteUser(companyId: string, email: string, teamId: string): Promise<UserGroup> {
		const response = await axiosInstance.post(`${BASE}`, { email: email, teamid: teamId });
		return response.data
	}

	async inviteUserInMultilpleTeams(companyId: string, email: string, teamIds: string[]): Promise<UserGroup> {
		const response = await axiosInstance.post(`${BASE}/invite`, { email: email, teamids: teamIds });
		return response.data
	}


	async delete(userId: string): Promise<UserGroup> {
		const response = await axiosInstance.delete(`${BASE}/${userId}`);
		return response.data
	}
}
