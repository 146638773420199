import { Severity } from "../../../../../../data/model/MobSF";
import { Badge, BadgeType } from "../../../../../_components/Badge";
import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection";
import { Tooltip } from "../../../../../_components/Tooltip";
import { BugAntIcon } from '@heroicons/react/24/outline'

const BinaryCodeAnalysis: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="Binary Code Analysis" subtitle="Binary code analysis searches your application's compiled code for flaws that could potentially be exploited by an attacker." expanded={expanded}>
			<div>
				<div className='inline-flex mb-8 space-x-10'>
					<div className='text-center'>
						<Badge type={BadgeType.ERROR} customClass="px-4 py-2 mb-2" text={report.binary_analysis.summary.high ?? 0 } />
						<div className='font-medium'>High</div>
					</div>
					<div className='text-center'>
						<Badge type={BadgeType.WARNING} customClass="px-4 py-2 mb-2" text={report.binary_analysis.summary.warning ?? 0 } />
						<div className='font-medium'>Warning</div>
					</div>
					<div className='text-center'>
						<Badge type={BadgeType.INFO} customClass="px-4 py-2 mb-2" text={report.binary_analysis.summary.info ?? 0 } />
						<div className='font-medium'>Info</div>
					</div>
					<div className='text-center'>
						<Badge type={BadgeType.SUCCESS} customClass="px-4 py-2 mb-2" text={report.binary_analysis.summary.secure ?? 0 } />
						<div className='font-medium'>Secure</div>
					</div>
					<div className='text-center'>
						<Badge type={BadgeType.PURPLE} customClass="px-4 py-2 mb-2" text={report.binary_analysis.summary.suppressed ?? 0 } />
						<div className='font-medium'>Suppressed</div>
					</div>
				</div>
			</div>
			<div className="table-container">
				<table>
				<thead >
					<tr>
						<th>Issue</th>
						<th>Severity</th>
						<th>Standards</th>
						<th>Description</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
				{
					report?.binary_analysis.findings ? (
						(() => {
							const findings = Object.entries(report.binary_analysis.findings)
							const rows: React.ReactElement[] = [];
							for (const [key, value] of findings) {
								rows.push(<CodeAnalyseRow key={key} name={key} severity={ ((value as unknown) as Severity) } />)
							}

							return rows
						})()
					): <></>
				}
				</tbody>
				</table>
			</div>
		</SecuritySection>
	)
}

type PermissionProps = {
	name: string
	severity: Severity
}

const CodeAnalyseRow: React.FC<PermissionProps> = ({ name, severity }) => {

	return (   
		<tr>
			<td><span className="font-bold">{ name }</span></td>
			<td>
				<Badge type={BadgeType.ERROR} text={severity.severity} />
			</td>
			<td>
				<span className="font-bold">CWE: </span>{severity.cwe}<br/>
				<span className="font-bold">OWASP Top 10: </span>{severity["owasp-mobile"]}<br/>
				<span className="font-bold">OWASP MASVS: </span>{severity.masvs}<br/>
				<span className="font-bold">CVSS: </span>{severity.cvss}<br/>
			 </td>
			<td>{severity.detailed_desc}</td>
			<td className="text-right">
				<Tooltip content={<span>Declare an issue</span>}>
					<button className="create-issue border-1 rounded-md p-3 hover:bg-gray-50">
						<BugAntIcon className="size-4" />
					</button>
				</Tooltip>
			</td>
			</tr>
	)
}

export { BinaryCodeAnalysis }