import { FC } from "react"
import { _BaseButtonProps } from "./buttons/Button"

type Props = _BaseButtonProps & {
	style: "neutral" | "success" | "info" | "error" | "fatal"
}

const OutlineButton: FC<Props> = ({ style, text, enabled, onClick }) => {

	const styles = () => {
		switch (style) {
			case "neutral":
				return "bg-gray-50 text-gray-800 ring-1 ring-inset ring-gray-800/10 hover:bg-gray-100 hover:text-gray-700 hover:ring-gray-700/10"

			case "success":
				return "bg-green-50 text-green-700 ring-1 ring-inset ring-green-600/20 hover:bg-green-100 hover:text-green-700 hover:ring-green-700/10"

			case "info":
				return "bg-blue-50 text-blue-700 ring-1 ring-inset ring-blue-700/10  hover:bg-blue-100 hover:text-blue-700 hover:ring-blue-700/10"

			case "error":
				return "bg-red-50 text-red-700 ring-1 ring-inset ring-red-600/10  hover:bg-red-100 hover:text-red-700 hover:ring-red-700/10"

			case "fatal":
				return "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-700/10  hover:bg-purple-100 hover:text-purple-700 hover:ring-purple-700/10"
			default: 
				return "bg-gray-50 text-gray-600 ring-1 ring-inset ring-gray-500/10  hover:bg-gray-100 hover:text-gray-700 hover:ring-gray-700/10"
		}
	}
	return (
		<button className={`items-center rounded-md px-2 text-sm py-1 ${enabled ? " opacity-100" : " opacity-10"} font-bold ${styles()}`} onClick={  (e) => onClick!(e) }>{ text }</button>
	)

}

export { OutlineButton }