import { FC, useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../../../hooks/useAuth'
import { App, AppType } from '../../../../data/model/App'
import { UserAPI } from '../../../../data/api/UserAPI'
import { AppForm } from './app/AppForm'
import AppFormViewModel from './app/AppFormViewModel'
import { Button } from '../../../_components/buttons/Button'
import { TextInput } from '../../../_components/TextInput'
import { Badge, BadgeType } from '../../../_components/Badge'
import { PencilSquareIcon, SquaresPlusIcon } from '@heroicons/react/24/outline'
import { AppAvatar } from '../../../_components/AppsAvatar'
import { ReactComponent as AppleLogo } from '../../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../../assets/images/android-logo.svg'
import { ButtonGroup } from '../../../_components/ButtonGroup'
import { Tooltip } from '@material-tailwind/react'
import { Modal } from '../../../_components/Modal'

const Apps: FC = () => {

	const { workspace } = useAuth()
	const [apps, setApps] = useState<App[]>()
	const [selectedApp, setSelectedApp] = useState<App>()

	const [isAppModalVisible, setIsAppModalVisible] = useState<{ visible: boolean; app: App | undefined }>({ visible: false, app: undefined });


	const getApps = useCallback(async () => {
		const userAPI = new UserAPI()
		if (workspace != null) {
			const apps = await userAPI.getAppForUser("me", workspace.id)
			setApps(apps)
		}

	}, [workspace])

	useEffect(() => {
		if (!isAppModalVisible.visible) {
			console.log("Use effect called getApps")
			getApps()
		}
	}, [getApps, workspace, isAppModalVisible]);

	useEffect(() => {
		console.log("Use effect called setSelectedApp")
		if (selectedApp == undefined) {
			setSelectedApp(apps?.[0])
		}
	}, [apps, setSelectedApp]);

	return (
		<>
			<div className='px-6 h-full overflow-y-scroll'>
				{
					(apps && apps.length > 0) ? (
						<>
							<p className='mt-4 mb-2 font-extrabold text-lg'>Manage your apps</p>
							<div className="flex justify-between items-center mt-6">
								<TextInput name="Search" placeholder="Search..." />
								<Button type='dark' text={"New app"} onClick={() => setIsAppModalVisible({ visible: true, app: undefined })} customClass="mb-3">
									<span className="flex flex-row items-center gap-2 ">
										<SquaresPlusIcon className="size-4" />
										New app
									</span>
								</Button>
							</div>
							<div className="table-container">
								<table>
									<thead>
										<tr>
											<th>Name</th>
											<th className='w-10'>Plateform</th>
											<th>Bundle ID</th>
											<th>Application ID</th>
											<th className="w-20">Actions</th>
										</tr>
									</thead>
									<tbody>
										{
											apps.map(app => (
												<tr key={app.id}>
													<td>
														<div className='flex flex-row items-center gap-1'>
															<AppAvatar app={app} />
															<p className='font-semibold'>{app.name}</p>
														</div>
													</td>
													<td align='center'>{app.platform === AppType.IOS ? <AppleLogo className="inline size-4 items-center" /> : <AndroidLogo className="inline size-4 items-center" />}</td>
													<td><Badge type={BadgeType.NEUTRAL} text={app.bundleId}></Badge></td>
													<td><p><Tooltip content="Application ID is used by your CICD to identify uploaded APK & IPA">{app.id}</Tooltip></p></td>

													<td align='right'>
														<ButtonGroup>
															<Tooltip content="Edit application">
																<PencilSquareIcon className="size-4" onClick={() => setIsAppModalVisible({ visible: true, app: app })} />
															</Tooltip>
														</ButtonGroup>
													</td>
												</tr>
											))
										}
									</tbody>
								</table>
							</div>
						</>
					) : (
						<div className="flex flex-col justify-center items-center h-full">
							<div className="p-7 bg-gradient-to-b from-slate-100 to-transparent rounded-full">
								<SquaresPlusIcon className="size-20" />
							</div>
							<div className="flex flex-col justify-center items-center mt-6">
								<p className="font-extrabold text-5xl mb-3">Create your first app 🚀</p>
								<p className="text-gray-500 text-center max-w-[600px] mb-5">Your workspace is empty! Get started by creating your first application and ake control of your mobile applications with powerful management tools.</p>
								<Button type="dark" size="lg" onClick={() => { }}>Create my first app</Button>
							</div>
						</div>
					)
				}
			</div>
			<Modal title={isAppModalVisible.app?.name ?? ""} message={""} visible={isAppModalVisible.visible} onClose={() => setIsAppModalVisible({ visible: false, app: undefined })}>
				<AppForm viewModel={new AppFormViewModel(isAppModalVisible.app, () => setIsAppModalVisible({ visible: false, app: undefined }))} />
			</Modal>
		</>
	)
}

export { Apps }