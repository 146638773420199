import { FC, useCallback, useEffect, useState } from "react"
import { EnvelopeIcon, HandRaisedIcon, PencilSquareIcon, TrashIcon, UserGroupIcon, UserPlusIcon, UsersIcon  } from "@heroicons/react/24/outline" 
import { Modal } from "../../../../../_components/Modal"
import { User } from "../../../../../../data/model/User"
import { UserGroupsAPI } from "../../../../../../data/api/UserGroupsAPI"
import { ButtonGroup } from "../../../../../_components/ButtonGroup"
import { Tooltip } from "../../../../../_components/Tooltip"
import { Avatar } from "../../../../../_components/avatar/Avatar"
import { Button } from "../../../../../_components/buttons/Button"
import { UserGroup, UserGroupType } from "../../../../../../data/model/UserGroup"
import { TeamExtended } from "../../TeamsViewModel"
import { CompanyAPI } from "../../../../../../data/api/CompanyAPI"
import { useAuth } from "../../../../../../hooks/useAuth"
import { Label } from "../../../../../_components/Label"
import { TextInput } from "../../../../../_components/TextInput"
import { AddUserViewModel } from "./AddUserViewModel"
import { observer } from "mobx-react-lite"

type Props = {
	visible: boolean
	onClose: () => void
	viewModel: AddUserViewModel
}

const AddUserModal: FC<Props> = observer( ({viewModel, visible, onClose}) => {

	const [selectedOption, setSelectedOption] = useState<'newUser' | 'existingUser'>('newUser');

	useEffect(() => {
		viewModel.load()
	}, [viewModel])


	const performInvitation = () => {
		( async() => {
			if (selectedOption == "newUser") {
				await viewModel.performInvitationFromEmail()
			}
			else {
				await viewModel.performInvitationFroUsers()
			}
			onClose()
		})()
	}

	return (
		<Modal title={'Add member to team'} message={""} visible={visible}
			onClose={ onClose }
			>
			<div className="flex flex-col w-full pt-4">

			<div className="font-normal space-y-2 my-2 mb-6">
				<label className="flex flex-row gap-2">
					<input type="radio" value="option1" checked={selectedOption === 'newUser'} onChange={() => setSelectedOption('newUser')} />
					<p className="">Invite a new member</p>
				</label>
				<label className="flex flex-row gap-2">
					<input type="radio" value="option2" checked={selectedOption === 'existingUser'} onChange={() => setSelectedOption('existingUser')} />
					Add existing member
				</label>
			</div>
			{

				selectedOption == "newUser" 
				? (<div>
						<Label htmlFor={""} label={"User email"}/>
						<TextInput name="email" placeholder="john@mail.com" size="xl" customClass="mt-1" onChange={ (e) => viewModel.setEmail(e.target.value) }/>
							{ viewModel.emailError != null && (
        						<p className="text-xs mt-1 text-red-500 max-w-96">{ viewModel.emailError }</p>
        					)}
						<p className="text-xs  text-gray-500 mt-3">New member will receive invitation via email, and need to accepte it to join the {viewModel.team?.userGroup.type == UserGroupType.WORKSPACE ? "workspace" : "team"}</p>
						<div className="mt-3 space-x-2">
						
						</div>
					</div>
					)
				:( <>
						{
								viewModel.users.length == 0 
								? (
									<p className="bg-slate-100 rounded p-2">All existing users in the workspace are already invited in this team.</p>
								) 
								:(
									<div className="table-container max-h-52 flex-grow overflow-scroll">
						<table>
							<thead className="sticky top-0 bg-white rounded-md">
								<th></th>
								<th>Name</th>
								<td></td>
							</thead>
								<tbody className="">
								{
									viewModel.users?.map(user => {
										return (
											<tr>
												<td>
													<input type="checkbox" checked={user.selected} onChange={ (e) => viewModel.handleMemberSelection(user.user.id!, e.target.checked) }  />
												</td>
												<td>
													<Avatar user={user.user} />
												</td>
												<td className="">
													<div className="flex flex-col">
													<p className="font-bold">{user.user.firstname} {user.user.lastname}</p>
													<p className="">{user.user.email}</p>
													</div>
												</td>
											</tr>
										)
									})
								}
							</tbody>							
						</table>
					</div>
								)
						}
					
					</>
				)
			}
			<div className="ml-auto mt-14">
				<Button size="lg" 
					enabled= { selectedOption == "newUser" ? (viewModel.emailError == null && viewModel.email != '') : viewModel.isUserSelectionValid }
					loading={viewModel.isInviting} 
					loadingText="Inviting..." 
					onClick={ performInvitation }
					>
					{
						selectedOption == "newUser" ? "Send invite" : "Add member"
					}
				</Button>
			</div>
		</div>
			
		</Modal>
	)
})

export { AddUserModal }