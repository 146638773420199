/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { Label } from './Label'

type Props = {
	name: string
	label: string
	initialeImage?: string | undefined
	onImageUpload: (file: File) => void
	customClass?: string
}

const ImageInput: FC<Props> = ({ name, initialeImage, customClass, label, onImageUpload }) => {

	const [image, setImage] = useState<string | undefined>(initialeImage)
	const fallbackImage = 'https://placehold.co/150x150@2x.png'

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImage(reader.result as string); // Display the uploaded image
			};
			reader.readAsDataURL(file); // Read the file
			onImageUpload(file); // Notify the parent with the uploaded file		
		}
	}

	return (
		<div className={`mb-7 ${customClass} `}>
			<div className="flex">
				<img
					src={image}
					className="size-28 bg-cover bg-center object-cover rounded-md border-1 border-gray-300"
					onError={() => setImage(fallbackImage)}
				/>
				<div className='flex flex-col ml-3 gap-2'>
					<Label style="md" htmlFor={name} label={label} />
					<input type="file" accept="image/*" onChange={handleImageChange} />
					<p className='text-gray-400 max-w-md text-sm'>The ideal image size is 192 x 192 pixels. The maximum file size allowed is 200 KiB.</p>
				</div>
			</div>
		</div>
	)
}

export { ImageInput }
