import { MobSFProps } from "../MobSFProps"
import { MobSFSeverityBadge } from "./MobSFSeverityBadge";
import { SecuritySection } from "../_components/SecuritySection";
import { Tooltip } from "../../../../../_components/Tooltip";
import { BugAntIcon } from '@heroicons/react/24/outline'


const MachOAnalysis: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="MachO Analysis" subtitle="Binary code analysis searches your application's compiled code for flaws that could potentially be exploited by an attacker." expanded={expanded}>
			<div className="table-container">
			<table>
				<thead>
					<tr>
						<th>Protection</th>
						<th>Status</th>
						<th>Severity</th>
						<th>Description</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
				{
					report?.macho_analysis ? (
						(() => {

							const rows: React.ReactElement[] = [];
							// ARC
							if (report.macho_analysis.arc) {
								const arc = report.macho_analysis.arc
								rows.push(<Mach0Row key="ARC" protection="ARC" status={ arc.has_arc ? "True" : "False" }  severity={ arc.severity } description={ arc.description } />)
							}
							
							// Code Signature
							if (report.macho_analysis.code_signature) {
								const code_signature = report.macho_analysis.code_signature
								rows.push(<Mach0Row key="CS" protection="Code Signature" status={ code_signature.has_code_signature ? "True" : "False"}  severity={ code_signature.severity } description={ code_signature.description } />)
							}

							// Encrypted
							if (report.macho_analysis.encrypted) {
								const encrypted = report.macho_analysis.encrypted
								rows.push(<Mach0Row key="Encrypted" protection="Encrypted" status={ encrypted.is_encrypted ? "True" : "False" }  severity={ encrypted.severity } description={ encrypted.description } />)
							}

							// NX
							if (report.macho_analysis.nx) {
								const nx = report.macho_analysis.nx
								rows.push(<Mach0Row key="NX" protection="NX" status={nx.has_nx ? "True" : "False"}  severity={ nx.severity } description={ nx.description } />)
							}

							// PIE
							if (report.macho_analysis.pie) {
								const pie = report.macho_analysis.pie
								rows.push(<Mach0Row key="PIE" protection="PIE" status={pie.has_pie ? "True" : "False"}  severity={ pie.severity } description={ pie.description } />)
							}

							// RPATH
							if (report.macho_analysis.rpath) {
								const rpath = report.macho_analysis.rpath
								rows.push(<Mach0Row key="RPATH" protection="RPATH" status={ rpath.has_rpath ? "True" : "False"}  severity={ rpath.severity } description={ rpath.description } />)
							}

							// Stack canary
							if (report.macho_analysis.stack_canary) {
								const stack_canary = report.macho_analysis.stack_canary
								rows.push(<Mach0Row key="SC" protection="Stack Canary" status={ stack_canary.has_canary ? "True" : "False"}  severity={ stack_canary.severity } description={ stack_canary.description } />)
							}

								// Symbol Stripped
								if (report.macho_analysis.symbol) {
								const symbol = report.macho_analysis.symbol
								rows.push(<Mach0Row key="SS" protection="Symbols stripped" status={ symbol.is_stripped ? "True" : "False"}  severity={ symbol.severity } description={ symbol.description } />)
							}
							return rows
						})()
					): <></>
				}
				</tbody>
			</table>
			</div>
		</SecuritySection>
	)
}

type MachOProps = {
	protection: string
	status: string 
	severity: string 
	description: string
}

const Mach0Row: React.FC<MachOProps> = ({ protection, status, severity, description }) => {

	return (   
		<tr>
			<td><span className="font-bold">{ protection }</span></td>
			<td>{ status }</td>
			<td><MobSFSeverityBadge key={protection} severity={ severity }/></td>
			<td>{ description }</td>
			<td className="text-right">
				<Tooltip content={<span>Declare an issue</span>}>
					<button className="create-issue border-1 rounded-md p-3 hover:bg-gray-50">
						<BugAntIcon className="size-4" />
					</button>
				</Tooltip>
			</td>
		</tr>
	)
}

export { MachOAnalysis }