import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";

type Props = {
    title: string, 
    to: string
}

const NavigationTab: FC<Props> = ( {title, to }) => {

    const {pathname} = useLocation()
	


    const getCurrentUrl = (pathname: string) => {
	    return pathname.split(/[?#]/)[0]
    }

    const checkIsActive =(pathname: string, url: string) => {
        const current = getCurrentUrl(pathname)
        if (!current || !url) {
            return false
        }

        if (current === url) {
            return true
        }

        if (current.indexOf(url) > -1) {
            return true
        }

        return false
    }

    const isActive = checkIsActive(pathname, to)


    return (
        
        <li className={`px-3 py-1 rounded-3xl font-bold text-lg hover:bg-black hover:text-white ${ isActive ? "text-white bg-black" : ""}`} id={title}>
            <Link to={to}>
            <p aria-current="page">{title}</p>
            </Link>
        </li>
    )
}

export { NavigationTab }