import { FC, useState } from "react"
import './AppCard.css'
import { App, AppType } from "../../../../../../data/model/App"
import { ReactComponent as AppleLogo } from '../../../../../../assets/images/apple-logo.svg'
import { ReactComponent as AndroidLogo } from '../../../../../../assets/images/android-logo.svg'
import { Link } from "react-router-dom"
import { AppAvatar } from "../../../../../_components/AppsAvatar"

type Props = {
	app: App
}

const AppCard: FC<Props> = ({ app }) => {

	return (
		<Link to={`app/${app.id}`}>
			<div className="card border-1 border-[#E4E6EF] p-6 rounded-lg">
				<div className="flex flex-row space-x-4 items-center">
					<AppAvatar app={app} size="lg" />
					<div>
						<div className="flex items-center">
							<h3 className="font-bold text-xl me-1">{app.name}</h3>
							{app.platform === AppType.IOS ? <AppleLogo className="size-4 mb-1" /> : <AndroidLogo className="size-4" />}
						</div>
						<p className="text-sm font-medium text-gray-400">{app.bundleId}</p>
					</div>
				</div>
			</div>
		</Link>
	)
}

export { AppCard }