import { Email } from "../../../../../../data/model/MobSF";
import { MobSFProps } from "../MobSFProps"
import { SecuritySection } from "../_components/SecuritySection";

const Emails: React.FC<MobSFProps> = ({ report, expanded }) => {
	
	return (
		<SecuritySection title="Emails" subtitle="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum." expanded={expanded}>
			<div className="table-container">
			<table>
				<thead>
					<tr>
						<th>Email</th>
						<th>File</th>
					</tr>
				</thead>
				<tbody>
				{
					report.emails ? (
						(() => {
							const rows: React.ReactElement[] = [];
							for (const email of report.emails) {
								rows.push(<EmailRow email={email} />)
							}
							return rows
						})()
					): <></>
				}
				</tbody>
			</table>
			</div>
		</SecuritySection>
	)
}

type EmailRowProps = {
	email: Email
}

const EmailRow: React.FC<EmailRowProps> = ({ email }) => {
	
	return (   
		<tr>
			<td>
			{email.emails.map((value, index) => (
				<p key={index}><span>{value}</span><br/></p>
			))}
			</td>           
			<td>{ email.path }</td>
		</tr>
	)
}

export { Emails }