import { FC, useCallback, useEffect, useState } from "react"
import { DropDownField, DropDownItem } from "../../../_components/Dropdown"
import { LiveAPI } from "../../../../data/api/LiveAPI"
import { useParams } from "react-router-dom"
import { Device } from "../../../../data/model/Device"
import { LogContainer } from "./_components/LogContainer"
import { NetworkContainer } from "./_components/NetworkContainer"

export enum LiveDebugType {
	LOGS, NETWORK
}

type Props = {
	liveDebugType: LiveDebugType
}

const BuildLive: FC<Props> = ({ liveDebugType }) => {

	const { buildId } = useParams()

	const [devices, setDevices] = useState<Device[]>()
	const [activateSessionId, setActivateSessionId] = useState<string | undefined>()

	/// Fetch all attached device to the current build
	const fetchDevices = useCallback((async () => {
		const logAPI = new LiveAPI()
		const response = await logAPI.devices(buildId!)
		setDevices(response)
		console.log(`Session active: ${activateSessionId}, receive device: ${response}`)
		if (activateSessionId && (response.map((d) => d.sessionId).includes(activateSessionId) == false || response.length == 0)) {
			setActivateSessionId(undefined)
		}
		console.log(JSON.stringify(response))
	}), [buildId, activateSessionId])

	/**
	 *  User select a device, we have to activate remote logging 
	 *  and then device will be authorized to send its logs
	 */
	const onSelectDevice = useCallback((async (sessionId: string) => {
		const logAPI = new LiveAPI()
		const response = await logAPI.activate(buildId!, sessionId)
		if (response.active) {
			console.log(`Activate log for session ${sessionId}  succedeed`)
			setActivateSessionId(sessionId)
		}
	}), [buildId])

	useEffect(() => {

		if (buildId !== undefined) {
			fetchDevices();
		}
		const intervalCall = setInterval(() => {
			if (buildId !== undefined) {
				fetchDevices();
			}
		}, 2000);
		return () => {
			// clean up
			clearInterval(intervalCall);
		};

	}, [buildId, fetchDevices]);


	var device = devices?.find((device) => { return device.sessionId == activateSessionId })
	var selectdStr = undefined
	if (device) {
		selectdStr = `[${device.brand}] ${device.name} - os: ${device.version} #S${device.sessionId}`
	}

	return (
		<div id="up_anchor" className="h-full flex flex-col">
			<div id="header">
				<p className="text-gray-400 pb-4">Logs are retreived every 2 seconds. If nothing is displayed, check your device's connectivity.</p>
				<p className="pb-4">Select a device attached to your build to see logs</p>
				<DropDownField key={activateSessionId} customClass="mb-4" defaultValue={activateSessionId} emptyMessage="No device connected" onSelect={(value) => { onSelectDevice(value) }}>
					{
						devices?.map((device) => {
							return <DropDownItem title={`[${device.brand}] ${device.name} - os: ${device.version} #S${device.sessionId} `} value={device.sessionId!} key={device.sessionId!} />
						})
					}
				</DropDownField>
			</div>
				{
					liveDebugType == LiveDebugType.LOGS && (
						<LogContainer buildId={buildId!} sessionId={activateSessionId} />
					)
				}
				{
					liveDebugType == LiveDebugType.NETWORK && (
						<NetworkContainer sessionId={activateSessionId} />
					)
				}
			</div>
	)
}

export { BuildLive }