import React, { PropsWithChildren, useState } from 'react';

const Collapsible: React.FC<PropsWithChildren<{ title: string, titleWhileClose?: string, isOpenByDefault?: boolean }>> = ({ title, titleWhileClose, isOpenByDefault, children }) => {

  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <button
        onClick={toggleCollapse}
        className="flex justify-between items-center text-left focus:outline-none"
      >
        {
          (titleWhileClose && !isOpen) ? (
            <span>{titleWhileClose}</span>
          ) : <span>{title}</span>
        }
        <svg
          className={`w-4 h-4 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''
            }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ${isOpen ? '' : 'max-h-0'}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
