
import { FC, useState } from "react";
import { Button } from "./presentation/_components/buttons/Button";
import { Badge, BadgeType } from "./presentation/_components/Badge";
import { DropDownField, DropDownItem } from "./presentation/_components/Dropdown";
import { ImageInput } from "./presentation/_components/ImageInput";
import { Loader } from "./presentation/_components/Loader";
import { Tooltip } from "./presentation/_components/Tooltip";
import { QuestionMarkCircleIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { TextInput } from "./presentation/_components/TextInput";
import { NavigationTabs } from "./presentation/_components/NavigationTabs/Tabs";
import { NavigationTab } from "./presentation/_components/NavigationTabs/Tab";
import { Avatar } from "./presentation/_components/avatar/Avatar";
import { User } from "./data/model/User";
import { Logo } from "./presentation/_components/logo/logo";
import Collapsible from "./presentation/_components/Collapsible";
import { Alert } from "./presentation/_components/Alert";
import { Label } from "./presentation/_components/Label";
import { Modal } from "./presentation/_components/Modal";
import { ButtonGroup } from "./presentation/_components/ButtonGroup";
import { Carousel } from "./presentation/_components/Carousel";
import TokenField from "./presentation/_components/TokenField";
import { Tabs } from "./presentation/_components/tabs/Tabs";
import { Tab } from "./presentation/_components/tabs/Tab";
import { CopyToClipboardInput } from "./presentation/_components/CopyToClipboard";

const Components: FC = () => {

	const [alert1, setAlert1] = useState(false);
	const [alert2, setAlert2] = useState(false);
	const [alert3, setAlert3] = useState(false);
	const [alert4, setAlert4] = useState(false);

	const [modal1, setModal1] = useState(false);
	const [modal2, setModal2] = useState(false);
	const [modal3, setModal3] = useState(false);
	const [modal4, setModal4] = useState(false);

	const [alertInModal, setAlertInModal] = useState(false);

	const [carousel1, setCarousel1] = useState(false);

	return (


		<div className="w-full h-full overflow-scroll p-8">
			{/* Logo */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Logo</p>
				<div className="flex flex-row gap-8 mt-6">
					<Logo size="small" />
					<Logo />
					<Logo size="xl" />
					<Logo size="2xl" />
					<Logo size="small" iconOnly={true} />
					<Logo iconOnly={true} />
					<Logo size="xl" iconOnly={true} />
					<Logo size="2xl" iconOnly={true} />
				</div>
			</div>

			<div>
				<TokenField onTokensChange={(e) => console.log(e)} />
			</div>

			{/* Button */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4">Buttons</p>
				{/* Primary */}
				<p className="text-lg font-bold pb-2 mt-7">Primary</p>
				<div className="flex flex-row gap-8">
					<div>
						<p className="font-semibold text-sm pb-2">small</p>
						<div className="grid gap-2">
							<Button type="primary" size="sm" text={"Next"} onClick={console.log} />
							<Button loading={true} type="primary" size="sm" text={"Next"} onClick={console.log} />
							<Button type="primary" enabled={false} size="sm" text={"Next"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">default</p>
						<div className="grid gap-2">
							<Button type="primary" text={"Next"} onClick={console.log} />
							<Button loading={true} type="primary" text={"Next"} loadingText={"Loading..."} onClick={console.log} />
							<Button type="primary" enabled={false} text={"Next"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">large</p>
						<div className="grid gap-2">
							<Button type="primary" size="lg" text={"Next"} onClick={console.log} />
							<Button loading={true} type="primary" size="lg" text={"Next"} onClick={console.log} />
							<Button type="primary" enabled={false} size="lg" text={"Next"} onClick={console.log} />
						</div>
					</div>
				</div>
				{/* Secondary */}
				<p className="text-lg font-bold pb-2 mt-7">Secondary</p>
				<div className="flex flex-row gap-8 ">

					<div>
						<p className="font-semibold text-sm pb-2">small</p>
						<div className="grid gap-2">
							<Button type="secondary" size="sm" text={"Cancel"} onClick={console.log} />
							<Button type="secondary" enabled={false} size="sm" text={"Cancel"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">default</p>
						<div className="grid gap-2">
							<Button type="secondary" text={"Cancel"} onClick={console.log} />
							<Button type="secondary" enabled={false} text={"Cancel"} onClick={console.log} />
						</div>
					</div>
					<div className="grid gap-2">
						<p className="font-semibold text-sm pb-2">large</p>
						<div className="grid gap-2">
							<Button type="secondary" size="lg" text={"Cancel"} onClick={console.log} />
							<Button type="secondary" enabled={false} size="lg" text={"Cancel"} onClick={console.log} />
						</div>
					</div>
				</div>
				{/* Desctructive */}
				<p className="text-lg font-bold pb-2 mt-7">Destructive</p>
				<div className="flex flex-row gap-8">

					<div>
						<p className="font-semibold text-sm pb-2"> small</p>
						<div className="grid gap-2">
							<Button type="destructive" size="sm" text={"Delete"} onClick={console.log} />
							<Button type="destructive" enabled={false} size="sm" text={"Delete"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">default</p>
						<div className="grid gap-2">
							<Button type="destructive" text={"Delete"} onClick={console.log} />
							<Button type="destructive" enabled={false} text={"Delete"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">large</p>
						<div className="grid gap-2">
							<Button type="destructive" size="lg" text={"Delete"} onClick={console.log} />
							<Button type="destructive" enabled={false} size="lg" text={"Delete"} onClick={console.log} />
						</div>
					</div>
				</div>
				{/* Dark */}
				<p className="text-lg font-bold pb-2 mt-7">Dark</p>
				<div className="flex flex-row gap-8">

					<div>
						<p className="font-semibold text-sm pb-2">Dark (small)</p>
						<div className="grid gap-2">
							<Button type="dark" size="sm" text={"Delete"} onClick={console.log} />
							<Button loading={true} type="dark" size="sm" text={"Next"} onClick={console.log} />
							<Button type="dark" enabled={false} size="sm" text={"Next"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">default</p>
						<div className="grid gap-2">
							<Button type="dark" text={"Next"} onClick={console.log} />
							<Button loading={true} type="dark" text={"Next"} onClick={console.log} />
							<Button type="dark" enabled={false} text={"Next"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">large</p>
						<div className="grid gap-2">
							<Button type="dark" size="lg" text={"Next"} onClick={console.log} />
							<Button loading={true} type="dark" size="lg" text={"Next"} onClick={console.log} />
							<Button type="dark" enabled={false} size="lg" text={"Next"} onClick={console.log} />
						</div>
					</div>
				</div>
				
				<p className="text-lg font-bold pb-2 mt-7">Loading</p>
				<div className="flex flex-row gap-8">

					<div>
						<p className="font-semibold text-sm pb-2">Dark (small)</p>
						<div className="grid">
							<Button text="Confirm " onClick={undefined} loading={true} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">default</p>
						<div className="grid">

						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">large</p>
						<div className="grid">

						</div>
					</div>
				</div>
			</div>

			{/* Outlined Button */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1  mt-4">Outline buttons: todo </p>
				<p className="text-lg font-bold pb-2 mt-7">Primary</p>
				{/* Primary */}
				<div className="flex flex-row gap-8">
					<div>
						<p className="font-semibold text-sm pb-2">small</p>
						<div className="grid gap-2">
							<Button type="primary" size="sm" text={"Next"} onClick={console.log} />
							<Button type="primary" enabled={false} size="sm" text={"Next"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">default</p>
						<div className="grid gap-2">
							<Button type="primary" text={"Next"} onClick={console.log} />
							<Button type="primary" enabled={false} text={"Next"} onClick={console.log} />
						</div>
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">large</p>
						<div className="grid gap-2">
							<Button type="primary" size="lg" text={"Next"} onClick={console.log} />
							<Button type="primary" enabled={false} size="lg" text={"Next"} onClick={console.log} />
						</div>
					</div>
				</div>
			</div>
			{/* Button Group */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mb-4">Button Group</p>
				<div className="flex flex-row items-center gap-6">
					<p className="font-semibold">mode=select</p>
					<ButtonGroup onClick={console.log} >
						<p>100</p>
						<p>200</p>
						<p>300</p>
						<p>400</p>
					</ButtonGroup>
				</div>
				<div className="flex flex-row items-center gap-6">
					<p className="font-semibold">mode=multi</p>
					<ButtonGroup onClick={console.log} mode="multi" customClass="mt-4">
						<p>100</p>
						<p>200</p>
						<p>300</p>
						<p>400</p>
					</ButtonGroup>
				</div>
				<div className="flex flex-row items-center gap-6">
					<p className="font-semibold">mode=click</p>
					<ButtonGroup customClass="mt-4" mode="click" onClick={console.log}>
						<ChevronLeftIcon className="size-4" />
						<ChevronRightIcon className="size-4" />
					</ButtonGroup>
				</div>
			</div>
			{/* Modal */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4">Modal</p>

				<div className="flex flex-col gap-3 pt-4">
					<div className="flex flex-row gap-3">
						<p>Simple modal with a view</p>
						<Button text="Show" onClick={() => { setModal1(true) }} />
						<Modal
							title={"Modal title"}
							message={"Modal message"}
							visible={modal1}
							onClose={() => { setModal1(false) }}
						>
							<div className="bg-slate-100 rounded-md p-2 mb-1">
								This is the content of the modal.
							</div>
							<p>You can add wathever you want inside this modal </p>
							<div className="flex flex-row gap-2 justify-end">
								<Button type="secondary" text={"Next"} onClick={() => { setModal1(false) }} />
								<Button type="dark" text={"Next"} onClick={() => { setModal1(false) }} />
							</div>
						</Modal>
					</div>

					<div className="flex flex-row gap-3">
						<p>Simple modal with an <b>alert inside</b></p>
						<Button text="Show" onClick={() => { setModal4(true) }} />
						<Modal
							title={"Modal with an alert"}
							message={"Clic on primary button"}
							visible={modal4}
							onClose={() => { setModal4(false) }}
						>
							<div className="bg-slate-100 rounded-md p-2 mb-1">
								Clic on primary button
							</div>
							
							<div className="flex flex-row gap-2 justify-end">
								<Button type="secondary" text={"Next"} onClick={() => { setModal4(false) }} />
								<Button type="dark" text={"Show alert"} onClick={() => { setAlertInModal(true) }} />
							</div>
							<Alert
								title="Informative"
								visible={alertInModal}
								message="This is an alert in modal"
								primaryButtonTitle="OK"
								primaryCallback={() => { setAlertInModal(false) }}
								onClose={() => { setAlertInModal(false) }}
							/>
						</Modal>
					</div>

					<div className="flex flex-row gap-3">
						<p>Simple modal with a really big content</p>
						<Button text="Show" onClick={() => { setModal2(true) }} />
						<Modal
							title={"Modal title"}
							message={"Modal message"}
							visible={modal2}
							onClose={() => { setModal2(false) }}
						>
							<div className="bg-slate-100 rounded-md p-2 mb-1">
								This is the content of the modal.
							</div>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>
							<p>You can add wathever you want inside this modal </p>

							<div className="flex flex-row gap-2 justify-end">
								<Button type="secondary" text={"Next"} onClick={() => { setModal2(false) }} />
								<Button type="primary" text={"Next"} onClick={() => { setModal2(false) }} />
							</div>
						</Modal>
					</div>
					<div className="flex flex-row gap-3">
						<p>Simple modal with a really big content</p>
						<Button text="Show" onClick={() => { setModal3(true) }} />
						<Modal
							title={"Modal title"}
							message={"Modal message"}
							visible={modal3}
							onClose={() => { setModal3(false) }}
						>
							<div>
								<form className="pb-5 flex flex-col gap-3">
									<div>
										<Label htmlFor={""} label={"Email"} />
										<TextInput name={"Hello"} placeholder="email@provider.com" />
									</div>
									<div>
										<Label htmlFor={""} label={"Name"} />
										<TextInput name={"name"} placeholder="Michel" />
									</div>
									<div>
										<Label htmlFor={""} label={"Town"} />
										<DropDownField onSelect={(value) => console.log(value)}>
											<DropDownItem value="Paris" title="Paris" />
											<DropDownItem value="London" title="London" />
										</DropDownField>
									</div>
								</form>
								<div className="flex flex-row gap-2 justify-end">
									<Button type="secondary" text={"Next"} onClick={() => { setModal3(false) }} />
									<Button type="primary" text={"Next"} onClick={() => { setModal3(false) }} />
								</div>
							</div>
						</Modal>
					</div>
				</div>
			</div>

			{/* Carousel */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4">Carousel</p>

				<div className="flex flex-col gap-3 pt-4">
					<div className="flex flex-row gap-3">
						<p>Carousel in modal</p>
						<Button text="Show" onClick={() => { setCarousel1(true) }} />
						<Modal
							title={"Modal title"}
							message={"Modal message"}
							visible={carousel1}
							onClose={() => { setCarousel1(false) }}
						>
							<Carousel canShowNext={true} confirmTitle="Next">
								<div className="w-full h-60  bg-red-200">page 1</div>
								<div className="w-full h-24 bg-blue-200">page 2</div>
								<div className="w-full bg-green-200">page 3</div>
								<div className="w-full h-36 bg-yellow-200">page 4</div>
							</Carousel>
						</Modal>
					</div>
				</div>
			</div>

			{/* Alertes */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4">Alertes</p>
				<div className="mt-7 mb-3 text-gray-800 p-3 gap-2 bg-red-50 rounded-md w-fit -ms-3 flex items-center">
					<p>By default <strong>alerts</strong> can be closed using <strong>ESC</strong> or clicking on <strong>background</strong>.</p>
				</div>
				<div className="flex flex-row gap-3">
					<p>Simple alert with primary button</p>
					<Button text="Show" onClick={() => { setAlert1(true) }} />
					<Alert
						title="Informative"
						visible={alert1}
						message="This is a simple information alert"
						primaryButtonTitle="OK"
						primaryCallback={() => { setAlert1(false) }}
						onClose={() => { setAlert1(false) }}
					/>
				</div>

				<div className="flex flex-row gap-3 mt-7">
					<p>Simple alert with primary & secondary button</p>
					<Button text="Show" onClick={() => { setAlert2(true) }} />

					<Alert
						title="Informative"
						visible={alert2}
						message="This is a simple alert with primary & secondary button"
						primaryButtonTitle="OK"
						secondaryButtonTitle="Cancel"
						primaryCallback={() => { setAlert2(false) }}
						secondaryCallback={() => { setAlert2(false) }}
						onClose={() => { setAlert2(false) }}
					/>
				</div>

				<div className="flex flex-row gap-3 mt-7">
					<p>Destructive alert with primary & secondary button</p>
					<Button text="Show" onClick={() => { setAlert3(true) }} />

					<Alert
						title="Informative"
						visible={alert3}
						type="destructive"
						message="This is a destructive alert with primary & secondary button"
						primaryButtonTitle="OK"
						secondaryButtonTitle="Cancel"
						primaryCallback={() => { setAlert3(false) }}
						secondaryCallback={() => { setAlert3(false) }}
						onClose={() => { setAlert3(false) }}
					/>
				</div>

				<div className="flex flex-row gap-3 mt-7">
					<p>Destructive alert with primary & secondary button <strong>not closable</strong></p>
					<Button text="Show" onClick={() => { setAlert4(true) }} />

					<Alert
						title="Informative"
						visible={alert4}
						type="destructive"
						closable={false}
						message="This is a destructive alert with primary & secondary button not closable using ESC or background clic"
						primaryButtonTitle="OK"
						secondaryButtonTitle="Cancel"
						primaryCallback={() => { setAlert4(false) }}
						secondaryCallback={() => { setAlert4(false) }}
						onClose={() => { setAlert4(false) }}
					/>
				</div>



			</div>
			{/* Badge */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4">Badges</p>
				{/* Primary */}
				<div className="flex flex-row gap-8 mt-7">
					<div>
						<p className="font-semibold text-sm pb-2">Primary (default)</p>
						<Badge type={BadgeType.NEUTRAL} text={"Neutral"} />
						<Badge type={BadgeType.ERROR} text={"Error"} />
						<Badge type={BadgeType.DANGEROUS} text={"Dangerous"} />
						<Badge type={BadgeType.HIGH} text={"High"} />
						<Badge type={BadgeType.WARNING} text={"Warning"} />
						<Badge type={BadgeType.SUCCESS} text={"Success"} />
						<Badge type={BadgeType.GOOD} text={"Good"} />
						<Badge type={BadgeType.NORMAL} text={"Normal"} />
						<Badge type={BadgeType.INFO} text={"Info"} />
						<Badge type={BadgeType.INFOLIGHT} text={"Info light"} />
						<Badge type={BadgeType.PURPLE} text={"Purple"} />
						<Badge type={BadgeType.SECURE} text={"Secure"} />
						<Badge type={BadgeType.ERRORLIGHT} text={"Error light"} />
					</div>
				</div>
			</div>

			{/* Tabs */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Tabs</p>
				<NavigationTabs>
					<NavigationTab to={"components"} title={"Repository"} />
					<NavigationTab to={""} title={"Merge request"} />
					<NavigationTab to={""} title={"Pipeline"} />
					<NavigationTab to={""} title={"Settings"} />
				</NavigationTabs>
			</div>


			{/* Tabs 2 */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Tabs</p>
				<Tabs>
					<Tab title="Tab 1">
						<p>Content of tab 1</p>
					</Tab>
					<Tab title="Tab 2">
						<p>Content of tab 2</p>
					</Tab>
				</Tabs>
			</div>

			{/* Dropdown */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Dropdown</p>
				<p>Default</p>
				<DropDownField onSelect={(value) => console.log(value)}>
					<DropDownItem value="Paris" title="Paris" />
					<DropDownItem value="London" title="London" />
					<DropDownItem value="Berlin" title="Berlin" />
					<DropDownItem value="Bruxelles" title="Bruxelles" />
					<DropDownItem value="Amsterdam" title="Amsterdam" />
					<DropDownItem value="Rome" title="Rome" />
					<DropDownItem value="Varsovie" title="Varsovie" />
					<DropDownItem value="Lisbonne" title="Lisbonne" />
					<DropDownItem value="Bucarest" title="Bucarest" />
					<DropDownItem value="Stockholm" title="Stockholm" />
					<DropDownItem value="Berne" title="Berne" />
					<DropDownItem value="Dublin" title="Dublin" />
					<DropDownItem value="Oslo" title="Oslo" />

				</DropDownField>
			</div>

			{/* Input */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Input</p>
				<TextInput name={"input"} placeholder="Search for something..." />
			</div>

			{/* Copy To Clipboard */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">CopyToClipboard</p>
				<CopyToClipboardInput text={"Hello Ben"} />
			</div>


			{/* Image input */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Image input</p>
				<ImageInput name="image" label={"Avatar"} onImageUpload={ () => {} } />
			</div>

			{/* Avatar */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Avatar</p>
				<div className="flex flex-row gap-8 mt-6">
					<div>
						<Avatar user={new User()} />
						<p>User null</p>
					</div>
					<div>
						<Avatar user={new User({ firstname: "Benjamin" })} />
						<p>Only firstname </p>
					</div>
					<div>
						<Avatar user={new User({ firstname: "Benjamin", lastname: "Frolicher" })} />
						<p>First & lastname</p>
					</div>
					<div>
						<Avatar user={new User({ lastname: "Frolicher" })} />
						<p>Lastname</p>
					</div>
					<div>
						<Avatar user={new User({ avatar: "0c8de945f91e583177d637b6e1003d12.jpeg" })} />
						<p>User with avatar</p>
					</div>
				</div>
			</div>

			{/* Loader */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Loader</p>
				<div className="flex flex-row gap-8 mt-6">
					<div>
						<p className="font-semibold text-sm pb-2">sm</p>
						<Loader size="sm" />
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">base</p>
						<Loader size="default" />
					</div>
					<div>
						<p className="font-semibold text-sm pb-2">lg</p>
						<Loader size="lg" />
					</div>
				</div>
			</div>

			{/* Tooltip */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Tooltip</p>
				<div className="flex flex-row gap-8 mt-6">
					<Tooltip content="Hi BG" >
						<p>From a simple text</p>
					</Tooltip>
					<Tooltip content="Hi BG" >
						<Button text="Fuck, bug on our button" enabled={false} onClick={console.log} />
					</Tooltip>


					<Tooltip content="Hi from an image">
						<QuestionMarkCircleIcon className="size-6" />
					</Tooltip>
				</div>
			</div>

			{/* Collapsible */}
			{ /* ----------------------------------------------------------------------------------------------------------------- */}
			<div>
				<p className="font-black text-2xl border-b-1 mt-4 mb-7">Collapsible</p>
				<Collapsible title={"Do not use. Under development"}>
					<p>Hide me please </p>
				</Collapsible>
			</div>


		</div>

	)
}

export { Components }

