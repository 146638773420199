import { makeAutoObservable, makeObservable, observable } from "mobx";
import { CompanyAPI } from "../../../../data/api/CompanyAPI";
import { App } from "../../../../data/model/App";
import { Company } from "../../../../data/model/Company";
import { StorePage } from "./StorePage";

interface GroupedApp {
  [key: string]: App[];
}

class StorePageViewModel {

	private workspace: Company | undefined

	public groupedApps: GroupedApp | undefined
	public isLoading: boolean = false

	constructor(workspace: Company | undefined) {
		makeObservable(this, {
			groupedApps: observable,
			isLoading: observable
		})
		this.workspace = workspace
	}

	public load() {
		
		try {
			(async () => {
			
			if (this.workspace != null) {
				this.isLoading = true
				const companyAPI = new CompanyAPI()
				const apps = await companyAPI.getAppsForUserInCompany(this.workspace.id)

				if (apps.length > 0) {
					type GroupedApp = {
						[key: string]: App[];
					};
					const groupedBy = apps.reduce((acc: GroupedApp, app) => {
						// Get the role property of the user
						const groupBy: string = app.groupBy || "No group";

						// Initialize the role array if it doesn't exist yet
						if (!acc[groupBy]) {
							acc[groupBy] = [];
						}

						// Push the user into the relevant role array
						acc[groupBy].push(app);

						return acc;
					}, {});

					this.groupedApps = groupedBy
				} else {
					this.groupedApps = undefined
				}
				this.isLoading = false
				
			}
			 })()
		}
		catch (error) { }
		finally { }
	}
}

export { StorePageViewModel }