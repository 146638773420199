import { FC, useState } from "react"
import { App, AppType } from "../../data/model/App"
import { Tooltip } from "./Tooltip"

type Props = {
    apps?: App[]
    size?: "sm" | "default"
}

const AppsAvatar: FC<Props> = (props) => {
    return (
        <>
            {
                props.apps?.map(app => (
                    <AppAvatar app={app} size={props.size} />
                ))
            }
        </>
    )
}

AppsAvatar.defaultProps = {
    size: "default"
}

type AppAvatarProps = {
    app: App
    size?: "sm" | "lg" | "default"
}

const AppAvatar: FC<AppAvatarProps> = ({ app, size }) => {
    var className = ""
    const [imageError, setImageError] = useState(false);

    if (size === "sm") {
        className += 'size-5 text-xs object-cover rounded border-1 border-gray-200'
    }
    else if (size === "lg") {
        className += 'size-10 object-cover rounded border-1 border-gray-200'
    }
    else {
        className += 'size-8 object-cover rounded border-1 border-gray-200'
    }


    return (
        <Tooltip key={app.id} content={(app.platform == AppType.IOS ? "iOS :" : "Android: ") + app.name}>

            {
                (app.icon && !imageError) ? (<img src={`/api/img/${app.icon}`} className={`${className} rounded-md border-1`} onError={() => setImageError(true)} />) : (
                    <div className={`${className} flex items-center justify-center bg-slate-100 text-black font-bold`}>
                        {`${app.name?.[0] || ""}`.toUpperCase() || "?"}
                    </div>
                )
            }


        </Tooltip>
    )
}


export { AppsAvatar, AppAvatar }