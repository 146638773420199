import { CheckCircleIcon } from "@heroicons/react/24/outline"
import { FC } from "react"

const TodoPage: FC = () => {

	return (
		<div id="todos">
			<h1 className="font-black text-2xl mb-4 border-b-1">Todos</h1>
			<ul>
				<li className="font-bold">App</li>
				<li className="flex flex-row items-center">Valider / Refuser / Delete / DL build <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li>Voir si App overview ou autre (infos sur l’app)</li>
			</ul>
			<ul>
				<li className="font-bold">Build</li>
				<li className="flex flex-row items-center">Ou mettre qui à tester qui a validé ou refusé <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li>Ou mettre les infos de date</li>
				<li>Ou voir les terminaux autorisé a ce build</li>
				<li>Est-ce que le nombre d'installation est pertinent dans la liste ?</li>
				<li>Un build est associé à un env, des usergroup à des env, </li>
				<li>Ajouter un device lors d'une connection au SDK ou au backoffice !</li>
			</ul>
			<ul>
				<li className="font-bold">Build/CICD</li>
				<li>Fix le upload.rb avec appID etc...</li>
				<li>Comment on intègre facilement notre gitlab.ci et le fastfile?</li>
				<li>Changer le nom du binaire avec le buildID</li>
			</ul>
			<ul>
				<li className="font-bold">Build/Overview</li>
				<li>Ajouter version minimal supportée</li>
			</ul>
			<ul>
				<li className="font-bold">Build/Issues</li>
				<li>Les issues de sécurité remontent en auto tant que pas corrigé (possible d’ignorer/filtrer)</li>
			</ul>
			<ul>
				<li className="font-bold">Build/security</li>
			</ul>
			<ul>
				<li className="font-bold">Build/live</li>
				<li className="flex flex-row items-center">Ajouter clear log <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Ajouter crash <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Ajouter network <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Ajouter crash SDK Android <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Ajouter crash SDK iOS</li>
				<li className="flex flex-row items-center">Ajouter network SDK Android <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Ajouter network SDK iOS</li>
			</ul>
			<ul>
				<li className="font-bold">Issues</li>
				<li className="flex flex-row items-center">Supprimer icon company, juste icon de l’app <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Virer issue ID (soit au survol, soit dans le detail) <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
				<li className="flex flex-row items-center">Une issue est déclarée sur un build donnée. Il faudrait retrouver l'info quelquepart <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" /></li>
			</ul>

			<ul>
				<li className="font-bold">RBAC</li>				
			</ul>
			<p className="font-semibold">2 Choix possibles: </p>
					<p className="underline">1. Définition strictes des ROLES:</p>
					<p><strong>- SUPERADMIN:</strong> Toutes les fonctionnalités de l'app, y compris le paiement </p>
					<p><strong>- ADMIN:</strong> Accès à l'ensemble des fonctionnalités de l'app SANS le paiement</p>
					<p><strong>- OPS:</strong> </p>
					<p><strong>- OPS:</strong> </p>
					<p><strong>- OPS:</strong> </p>
					<p><strong>- EXTERNAL:</strong></p>
					
			<p className="underline">2. Définition modulables des ROLES </p>

			<h1 className="font-black text-2xl mb-4 border-b-1">Ideas</h1>
			<ul>
				<li className="font-bold">Performance</li>
				<li>Prise de mesure de consomation CPU, memory, et data (network)</li>
				<li>Les triggers peuvent être : </li>
				<li>- L'affichage d'un écran</li>
				<li>- Un temps (ex: toutes les 2secondes)</li>
				<li>On scree</li>
			</ul>
			<h1 className="font-black text-2xl mb-4 border-b-1">Tasks P1</h1>
			<ul>
				<li className="font-bold">Interface crashes @Ju</li>
				<li className="font-bold">Interface network @Ben</li>
				<li className="font-bold">Remontée issue SDK iOS @Ben</li>
				<li className="font-bold">Remontée crashes SDK iOS @Ben</li>
				<li className="font-bold">Remontée network SDK iOS @Ben</li>
				<li className="font-bold">Remontée crashes SDK Android @Ju</li>
				<li className="font-bold">Remontée network SDK Android @Ju</li>
				<li className="font-bold">UI SDK android / iOS</li>
				<li className="font-bold">UI backoffice a revoir</li>
			</ul>
			<h1 className="font-black text-2xl mb-4 border-b-1">Tasks P2</h1>
			<ul>
				<li className="font-bold">Desactivation des logs trigger prisma</li>
			</ul>
		</div>
	)
}

export { TodoPage }