import { FC } from "react"


type Props = {
	size?: "sm" | "default" | "lg" | "huge"
	customClass?: string
}

const Loader: FC<Props> = ({ size = "lg", customClass}) => {

	let className = ""

	if (size === "sm") {
		className = "size-3 border-2"
	}
	else if (size === "default") {
		className = "size-4 border-2"
	}
	else if (size === "lg") {
		className = "size-5 border-4"
	}
	else if (size === "huge") {
		className = "size-12 border-4"
	}

	return (
		<div className={`${className} border-gray-50 animate-spin rounded-full border-t-gray-400 ${customClass} `}></div>
	)
}

Loader.defaultProps = {
	size: "default"
}

export { Loader }