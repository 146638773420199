import { action, computed, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { CompanyAPI } from "../../../../../../data/api/CompanyAPI";
import { UserGroupsAPI } from "../../../../../../data/api/UserGroupsAPI";
import { Company } from "../../../../../../data/model/Company";
import { TeamExtended } from "../../TeamsViewModel";
import { User } from "../../../../../../data/model/User";
import { UserAPI } from "../../../../../../data/api/UserAPI";
import { UserSelection } from "../UserSelection";

class AddUserViewModel {

	email = ""
	users: UserSelection[] = []

	private allUsers: User[] = []

	isInviting: boolean = false

	private workspace: Company | undefined
	team: TeamExtended | undefined

	constructor(workspace: Company | undefined, team: TeamExtended | undefined) {
		makeObservable(this, {
			email: observable,
			users: observable,
			isInviting: observable,
			emailError: computed,
			setEmail: action
		});
		this.workspace = workspace
		this.team = team
	}

	setEmail(email: string) {
		runInAction(() => { this.email = email })
	}

	/// View life cycle
	/// ---------------------------------------------------------------------------------------------------------------------------------------
	load() {
		(async () => {
			const users = await this.fetchUsersWorkspace()
			this.allUsers = users ?? []
			const usersSelection = users?.filter((u) => {
				return this.team?.users?.map((u) => u.id).includes(u.id) == false
			}).map((u) => new UserSelection(u, false))
			if (usersSelection) {
				runInAction(() => {
					this.users = usersSelection
				})
			}
		})()
	}

	/// Get all apps in the current team
	async fetchUsersWorkspace() {
		if (this.team != undefined) {
			const api = new CompanyAPI()
			const users = await api.getUsersForCompany(this.workspace!.id)
			return users
		}
	}

	/// Utils
	/// ---------------------------------------------------------------------------------------------------------------------------------------

	private isEmailValid() {
		if (!this.email) return true;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(this.email);
	}

	private isEmailAlreadyExist() {
		return this.allUsers.map((u) => u.email).includes(this.email)
	}

	get emailError(): string | null {
		console.log(`Check email error ${this.isEmailValid()}`)
		if (!this.isEmailValid()) return "Please enter a valid email address."
		if (this.isEmailAlreadyExist()) return `${this.email} is already a member of ${this.workspace?.name} workspace. Please use Add existing member`
		return null
	}

	get isUserSelectionValid() {
		const selectedUsers = this.users.filter((u) => u.selected == true)
		return selectedUsers.length != 0
	}

	/// Actions
	/// ---------------------------------------------------------------------------------------------------------------------------------------

	/**
	 * Add the given user to the guests 
	 * @param userId User affected by the selection/unselection
	 * @param checked Is user selected
	 */
	handleMemberSelection(userId: string, checked: boolean) {
		this.users = this.users.map((userSelection) =>
			userSelection.user.id === userId
				? { ...userSelection, selected: checked }
				: userSelection
		)
	}

	/**
	 * Perform invitation from email
	 */
	performInvitationFromEmail = async () => {
		if (this.team != undefined) {
			this.isInviting = true
			const api = new UserAPI()
			await api.inviteUser(this.workspace!.id, this.email, this.team.userGroup.id)
			this.isInviting = false
		}
	}

	/**
	 * Perform invitation from the users list
	 */
	async performInvitationFroUsers() {
		if (this.team != undefined) {
			this.isInviting = true
			const api = new UserGroupsAPI()
			const userSelection = this.users.filter((u) => u.selected == true)
			await api.inviteUsers(this.team.userGroup.id, userSelection?.flatMap((u) => u.user.id!))
			this.isInviting = false
		}
	}
}

export { UserSelection, AddUserViewModel }


class MockUserAPI {

	inviteUser = async (workspace: string, email: string, teamId: string) => {


		return new Promise((resolve) => {
			setTimeout(() => {
				resolve("Result after 2 seconds");
			}, 2000); // 2 seconds delay
		});
	}

}


class MockUserGroupAPI {

	inviteUsers = async (teamId: string, users: string[]) => {

		return new Promise((resolve) => {
			setTimeout(() => {
				resolve("Result after 2 seconds");
			}, 2000); // 2 seconds delay
		});
	}

}