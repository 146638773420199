import { AxiosResponse } from 'axios';
import { App } from '../model/App';
import { Build } from '../model/Build';
import { axiosInstance } from '../utils/RequestUtils';

const BASE = `/app`

export class AppAPI {

     /**
     * Get App by its ID
     * @returns The related App
     * @throws
     */
     async getAppById(appId: string): Promise<App> {
          const response = axiosInstance.get(`${BASE}/${appId}`)
          return (await response).data
     }

     /**
     * Get builds for the given appId
     * @returns A list of build
     * @throws
     */
     async builds(appId: string): Promise<Build[]> {
          const response = await axiosInstance.get(`${BASE}/${appId}/builds`);
          return response.data
     }

     /**
     * Get app for the given appId
     * @returns an App
     * @throws
     */
     async app(appId: string): Promise<App> {
          const response = await axiosInstance.get(`${BASE}/${appId}`);
          return response.data
     }

     async updateApp(app: Partial<App>): Promise<App> {
          let formData = new FormData()
          if (app.iconFile)
               formData.append('file', app.iconFile)
          if (app.name)
               formData.append('name', app.name)
          if (app.bundleId)
               formData.append('bundleId', app.bundleId)
          if (app.groupBy)
               formData.append('groupBy', app.groupBy)
          if (app.jiraUrl)
               formData.append('jiraUrl', app.jiraUrl)
          if (app.gitlabUrl)
               formData.append('gitlabUrl', app.gitlabUrl)
          if (app.platform)
               formData.append('platform', app.platform)

          return axiosInstance
               .patch(`${BASE}/${app.id}`, formData, {
                    headers: {
                         'Content-Type':
                              'multipart/form-data; boundary=--------------------------995261027145138292227880',
                    },
               })
               .then((d: AxiosResponse<App>) => {
                    return d.data
               })
     }

     async appsForUserGroup(userGroupId: string): Promise<App> {
          const response = await axiosInstance.get(`${BASE}/${userGroupId}/apps`);
          return response.data
     }
}