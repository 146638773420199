import { ChangeEventHandler, FC, useEffect, useRef, useState } from "react"

type Props = {
	size?: "default" | "xl"
	maxChar?: number
	required?: boolean
	type?: string
	name: string
	placeholder?: string
	customClass?: string
	value?: string
	tabIndex?: number
	onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
}

const TextInput: FC<Props> = ({ size = "default", maxChar, required = false, type = "text", placeholder, customClass, name, value, onChange, tabIndex }) => {
	var [newType, setNewType] = useState<string>("text")


	useEffect(() => {
		if (type == "password") {
			if (value?.length == 0) {
				setNewType("text")
			}
		}
	}, [value])


	return (
		<div key={value} className="rounded-md">
			<input
				required={required || type === "password"}
				type={newType}
				tabIndex={tabIndex}
				name={name}
				placeholder={placeholder}
				defaultValue={value}
				onChange={(e) => {

					if (type == "password" && e.target.value.length > 0) {
						window.setTimeout(() => {
							setNewType("password")
						}, 500);

						const focusableElements = document.querySelectorAll(
							'input'
						);
						if (e.target) {
							const index = Array.from(focusableElements).indexOf(e.target);

							// Focus the next element if available
							if (index !== -1 && index < focusableElements.length - 1) {
								focusableElements[index + 1].focus();
							}
						}

					} else {
						setNewType("text")
					}

					if (onChange != undefined) {
						onChange(e)
					}
				}
				}
				maxLength={maxChar}
				className={`rounded-md ${size === "default" ? "h-8" : "h-12"} w-full border-0 p-2 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${customClass}`}
			/>
		</div>
	)
}

export { TextInput }