import { FC, useState } from "react";
import { ButtonGroup } from "../../../../_components/ButtonGroup";
import { Tooltip } from "../../../../_components/Tooltip";
import { PlateformLogo } from "../../../../_components/plateformLogo/plateformLogo";
import { AppType } from "../../../../../data/model/App";
import { QRCode } from "react-qrcode-logo";
import { Modal } from "../../../../_components/Modal";
import { useNavigate } from "react-router-dom";
import { AppForm } from "../../../settings/apps/app/AppForm";
import AppFormViewModel from "../../../settings/apps/app/AppFormViewModel";

type Props = {
	visible: boolean
	onClose: () => void
}

const CreateAppModal: FC<Props> = ({ visible, onClose }) => {

	return (
		<Modal title={'Create app'} message={""} visible={visible} onClose={onClose}>
			<AppForm viewModel={new AppFormViewModel(undefined, onClose)} />
		</Modal>
	)
}

export { CreateAppModal }