import exp from "constants";
import { FC, PropsWithChildren, ReactElement } from "react";

export default interface Props {
  title: string
  children: ReactElement
  customClass?: string
}

const Tab: FC<Props> = ({ children, customClass }) => {
  return (
   <div className={ `${customClass}` }> { children } </div>
  )
};

Tab.defaultProps = {
  customClass: ""
}

export { Tab }