import { FC, useEffect, useState } from "react";
import { Issue } from "../../../data/model/Issue";
import { Badge, BadgeType } from "../../_components/Badge";
import { IssueCriticality, IssueStatus } from "../../../data/api/IssuesAPI";
import { TrashIcon, FaceFrownIcon, FaceSmileIcon, XMarkIcon, ExclamationCircleIcon, ExclamationTriangleIcon, FireIcon, FlagIcon, EyeIcon } from '@heroicons/react/24/outline'
import { useOutsideClick } from "../../../hooks/useOuterClick";
import { Action } from "../../_props/Actions";
import { Tooltip } from "../../_components/Tooltip";
import { Modal } from "../../_components/Modal";
import { Avatar } from "../../_components/avatar/Avatar";
import { useAuth } from "../../../hooks/useAuth";
import Moment from "react-moment";
import { AppAvatar } from "../../_components/AppsAvatar";
import { User } from "../../../data/model/User";

type Props = {
	visible: boolean,
	issue: Issue,
	close: () => void,
	actions?: Action<Issue>[]
}

const IssueDetails: FC<Props> = ({ issue, visible, close, actions }) => {

	const { user } = useAuth()

	const statusBadge = () => {
		if (issue.status === IssueStatus.NEW) {
			return <Badge type={BadgeType.INFO} text={"New"} />
		} else if (issue.status === IssueStatus.CLOSED) {
			return <Badge type={BadgeType.NEUTRAL} text={" Closed"} />
		} else if (issue.status === IssueStatus.REFUSED) {
			return <Badge type={BadgeType.ERROR} text={" KO"} />
		} else { // VALIDATED
			return <Badge type={BadgeType.GOOD} text={" OK"} />
		}
	}

	const criticality = () => {

		switch (issue.criticality) {
			case IssueCriticality.LOW:
				return <Badge type={BadgeType.INFO} text={<div className="flex flex-row items-center gap-2"><FlagIcon className="size-3" /> <p>Low</p></div>} />
			case IssueCriticality.MEDIUM:
				return <Badge type={BadgeType.WARNING} text={<div className="flex flex-row items-center gap-2"><FlagIcon className="size-3" /> <p>Medium</p></div>} />
			case IssueCriticality.HIGH:
				return <Badge type={BadgeType.HIGH} text={<div className="flex flex-row items-center gap-2"><FlagIcon className="size-3" /> <p>Hight</p></div>} />
			case IssueCriticality.CRITICAL:
				return <Badge type={BadgeType.DANGEROUS} text={<div className="flex flex-row items-center gap-2"><FlagIcon className="size-3" /> <p>Critical</p></div>} />
				break
		}
	}

	useEffect(() => {

	}, [])

	return (
		<Modal
			title={issue.title!}
			message={""}
			visible={visible}
			onClose={close}
		>
			<div>
				<div className="flex justify-between border-b-1 pb-3">
					<div className="flex flex-row items-center gap-2">
						{statusBadge()}
						{criticality()}
					</div>
					<div>
						{
							actions?.map(action => {
								return <>
									<Tooltip content={action.description}>
										<button className={`border-1 rounded-md me-1 p-2 ${action.bgColor}`} onClick={(event) => {
											event.stopPropagation()
											action.action!(issue)
										}}>
											{action.icon}
										</button>
									</Tooltip>
								</>
							})
						}
					</div>
				</div>
				<div className="mt-4 grid grid-cols-2 gap-2">
					<p className="font-semibold text-gray-500">App: </p>
					{
						issue.app ? (<>
							<div className="inline-flex items-center gap-2">
								<AppAvatar app={issue.app} />
								<p className="font-semibold text-sm">{issue.app.name}</p>
							</div>
						</>
						) : <div></div>
					}

					<p className="font-semibold text-gray-500">Commiter: </p>
					{
						issue.build.committer ? (
							<Tooltip content={issue.build.committer?.firstname + " " + issue.build.committer?.lastname}>
								<span>
									<Avatar customClass="h-8 w-8 rounded-full" user={issue.build.committer!} />
								</span>
							</Tooltip>
						) : issue.build.committerEmail ? (<Tooltip content={issue.build.committerEmail}>
							<div className={`flex items-center justify-center bg-slate-100 text-black font-bold`}>
								{`${issue.build.committerEmail[0] || ""}`.toUpperCase() || "?"}
							</div>
						</Tooltip>) : <></>
					}
					<p className="font-semibold  text-gray-500">Reporter: </p>
					<div className="inline-flex items-center gap-2 bg-gray-100 rounded-md p-1 px-2 w-fit">
						<Avatar size="sm" user={user!} />
						<p className="font-semibold text-sm">{user?.firstname} {user?.lastname}</p>
					</div>
					<p className="font-semibold text-gray-500">Creation date: </p>
					<p><Moment format="DD MMM YYYY [at] HH[h]mm">{issue.createdAt}</Moment> </p>
				</div>
				<div className="pb-6">
					<p className="font-bold mt-8 pb-4">Description: </p>
					<p className="bg-slate-50 w-full rounded-md p-4 -ml-2">{issue.description}</p>
				</div>
				<p className="font-bold mt-8 pb-4">Attachements: </p>
				<div className="flex flex-row gap-4">
					{
						issue.screenshots.map((s) => {
							return (
								<div className="p-4 bg-slate-50 rounded-lg overflow-hidden border-1 border-slate-200 relative group">
									<img
										className="max-h-56 cursor-pointer"
										src={`/api/img/${s}`}

										alt=""
									/>
									<div onClick={(e) => window.open(`/api/img/${s}`, '_blank')} className="absolute  cursor-pointer bg-white inset-0 w-full h-full opacity-0 transition-opacity duration-100 group-hover:opacity-60 flex items-center justify-center">
										<EyeIcon className="w-8 h-8" />
									</div>
								</div>
							)
						})
					}
					{
						issue.records.map((r) => {
							return (
								<div className="p-4 bg-slate-50 rounded-lg overflow-hidden border-1 border-slate-200 relative group">
									<video className="max-h-56 cursor-pointer" src={`/api/img/${r}`} onClick={(e) => window.open(`/api/img/${r}`, '_blank')} />
									<div onClick={(e) => window.open(`/api/img/${r}`, '_blank')} className="absolute  cursor-pointer bg-white inset-0 w-full h-full opacity-0 transition-opacity duration-100 group-hover:opacity-60 flex items-center justify-center">
										<EyeIcon className="w-8 h-8" />
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</Modal>
	)

	// return <>
	// 	{open && (
	// 		<div ref={modalRef} className="fixed z-50 m-auto fixed inset-0 h-fit w-fit max-w-[60%] max-h-[80%] bg-white flex flex-col justify-between items-center border shadow-lg">

	// 			<div className="border-l-1 p-8 overflow-auto bg-white shadow-lg right-0 duration-700 transition-transform">

	// 				<div className="flex flex-row mb-5 justify-end">
	// 					<button onClick={() => { close() }}>
	// 						<XMarkIcon className="w-6 h-6" />
	// 					</button>
	// 				</div>

	// 				<div className="flex mb-5 justify-between">
	// 					<h2 className="font-bold text-xl">Issue detail</h2>
	// 					<div className="inline-flex items-center">
	// 						{criticality()}
	// 						{statusBadge()}
	// 					</div>
	// 				</div>

	// 				{
	// 					actions?.map(action => {
	// 						return <>
	// 							<Tooltip content={action.description}>
	// 								<button className={`border-1 rounded-md me-1 p-2 ${action.bgColor}`} onClick={(event) => {
	// 									event.stopPropagation()
	// 									action.action!(issue)
	// 								}}>
	// 									{action.icon}
	// 								</button>
	// 							</Tooltip>
	// 						</>
	// 					})
	// 				}

	// 				<div className="space-y-4">
	// 					{/* <span style={{ backgroundColor: `${issue?.build?.env?.color}` }} className={`text-white inline-flex items-center rounded-md px-2 text-xs font-bold`}>{issue?.build?.env?.name}</span> */}

	// 					<div className="flex mb-5 justify-between">
	// 						<h2 className="font-bold text-xl">Title:</h2>
	// 					</div>

	// 					<div className="flex mb-4">
	// 						<p className="font-bold text-lg pr-2 truncate" data-twe-toggle="tooltip" title={issue?.title}>{issue?.title}</p>
	// 					</div>

	// 					<div className="flex mb-5 justify-between">
	// 						<h2 className="font-bold text-xl">Description:</h2>
	// 					</div>

	// 					<div className="flex mb-4">
	// 						<p className="text-lg pr-2 truncate" data-twe-toggle="tooltip" title={issue?.description}>{issue?.description}</p>
	// 					</div>

	// 					<h2 className="font-bold text-xl">Screenshots:</h2>

	// 					<div>
	// 						{
	// 							issue?.screenshots.length! > 0 ? (
	// 								issue?.screenshots?.map(screen => {
	// 									return <div className="flex flex-row space-x-2 items-center justify-center"><img className="border w-1/2" src={`/api/img/${screen}`} alt=""></img></div>
	// 								})) : <>No screenshots</>
	// 						}
	// 					</div>


	// 					<h2 className="font-bold text-xl">Records:</h2>

	// 					<div>
	// 						{
	// 							issue?.records.length! > 0 ? (
	// 								issue?.records?.map((record) => {
	// 									console.log(record)
	// 									return <div className="flex flex-row space-x-2 items-center justify-center"><video className="border w-1/2" controls>
	// 										<source src={`/api/img/${record}`} type="video/mp4" />
	// 									</video></div>
	// 								})) : <>No records</>
	// 						}
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	)}
	// </>
}


export { IssueDetails }
