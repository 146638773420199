import { FC, useCallback } from "react"
import { Modal } from "../../../../../_components/Modal"
import { UserGroupsAPI } from "../../../../../../data/api/UserGroupsAPI"
import { UserGroup } from "../../../../../../data/model/UserGroup"
import { Label } from "../../../../../_components/Label"
import { TextInput } from "../../../../../_components/TextInput"
import { Button } from "../../../../../_components/buttons/Button"

type Props = {
	visible: boolean
	onClose: () => void
	team: UserGroup | undefined
}

const EditTeam: FC<Props> = (props) => {

	const editTeam = useCallback((async () => {
		if (props.team != undefined) {
			const userGroupsAPI = new UserGroupsAPI()
			const team = await userGroupsAPI.editUserGroup(props.team)
			props.onClose()
		}
	}), [props.team])

	return (
		<Modal title={'Edit team'} message={""} visible={props.visible && props.team != null}
			onClose={props.onClose}
		>
			<div className="flex flex-col w-full pt-4">
				<Label htmlFor={""} label={"Team name"} style="md" />
				<TextInput name="email" size="xl" placeholder="QA Team" value={props.team?.name} customClass="mt-1" onChange={(e) => props.team!.name = e.target.value} />
				{/* <p className="text-red-500 text-sm pt-4">An error occurred while processing your request. Please try again later.</p> */}

				<div className="ml-auto mt-14">
					<Button onClick={() => { editTeam() }} >Update</Button>
				</div>
			</div>
		</Modal>
	)
}

export { EditTeam }