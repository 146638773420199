import { Company } from "../../../../../data/model/Company";
import { UserGroup, UserGroupType } from "../../../../../data/model/UserGroup";
import { makeAutoObservable, observable, computed, runInAction, makeObservable } from "mobx"
import { TeamExtended } from "../TeamsViewModel";
import { CompanyAPI } from "../../../../../data/api/CompanyAPI";
import { UserGroupsAPI } from "../../../../../data/api/UserGroupsAPI";
import { User } from "../../../../../data/model/User";
import { App } from "../../../../../data/model/App";

enum Step {
	NAME = 0,
	USERS_SELECTION = 1,
	RIGHTS = 2,
	CONFIRM = 3
}

class CreateTeamViewModel {

	onFinish = () => { }
	step = Step.NAME
	team?: TeamExtended
	teams?: TeamExtended[]
	teamName?: string
	teamNameError: boolean = false
	users: SelectableUser[] = []
	apps: SelectableApp[] = []
	isCreating = false

	private workspace?: Company

	constructor(workspace: Company, onFinish: () => void) {
		makeObservable(this, {
			step: observable,
			teamName: observable,
			teamNameError: observable,
			team: observable,
			teams: observable,
			users: observable,
			apps: observable,
			isCreating: observable,
			nextIsActive: computed,
		});

		this.workspace = workspace
		this.onFinish = onFinish
	}

	public async load() {
		await this.geUsers()
		await this.getApps()
		await this.getTeams()
	}

	get title() {
		switch (this.step) {
			case Step.NAME:
				return "Next"
			case Step.USERS_SELECTION:
				return "Next"
			case Step.RIGHTS:
				return "Create team"
			case Step.CONFIRM:
				return "Creating..."
		}
	}
	get nextIsActive() {
		switch (this.step) {
			case Step.NAME:
				return (this.teamName != undefined) && this.teamName.length > 0 && this.teamNameError == false
			case Step.USERS_SELECTION:
				return this.users.filter(item => { return item.selected }).length > 0
			case Step.RIGHTS:
				return this.apps.filter(item => { return item.selected }).length > 0
			case Step.CONFIRM:
				return false;
		}
	}

	goToStep(step: Step) {
		this.step = step
		this.isCreating = false
		if (this.step === Step.CONFIRM) {
			this.performCreating()
		}
	}

	private async performCreating() {
		this.isCreating = true
		const api = new UserGroupsAPI()
		const response = await api.createUserGroup(this.teamName!,
			this.users.filter(item => item.selected).map(item => item.user.id),
			this.apps.filter(item => item.selected).map(item => item.app.id))
		if (response != undefined) {
			this.onFinish()
		} else {
			// TODO Show error
		}
	}

	private geUsers = async () => {
		const api = new CompanyAPI()
		if (this.workspace != null) {
			const response = await api.getUsersForCompany(this.workspace.id)
			runInAction(() => {
				this.users = response.map((a) => new SelectableUser(a))
			})
		}
	}

	private getApps = async () => {

		const api = new CompanyAPI()
		if (this.workspace != null) {
			const response = await api.getAppsForCompany(this.workspace.id)
			runInAction(() => {
				this.apps = response.map((a) => new SelectableApp(a))
			})
		}
	}

	private getTeams = async () => {
		const api = new CompanyAPI()
		if (this.workspace != null) {
			const response = await api.getUsergroupsForCompany(this.workspace.id)
			const teamsWithApps: TeamExtended[] = await Promise.all(
				response.flatMap(async (team) => {
					const users = await this.getUsersInTeam(team.id)
					const apps = await this.getAppsInTeam(team.id)
					const teamExtended = new TeamExtended(team)
					teamExtended.apps = apps
					teamExtended.users = users
					return teamExtended
				})
			)
			runInAction(() => {
				this.teams = teamsWithApps
			})
		}
	}

	private getUsersInTeam = async (teamId: string) => {
		const groupAPI = new UserGroupsAPI()
		return await groupAPI.getUsersForUserGroup(teamId)
	}

	private getAppsInTeam = async (teamId: string) => {
		const groupAPI = new UserGroupsAPI()
		return await groupAPI.appsForUserGroup(teamId)
	}

	public changeTeamName = (name: string) => {
		if (this.teams?.map(team => team.userGroup.name).includes(name)) {
			this.teamNameError = true
		} else {
			this.teamNameError = false
			this.teamName = name
			if (this.team == undefined) {
				const userGroup = new UserGroup()
				userGroup.name = name
				this.team = new TeamExtended(userGroup)
			} else {
				this.team.userGroup.name = name
			}
		}
	}

	public handleUsersSelection(userId: string, selected: boolean) {
		this.users = this.users.map((item) =>
			item.user.id === userId
				? { ...item, selected: selected }
				: item
		)
	}

	public handleAppSelection(appId: string, selected: boolean) {
		this.apps = this.apps.map((app) =>
			app.app.id === appId
				? { ...app, selected: selected }
				: app
		)
	}

	public selectableTeams() {
		return this.teams?.filter(team => { return team.userGroup.type != UserGroupType.WORKSPACE })
	}
}

class SelectableUser {
	user: User
	selected: boolean = false

	constructor(user: User) {
		this.user = user
	}
}

class SelectableApp {
	app: App
	selected: boolean = false

	constructor(app: App) {
		this.app = app
	}
}

export { CreateTeamViewModel, Step }