import { FC, useCallback, useEffect, useState } from "react"
import { Label } from "../../../../_components/Label"
import { TextInput } from "../../../../_components/TextInput"
import { Build, BuildStatus } from "../../../../../data/model/Build"
import { DropDownField, DropDownItem } from "../../../../_components/Dropdown"
import { XCircleIcon } from "@heroicons/react/24/outline"
import { Tooltip } from "../../../../_components/Tooltip"

type Props = {
	builds: Build[],
	setFilteredBuilds: React.Dispatch<React.SetStateAction<Build[]>>
}

export type Filters = {
	appVersion: string | "All"
	// env: string | "All"
	status: string | "All"
	issueId: string | "All"
}

const Filter: FC<Props> = ({ builds, setFilteredBuilds }) => {

	const [filters, setFilters] = useState<Filters>({
		appVersion: 'All',
		// env: 'All',
		status: 'All',
		issueId: "All"
	})

	const appVersions = new Set<string>()
	const envs = new Set<string>()
	const issueIds = new Set<string>()

	builds.forEach(build => {
		appVersions.add(build.version)
		// envs.add(build.env.name!)
		issueIds.add(build.customerIssue)
	})

	const filterBuilds = useCallback((() => {

		console.debug(JSON.stringify(filters))

		var status = (() => {
			switch (filters.status) {
				case "New": { return BuildStatus.NEW }
				case "Validated": { return BuildStatus.OK }
				default: { return BuildStatus.KO }
			}
		})


		setFilteredBuilds(builds.filter(build => {
			return (filters?.appVersion === 'All' || build.version === filters?.appVersion)
				// && (filters?.env === 'All' || build.env.name === filters?.env)
				&& (filters?.status === 'All' || build.status === status())
				&& (filters?.issueId === 'All' || build.customerIssue === filters?.issueId)
		}))

	}), [filters, builds, setFilteredBuilds])

	useEffect(() => {
		if (filters?.appVersion !== undefined 
			// || filters?.env !== undefined 
			|| filters?.status !== undefined || filters?.issueId !== undefined) {
			filterBuilds()
		}
	}, [filters.appVersion, 
		// filters.env, 
		filters.status, filters.issueId, filterBuilds])


	return (
		<div className="inline-flex gap-4 items-center">
			<div>
				<Label htmlFor={""} label="App version" />
				<div className="rounded-md">
					<DropDownField defaultValue={filters.appVersion} customClass="mb-4" onSelect={(value) => {
						setFilters({
							appVersion: value,
							// env: filters.env,
							status: filters.status,
							issueId: filters.issueId
						})
					}}>
						<DropDownItem value="All" title="All" />
						{
							Array.from(appVersions).map((appVersions, index) => {
								return <DropDownItem key={index + appVersions} value={appVersions} title={appVersions} />
							})
						}
					</DropDownField>
				</div>
			</div>
			{/* <div>
				<Label htmlFor={""} label="Environment" />
				<DropDownField defaultValue={filters.env} customClass="mb-4" onSelect={(value) => {
					setFilters({
						appVersion: filters.appVersion,
						env: value,
						status: filters.status,
						issueId: filters.issueId
					})
				}}>
					<DropDownItem value="All" title="All" />
					{
						Array.from(envs).map((env, index) => {
							return <DropDownItem key={index + env} value={env} title={env} />
						})
					}
				</DropDownField>
			</div> */}
			<div>
				<Label htmlFor={""} label="Status" />
				<DropDownField defaultValue={filters.status} customClass="mb-4" onSelect={(value) => {

					setFilters({
						appVersion: filters.appVersion,
						// env: filters.env,
						status: value,
						issueId: filters.issueId
					})
				}}>
					<DropDownItem value="All" title="All" />
					<DropDownItem value={"New"} title={"New"} />
					<DropDownItem value={"Validated"} title={"Validated"} />
					<DropDownItem value={"Refused"} title={"Refused"} />
				</DropDownField>
			</div>
			<div>
				<Label htmlFor={""} label="Issue ID" />
				<DropDownField defaultValue={filters.issueId} customClass="mb-4" onSelect={(value) => {
					setFilters({
						appVersion: filters.appVersion,
						// env: filters.env,
						status: filters.status,
						issueId: value
					})
				}}>
					<DropDownItem value="All" title="All" />
					{
						Array.from(issueIds).map((issueId, index) => {
							return <DropDownItem key={index + issueId} value={issueId} title={issueId} />
						})
					}
				</DropDownField>
			</div>
			<div>
				<Tooltip content={"Clear filters"}>
					<XCircleIcon className="h-6 w-6" onClick={() => {
						setFilters({
							appVersion: "All",
							// env: "All",
							status: "All",
							issueId: "All"
						})
					}} />
				</Tooltip>
			</div>
		</div>
	)
}

export { Filter }